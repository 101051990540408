import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

import { SectionTypeEnum } from '@/@enums';
import type {
  ErrorMessageModel,
  GroupEntity,
  ResponseErrorModel,
  MenuBySections,
  MenuCustomItemModel,
  MenuEntity,
  MenuFileItemModel,
  MenuGroupItemModel,
  ResponseMenuModel,
} from '@/@types';
import { $api } from '@/services';
import type { BaseState } from '@/store';

// const NOT_AVAILABLE_ITEMS = [
//   'Undefined',
//   'GettingStarted',
//   'Invite',
//   'Ideas',
//   'OrgChart',
// ];

interface MenuState extends BaseState<MenuEntity> {
  groups: MenuGroupItemModel[];
  groupsPinned: MenuGroupItemModel[];
  groupsUnpinned: MenuGroupItemModel[];
  fileItems: (MenuCustomItemModel | MenuFileItemModel)[];
  menuBySections: MenuBySections;
  unreadMessagesTotal: number;
}

const processGroup = (group: GroupEntity): MenuGroupItemModel => {
  const {
    type,
    stats: { messages },
    ...rest
  } = group;
  return Object.assign(rest, {
    index: 0,
    isPin: false,
    type: 'Group',
    groupType: type,
    unreadMessagesCount: messages,
  });
};

export const useMenuStore = defineStore({
  id: 'menu',
  state: (): MenuState => ({
    data: null,
    errors: [],
    loading: false,
    fileItems: [],
    groups: [],
    groupsPinned: [],
    groupsUnpinned: [],
    menuBySections: {
      Favorites: {
        sectionType: SectionTypeEnum.Favorites,
        title: '',
        isPublic: false,
        items: [],
      },
      Helpful: {
        sectionType: SectionTypeEnum.Helpful,
        title: '',
        isPublic: false,
        items: [],
      },
      Groups: {
        sectionType: SectionTypeEnum.Groups,
        title: '',
        isPublic: false,
        items: [],
      },
    },
    unreadMessagesTotal: 0,
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getMenuRaw: (state) => {
      return state.data;
    },
    getMenuBySections: (state) => {
      return state.menuBySections;
    },
    getGroups: (state) => {
      return state.groups;
    },
    getGroupsPinned: (state) => {
      return state.groupsPinned;
    },
    getGroupsUnpinned: (state) => {
      return state.groupsUnpinned;
    },
    getUnreadMessagesTotal: (state) => {
      return state.unreadMessagesTotal;
    },
  },
  actions: {
    async getMenu(): Promise<void> {
      this.errors = [];
      this.loading = true;

      const response = await $api.menu.getCustomMenu();

      if (response.statusCode === 200) {
        const model = response as ResponseMenuModel;
        this.data = model.data;
        await this.getGroupsFromMenu();
        this.countUnreadMessagesTotal();
        this.setGroupsPinned();
        this.setGroupsUnpinned();
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
    },
    async pinGroup(groupId: number): Promise<boolean> {
      const response = await $api.menu.pinGroup(groupId);

      if (response.statusCode === 200) {
        const index = this.groups.findIndex((item) => item.id === groupId);
        this.groups[index].isPin = true;
        this.setGroupsPinned();
        this.setGroupsUnpinned();
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async unpinGroup(groupId: number): Promise<boolean> {
      const response = await $api.menu.unpinGroup(groupId);

      if (response.statusCode === 200) {
        const index = this.groups.findIndex((item) => item.id === groupId);
        this.groups[index].isPin = false;
        this.setGroupsPinned();
        this.setGroupsUnpinned();
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async getGroupsFromMenu() {
      if (this.data?.sections?.length) {
        this.groups =
          (this.data.sections.find((section) => section.sectionType === 'Groups')?.items as MenuGroupItemModel[]) || [];
      }
    },
    setGroupsPinned() {
      this.groupsPinned = this.groups.filter((group) => group.isPin);
    },
    setGroupsUnpinned() {
      this.groupsUnpinned = this.groups.filter((group) => !group.isPin);
    },
    countUnreadMessagesTotal() {
      for (const group of this.groups) {
        if (group.unreadMessagesCount) {
          this.unreadMessagesTotal += group.unreadMessagesCount;
        } else if (group.stats?.messages) {
          this.unreadMessagesTotal += group.stats.messages;
        }
      }
    },
    addNewGroup(group: GroupEntity) {
      const processedGroup = processGroup(group);
      this.groups.unshift(processedGroup);
      this.setGroupsPinned();
      this.setGroupsUnpinned();
    },
    // async createMenuBySections() {
    //   if (this.data?.sections?.length) {
    //     for (const section of this.data.sections) {
    //       this.menuBySections[section.sectionType].title = section.title;
    //       this.menuBySections[section.sectionType].isPublic = section.isPublic;
    //       this.menuBySections[section.sectionType].items = (
    //         section.items || []
    //       ).map((sectionItem) => {
    //         if (sectionItem.type === 'File' && 'id' in sectionItem) {
    //           this.fileItems.push(sectionItem);
    //         }
    //         if (sectionItem.type === 'Group') {
    //           return processGroup(sectionItem as MenuGroupItemModel);
    //         } else {
    //           return sectionItem;
    //         }
    //       });
    //     }
    //   }
    //   return;
    // },
    // async fetchFileItemsInfo() {
    //   if (this.fileItems.length !== 0) {
    //     const docStore = useDocStore();
    //     const results = await Promise.allSettled(
    //       this.fileItems.map((file) => docStore.getDocById(file.id))
    //     );
    //     results.forEach((result, index) => {
    //       if (result.status === 'fulfilled' && result.value !== undefined) {
    //         this.fileItems[index] = Object.assign(
    //           {},
    //           this.fileItems[index],
    //           result.value
    //         );
    //       }
    //     });
    //   }
    //   return;
    // },
    // mapItemsWithSections(menuItems: MenuItemModel[]) {
    //   const keys = Object.keys(this.menuBySections);
    //   for (const sectionKey of keys) {
    //     const section = this.menuBySections[sectionKey as keyof MenuBySections];
    //     if (sectionKey !== 'Groups') {
    //       section.items = (section.items || [])
    //         .map((sectionItem) => {
    //           const mappedItem = menuItems.find(
    //             (item) => item.type === sectionItem.type
    //           );
    //           if (sectionItem.type === 'File' && 'id' in sectionItem) {
    //             // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //             return this.fileItems.find(
    //               (fileItem) => fileItem.id === sectionItem.id
    //             )!;
    //           }
    //           return mappedItem ? assign(sectionItem, mappedItem) : sectionItem;
    //         })
    //         .filter(
    //           (sectionItem) => !NOT_AVAILABLE_ITEMS.includes(sectionItem.type)
    //         );
    //     }
    //   }
    // },
  },

  persist: true,
});
