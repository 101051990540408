import type { ResponseErrorModel, ResponseSuccessModel, ResponseUsageRules, SetUsageRulesPayload } from '@/@types';
import axios from '@/services/axios';

export class NetworkAdministrationApiService {
  async getUsageRules(): Promise<ResponseUsageRules | ResponseErrorModel> {
    return axios.get('/networks/usageRules');
  }

  async setUsageRules(payload: SetUsageRulesPayload): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/networks/usageRules', payload);
  }
}
