import { defineStore } from 'pinia';

import type { DocumentExtensionEnum } from '@/@enums';
import type {
  TopicEntity,
  WikiTempModel,
  WikiModel,
  WikiVersionModel,
  WikiHistoryByIdModel,
  CreateWikiPayload,
  UpdateWikiPayload,
  UpdateWikiTemplatePayload,
  WikiRelationsModel,
  WikiGitDiffModel,
  ResponseWikiModel,
  ResponseWikiCreateModel,
  ResponseWikiTempModel,
  ResponseWikiRelationsModel,
  ResponseWikiVersionsModel,
  ResponseWikiHistoryById,
  ResponseErrorModel,
} from '@/@types';
import { useRichTextHelper } from '@/helpers';
import { $api } from '@/services';

interface WikiState {
  currentWiki: WikiModel | null;
  wikiId: number | null;
  isOfficial: boolean;
  mentionedUserIds: number[];
  wikiRelations: WikiRelationsModel;
  // Template
  currentTemplate: WikiTempModel | null;
  tempWikiId: number | null;
  // Diff related states
  sourceWikiVersion: WikiVersionModel | null;
  targetWikiVersion: WikiVersionModel | null;
  availableWikiVersions: WikiVersionModel[];
  availableHistoricalWikis: WikiHistoryByIdModel[];
  currentGitDiff: WikiGitDiffModel;
  appRichForm: {
    name: string;
    text: string;
  };
  isLoading: boolean;
  messageFromAi: { name: string; content: string } | null;
}

export const useWikiStore = defineStore({
  id: 'wiki',

  state: (): WikiState => ({
    currentWiki: null,
    wikiId: null,
    isOfficial: false,
    mentionedUserIds: [],
    wikiRelations: {
      data: [],
      loadMoreUrl: null,
    },
    // Template
    currentTemplate: null,
    tempWikiId: null,
    // Diff related states
    sourceWikiVersion: null,
    targetWikiVersion: null,
    availableWikiVersions: [],
    availableHistoricalWikis: [],
    currentGitDiff: { sourceId: null, targetId: null, diff: '' },
    appRichForm: {
      name: '',
      text: '',
    },
    isLoading: false,
    messageFromAi: null,
  }),

  getters: {
    author: (state) => (state.currentWiki ? state.currentWiki.author : null),
    lastEditor: (state) => (state.currentWiki?.modifier ? state.currentWiki.modifier : null),
    group: (state) => (state.currentWiki ? state.currentWiki.group : null),
    getIsOfficial: (state): boolean => state.isOfficial,
  },

  actions: {
    //TODO: later there will be a complete wiki store as doc store
    // wikisFromSearch(wikis: WikiModel[], loadMoreUrl: string | null = null): void {
    //   if (wikis.length) {
    //     this.data = mergeById(this.data, wikis);
    //     this.wikis.search.data = mergeById(this.wikis.search.data, wikis);
    //     this.wikis.search.loadMoreUrl = loadMoreUrl;
    //   }
    // },
    setMessageFromAi(name: string, content: string): void {
      this.messageFromAi = { name, content };
    },
    async getWikiById(id: number): Promise<boolean> {
      this.isLoading = true;
      try {
        const response = await $api.wiki.getWikiFromId(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiModel;
          model.data.bodyHtml = await useRichTextHelper().preprocessBody(model.data.wikiText);
          this.currentWiki = model.data;
          this.isOfficial = model.data.isOfficial;
          this.wikiId = model.data.id;

          return true;
        } else {
          this.currentWiki = null;
          this.isOfficial = false;
          this.wikiId = null;

          return false;
        }
      } catch (e) {
        console.error(e);
        this.currentWiki = null;
        this.isOfficial = false;
        this.wikiId = null;

        return false;
      } finally {
        this.isLoading = false;
      }
    },

    async createWiki(payload: CreateWikiPayload): Promise<number | null> {
      try {
        payload.text = useRichTextHelper().preSubmit(payload.text);
        const response = await $api.wiki.create(payload);

        if (response.statusCode === 200) {
          const { data } = response as ResponseWikiCreateModel;

          this.wikiId = data;
        } else {
          this.wikiId = null;
        }
      } catch (e) {
        console.error(e);
        this.wikiId = null;
      }
      return this.wikiId;
    },

    async updateWiki(payload: UpdateWikiPayload): Promise<boolean> {
      try {
        payload.text = useRichTextHelper().preSubmit(payload.text);
        const result = await $api.wiki.update(payload);
        if (result.statusCode === 200) {
          return true;
        }
        return false;
      } catch (e) {
        console.error('Failed to update wiki', e);
        return false;
      }
    },

    async downloadWiki(id: number, documentExtension: DocumentExtensionEnum): Promise<Blob | ResponseErrorModel> {
      try {
        const response = await $api.wiki.downloadWiki(id, documentExtension);

        return response;
      } catch (e) {
        console.error('Failed to download wiki', e);
        return e as ResponseErrorModel;
      }
    },

    async makeOfficial(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.markAsOfficial(id);

        if (response.statusCode === 200) {
          this.$patch({
            isOfficial: !this.isOfficial,
          });
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to make wiki official', e);
        return false;
      }
    },

    async showRelations(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.showRelations(id);

        if (response.statusCode === 200) {
          const { data } = response as ResponseWikiRelationsModel;
          this.wikiRelations.data = data.data;
          this.wikiRelations.loadMoreUrl = data.loadMoreUrl;

          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error('Failed to show relations', e);
        return false;
      }
    },

    async deleteWiki(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.delete(id);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to delete wiki', e);
        return false;
      }
    },

    async deleteWikiAndReplace(
      id: number,
      relationWikiId: number | null,
      relationFileId: number | null
    ): Promise<boolean> {
      try {
        const response = await $api.wiki.deleteWikiAndReplace(id, relationWikiId, relationFileId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to delete wiki and replace', e);
        return false;
      }
    },

    async getWikiHistory(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getHistory(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiVersionsModel;
          this.availableWikiVersions = model.data;

          return true;
        } else {
          this.availableWikiVersions = [];

          return false;
        }
      } catch (e) {
        console.error('Failed to get available wiki versions', e);
        this.availableWikiVersions = [];

        return false;
      }
    },

    async getAvailableHistoricalWikis(id: number): Promise<WikiHistoryByIdModel | null> {
      const response = await $api.wiki.getHistoricalWikiById(id);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiHistoryById;
        const found = this.availableHistoricalWikis.find((wiki) => wiki.id === model.data.id);
        if (!found) {
          this.availableHistoricalWikis.push(model.data);
        }
        return model.data;
      } else {
        return null;
      }
    },

    // Template
    async getCurrentTemplate(): Promise<boolean> {
      try {
        const response = await $api.wiki.getCurrentTemplate();

        if (response.statusCode === 200) {
          const model = response as ResponseWikiTempModel;
          if (model?.data?.text) {
            model.data.text = await useRichTextHelper().preprocessBody(model?.data?.text);
          }
          this.currentTemplate = model.data;
          this.tempWikiId = model?.data?.tempWikiId as number;

          return true;
        } else {
          this.currentTemplate = null;
          this.tempWikiId = null;

          return false;
        }
      } catch (e) {
        console.error('Failed to get current template', e);
        this.currentTemplate = null;
        this.tempWikiId = null;
        return false;
      }
    },

    async getTemplateById(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getTemplateById(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiTempModel;
          if (model.data) {
            if (model.data.text) {
              model.data.text = await useRichTextHelper().preprocessBody(model.data.text);
            }
            this.currentTemplate = model.data;
            this.tempWikiId = model.data.tempWikiId as number;

            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (e) {
        console.error('Failed to get template by id', e);
        return false;
      }
    },

    async updateWikiTemplate(payload: UpdateWikiTemplatePayload): Promise<boolean> {
      try {
        payload.text = useRichTextHelper().preSubmit(payload.text);
        const response = await $api.wiki.updateCurrentTemplate(payload);

        if (response.statusCode === 200 && (response as ResponseWikiTempModel).data !== null) {
          const { data } = response as ResponseWikiTempModel;

          if (data?.text) {
            data.text = await useRichTextHelper().preprocessBody(data.text);
          }
          this.currentTemplate = data;
          this.tempWikiId = data?.tempWikiId as number;
          return true;
        } else {
          this.currentTemplate = null;
          return false;
        }
      } catch (e) {
        console.error(e);
        this.currentTemplate = null;
        return false;
      }
    },

    async deleteWikiTemplate(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.deleteTemplate(id);

        if (response.statusCode === 200) {
          this.currentTemplate = null;
          this.tempWikiId = null;
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);

        return false;
      }
    },

    // Tags
    async addTag(id: number, tags: TopicEntity[]): Promise<boolean> {
      try {
        const response = await $api.wiki.addTag(
          id,
          tags.map(({ title }) => title)
        );

        return response.statusCode === 200;
      } catch (e) {
        console.error(e);

        return false;
      }
    },

    async deleteTag(id: number, tagId: number): Promise<boolean> {
      try {
        const response = await $api.wiki.deleteTag(id, tagId);

        return response.statusCode === 200;
      } catch (e) {
        console.error(e);

        return false;
      }
    },

    setMentionedUserId(ids: number[]) {
      this.mentionedUserIds = [...this.mentionedUserIds, ...ids];
    },
  },
  persist: true,
});
