import { $api } from '@/services';

export const useSearchAutocomplete = (type: string): { autocomplete: (searchText: string) => Promise<[] | null> } => {
  const autoCompleteEndpoint = {
    user: $api.user.autocomplete,
    group: $api.group.autocomplete,
    topic: $api.topic.autocomplete,
  };

  const autocomplete = async (searchText: string): Promise<[] | null> => {
    if (!searchText) return null;

    let result = null;
    if (type === 'group') {
      result = await autoCompleteEndpoint.group(searchText, false);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result = await autoCompleteEndpoint[type](searchText);
    }

    if (result?.statusCode === 200) {
      return result?.data || null;
    }

    return null;
  };

  return {
    autocomplete,
  };
};
