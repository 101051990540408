import { cloneDeep, orderBy, sumBy, unionBy, find } from 'lodash';
import { defineStore } from 'pinia';

import { ChainTypeEnum, MessageDeliveryStatusEnum, MessageTypeEnum } from '@/@enums';
import type {
  ErrorMessageModel,
  LastMessageModel,
  MessageChainEntity,
  ResponseChainsModel,
  ResponseErrorModel,
  ResponseUnreadModel,
  ResponseSuccessModel,
  ResponseChainModel,
  ResponseMessagesModel,
} from '@/@types';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import { useChatStore } from '@/store';

interface MessengerState extends EntityState<MessageChainEntity> {
  isInit: boolean;
  isActive: boolean;
  loadMoreUrl: string | null;
  disableLoadMore: boolean;
}

export const useMessengerStore = defineStore({
  id: 'messenger',
  state: (): MessengerState => ({
    data: [],
    errors: [],
    loading: false,
    isInit: false,
    isActive: true,
    loadMoreUrl: null,
    disableLoadMore: false,
  }),
  getters: {
    autocomplete: (state) => (text: string) => {
      text = text.toLowerCase();
      return text.length > 0
        ? state.data.filter((n: MessageChainEntity) => n.title.toLowerCase().includes(text))
        : state.data;
    },
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getByType:
      (state) =>
      (type: ChainTypeEnum): MessageChainEntity[] =>
        orderBy(
          state.data.filter((f: MessageChainEntity) => f.chainType === type),
          ['lastMessage.createdAt'],
          ['desc']
        ),
    getLastMessageId: (state) => () => {
      const maxId = Math.max(...state.data.map((n) => n.lastMessage.id));
      return isFinite(maxId) ? maxId : null;
    },
    isEmptyList: (state) => (): boolean => {
      return state.data.length === 0;
    },
    getChainById:
      (state) =>
      (chainId: number): MessageChainEntity | undefined => {
        return state.data.find((n) => n.chainId === chainId);
      },
    getUnreadMessagesCount: (state): number => sumBy(state.data, (n) => n.unreadMessagesCount),
    getLoadMoreUrl: (state) => () => state.loadMoreUrl,
  },
  actions: {
    async getChains() {
      this.$reset();
      this.loading = true;
      const response = await $api.messenger.getChains();

      if (response.statusCode === 200) {
        const model = response as ResponseChainsModel;

        this.data = model.data;
        this.loadMoreUrl = model.loadMoreUrl;

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
    },
    async loadMore(url: string): Promise<boolean> {
      const response = await $api.messenger.getByUrl(url);

      if (response.statusCode === 200) {
        const model = response as ResponseChainsModel;

        this.data = mergeById(this.data, model.data);
        this.loadMoreUrl = model.loadMoreUrl;
        this.errors = [];

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    setDisableLoadMore(state: boolean) {
      this.disableLoadMore = state;
    },
    async chainById(chainId: number): Promise<void> {
      const response = await $api.messenger.getChainById(chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseChainModel;

        const chain = model.data;
        const index = this.data.findIndex((n) => n.chainId === chain.chainId);
        if (~index) {
          this.data[index] = cloneDeep(chain);
        } else {
          this.data.unshift(chain);
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return;
    },
    async chainByUserId(userId: number): Promise<void> {
      const response = await $api.messenger.getByUserId(userId);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;
        const chainId = model.data[0].chainId;
        await this.chainById(chainId);
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },
    async updateUnreadCounter() {
      const chatStore = useChatStore();

      const response = await $api.messenger.getUnread(this.getLastMessageId());
      if (response.statusCode === 200) {
        const model = response as ResponseUnreadModel;

        model.data.forEach((m) => {
          const index = this.data.findIndex(({ chainId }) => chainId === m.chainId);
          if (~index) {
            this.updateLastMessage(m, m.status !== MessageDeliveryStatusEnum.Delivered);
          }
        });

        // If user have an active chain and last message is not the same as in the store
        if (chatStore.chain) {
          const currentChainId = chatStore.chain.chainId;
          if (!currentChainId) return;

          const currentChain = chatStore.chats[currentChainId];
          if (!currentChain) return;

          const currentChainMessages = currentChain.data;
          const lastMessageInCurrentChain = currentChainMessages.at(-1);

          if (lastMessageInCurrentChain?.id !== this.getChainById(currentChainId)?.lastMessage.id) {
            await chatStore.updateChain(chatStore.chain, true);
          }
        }
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
    },
    updateLastMessage(message: LastMessageModel, isNew: boolean) {
      const index = this.data.findIndex(({ chainId }) => chainId === message.chainId);

      if (~index) {
        this.data[index].lastMessage = message;
        const chatStore = useChatStore();

        chatStore.setUpdate();
        if (isNew && message.type !== MessageTypeEnum.Init && message.type !== MessageTypeEnum.CreateGroup) {
          this.data[index].unreadMessagesCount++;
        }
      }

      this.errors = [];
    },
    async changeChainType(id: number, type: ChainTypeEnum): Promise<boolean> {
      const chatStore = useChatStore();
      let response: ResponseSuccessModel | ResponseErrorModel | null = null;
      if (type === ChainTypeEnum.Active) {
        response = await $api.messenger.moveToActive(id);
      }
      if (type === ChainTypeEnum.Archive) {
        response = await $api.messenger.moveToArchive(id);
      }

      if (response?.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === id);
        this.data[index].chainType = type;
        if (chatStore.chain?.chainId === id) {
          chatStore.chain.chainType = type;
        }
        return true;
      }

      if (response?.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async muteChainChange(chainId: number, mute: boolean): Promise<boolean> {
      const chatStore = useChatStore();
      const response = await $api.messenger.muteChainChange(chainId, mute);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].muted = mute;
        if (chatStore.chain?.chainId === chainId) {
          chatStore.chain.muted = mute;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteChain(chainId: number): Promise<boolean> {
      const response = await $api.messenger.deleteChain(chainId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data.splice(index, 1);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async exitChain(chainId: number): Promise<boolean> {
      const response = await $api.messenger.exitChain(chainId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].isInChain = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async renameChain(chainId: number, chainTitle: string): Promise<boolean> {
      const response = await $api.messenger.renameChain(chainId, chainTitle);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].title = chainTitle;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    resetUnreadMessagesCount(chainId: number) {
      const index = this.data.findIndex((n) => n.chainId === chainId);
      if (this.data[index]) {
        this.data[index].unreadMessagesCount = 0;
      }
    },
    updateMessengerData(a: MessageChainEntity[], b: MessageChainEntity[]) {
      this.data = mergeById(a, b);
    },
  },
  persist: true,
});

const mergeById = (a: MessageChainEntity[], b: MessageChainEntity[]) => {
  return unionBy(a, b, 'chainId').map((obj) => {
    const match = find(b, { chainId: obj.chainId });
    return match ? Object.assign({}, obj, match) : obj;
  });
};
