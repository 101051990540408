import { LocalNotifications } from '@capacitor/local-notifications';
import type { OverlayEventDetail } from '@ionic/core';
import { alertController, modalController } from '@ionic/vue';
import type { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { ref } from 'vue';

import {
  ChainTypeEnum,
  MeetStatusEnum,
  MessageDeliveryStatusEnum,
  MessageDirectionTypeEnum,
  MessageTypeEnum,
} from '@/@enums';
import type {
  CallResultSignalRModel,
  CallSignalRModel,
  EditMessageModel,
  MessageChainEntity,
  MessageEntity,
  MessagesReadModel,
  UserTyping,
  WebSocketModel,
} from '@/@types';
import {
  appMeets,
  componentMeetCallModal,
  componentMeetRoom,
  htmlToText,
  isNativeMobile,
  showDismissingToast,
  showToast,
  toLastModel,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { useAppStore, useChatStore, useMeetStore, useMessengerStore, useUserStore } from '@/store';

interface IWebSockets {
  initWebSockets: (model: WebSocketModel | null) => Promise<void>;
  startWebSockets: () => Promise<boolean>;
  stopWebSockets: () => Promise<boolean>;
  typingEvents: any;
  isActive: boolean;
  connection?: HubConnection;
}

let webSocketsInstance: IWebSockets | null = null;

export function useWebSockets(): IWebSockets {
  if (webSocketsInstance) {
    return webSocketsInstance;
  }

  let connection: HubConnection | undefined;
  let isActive = false;
  const typingEvents = ref<UserTyping[]>([]);

  const _changeTyping = (data: UserTyping): void => {
    const index = typingEvents.value.findIndex(
      (f: UserTyping) => f.chainId === data.chainId && f.userId === data.userId
    );
    if (~index) {
      typingEvents.value.splice(index, 1, data);
    } else {
      typingEvents.value.push(data);
    }
  };

  const _onReconnected = async (connectionId?: string | undefined): Promise<void> => {
    const meetStore = useMeetStore();
    const model = useAppStore().getWebSocketModel;
    if (!model) {
      console.error('Reconnection failed - model is null', model, connectionId);
      return;
    }

    console.log('Connection reconnected', connectionId); //! DEBUG

    if (!connection) {
      console.error('Reconnection failed - connection is null', connection);
      return;
    }

    const invokeModel = {
      coreId: model.coreId,
      companyRowId: model.companyRowId,
      userId: model.userId,
      userRowId: model.userRowId,
    };
    await connection.invoke('connect', invokeModel);

    meetStore.$patch((state) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.connectionId = connection!.connectionId;
    });
  };

  const _onTyping = (data: UserTyping): void => {
    console.log(`User ${data.userId} is typing - ${data.typing}`); //! DEBUG

    _changeTyping({
      userId: +data.userId,
      chainId: +data.chainId,
      typing: data.typing,
    });
  };

  const _onUserConnected = (userId: number): void => {
    const appStore = useAppStore();

    console.log(`User ${userId} connected`); //! DEBUG

    const index = appStore.onlineUsers.findIndex((u: number) => u === userId);
    if (index < 0) {
      appStore.userConnected(userId);
    }

    console.log(`Connected users: ${appStore.onlineUsers}`); //! DEBUG
  };

  const _onUserDisconnected = (userId: number): void => {
    const appStore = useAppStore();

    console.log(`User ${userId} disconnected`); //! DEBUG

    appStore.userDisconnected(userId);

    console.log(`Connected users: ${appStore.onlineUsers}`); //! DEBUG
  };

  const _getMessageBody = (newMess: MessageEntity): string => {
    const { t } = useI18n();

    const messageBody = (message: MessageEntity): string => {
      const withFiles =
        message.attachedFiles.count > 0 ||
        (message?.forwardedAttachedFiles && message.forwardedAttachedFiles.count > 0);

      if (message.bodyHtml) {
        return htmlToText(message.bodyHtml);
      } else if (withFiles) {
        return t('messenger.messengerSend.file');
      }
      return '';
    };

    switch (newMess.messageType) {
      case MessageTypeEnum.Quote:
        {
          if (newMess?.originalMessage) {
            return t('messenger.chatPage.forwarded');
          }
        }
        break;

      case MessageTypeEnum.Forward:
      case MessageTypeEnum.ForwardOld: {
        return t('messenger.chatPage.forwarded');
      }

      default:
        return messageBody(newMess);
    }
    return '';
  };

  const _mobileScheduleNotification = async (newMess: MessageEntity): Promise<void> => {
    const scheduleObj = {
      notifications: [
        {
          title: newMess.authorFullName,
          body: _getMessageBody(newMess),
          id: newMess.chainId,
          actionTypeId: 'NEW_CHAT_MESSAGE',
        },
      ],
    };
    await LocalNotifications.schedule(scheduleObj);
  };

  const _onPushMessage = async (message: MessageEntity): Promise<void> => {
    const appStore = useAppStore();
    const messengerStore = useMessengerStore();
    const chatStore = useChatStore();
    const currentChainId = chatStore.getId;
    const currentUserId = useUserStore().getId;
    const model = appStore.getWebSocketModel;

    if (!model) {
      console.error('Pushing message failed - model is null', model);
      return;
    }

    const newMess = message.originalMessage as MessageEntity;
    if (model.userId === newMess.authorId) {
      console.log('Message from the current user, no need to update anything'); //! DEBUG
      return;
    }

    newMess.direction = MessageDirectionTypeEnum.Incoming;
    console.log(`User ${newMess.authorId} sent a message - `, newMess); //! DEBUG

    _changeTyping({
      userId: newMess.authorId,
      chainId: newMess.chainId,
      typing: false,
    });

    let existedChain = messengerStore.getChainById(newMess.chainId);
    // If chain is not currently in the local storage - trying to get it
    if (!existedChain) {
      await messengerStore.chainById(newMess.chainId);
    }
    // Trying to get chain again
    existedChain = messengerStore.getChainById(newMess.chainId);
    if (!existedChain) {
      console.error('Pushing message failed - chain is null', existedChain, newMess.chainId);
      return;
    }
    // If message is in archive chain, change chain type to active
    if (existedChain && existedChain.chainType === ChainTypeEnum.Archive) {
      existedChain.chainType = ChainTypeEnum.Active;
    }

    const chainIsMuted = existedChain?.muted;
    const notificationsIsActive = appStore.localNotifications;

    // If message is incoming, chain is not current, chain is not muted and notifications are active - playing sound
    const shouldPlaySound =
      newMess.direction === MessageDirectionTypeEnum.Incoming &&
      (newMess.chainId !== currentChainId || newMess.chainId === null) &&
      !chainIsMuted &&
      notificationsIsActive;

    if (shouldPlaySound) {
      if (!isNativeMobile) {
        const audioFile = new Audio(appStore.getAppNotificationSoundPath);
        await audioFile.play();
      } else {
        await _mobileScheduleNotification(newMess);
      }
    }

    //TODO: Waiting for API - https://gitlab.united-grid.com/intra/core/-/issues/668
    await chatStore.updateChain(existedChain, false);

    messengerStore.updateLastMessage(toLastModel(newMess), model.userId !== newMess.authorId);

    // If the message is in the current chain and the author is not the current user - reading the message
    if (newMess.chainId === currentChainId && newMess.authorId !== currentUserId) {
      chatStore.read(
        {
          authorId: newMess.authorId,
          chainId: newMess.chainId,
          messageId: newMess.id,
          status: MessageDeliveryStatusEnum.ReadAll,
          uniqueId: newMess.uniqueId,
        } as MessagesReadModel,
        false
      );
      await chatStore.markAsRead(newMess.id);
    }
  };

  const _onEditMessage = (message: MessageEntity): void => {
    const messengerStore = useMessengerStore();

    console.log('editMessage', message.id);

    const mess = message.originalMessage;
    if (mess !== null) {
      useChatStore().redact({
        chainId: mess.chainId,
        message: toLastModel(mess),
        fileInfos: mess.attachedFiles.data,
        uniqueId: mess.uniqueId,
      } as EditMessageModel);

      messengerStore.updateLastMessage(toLastModel(mess), false);
    }
  };

  const _onDeleteMessage = (messageId: number, chainId: number): void => {
    console.log('deleteMessage', messageId, chainId);
    const { t } = useI18n();
    const chatStore = useChatStore();
    const messengerStore = useMessengerStore();
    const currentChainId = chatStore.chain?.chainId;
    const lastMessage = chatStore.delete(messageId, currentChainId as number);

    const index = messengerStore.data.findIndex((f: MessageChainEntity) => f.chainId === chainId);

    if (~index) {
      if (currentChainId === chainId) {
        messengerStore.updateLastMessage(toLastModel(lastMessage), false);
      } else if (messengerStore.data[index].lastMessage.id === messageId) {
        messengerStore.data[index].lastMessage.text = t('messenger.chatPage.deleted');
        messengerStore.data[index].lastMessage.status = MessageDeliveryStatusEnum.Deleted;
      }
    }
  };

  const _registerConnectionEvents = (): void => {
    if (!connection) {
      console.error('Registration of events failed - connection is null', connection);
      return;
    }

    // Connection on closed
    connection.onclose((data: any) => {
      console.log('Connection closed', data); //! DEBUG
    });

    // Connection on reconnected
    connection.onreconnected(_onReconnected);

    // Connection on typing
    connection.on('typing', _onTyping);

    // Connection on users online
    connection.on('usersOnline', (data: number[]) => {
      useAppStore().usersOnline(data);
    });

    // Connection on user connected
    connection.on('userConnected', _onUserConnected);

    // Connection on user disconnected
    connection.on('userDisconnected', _onUserDisconnected);

    // Connection on pushMessage
    connection.on('pushMessage', _onPushMessage);

    connection.on('editMessage', _onEditMessage);

    connection.on('readMessage', (message: MessageEntity) => {
      // console.log('readMessage - message', message); //! DEBUG
      const chatStore = useChatStore();
      chatStore.read(
        {
          authorId: message.authorId,
          chainId: message.chainId,
          messageId: message.id,
          status: message.status,
          uniqueId: message.uniqueId,
        } as MessagesReadModel,
        false
      );
    });

    connection.on('deleteMessage', _onDeleteMessage);
  };

  const _retryConnection = async (appStore = useAppStore()): Promise<void> => {
    const { t } = useI18n();
    try {
      const toast = await showDismissingToast(t('messenger.errorConnect'), t('retry'), false);

      // User tries to reconnect
      toast.onDidDismiss().then(async (event: OverlayEventDetail) => {
        if (event.role === 'retry') {
          await stopWebSockets();
          await initWebSockets(appStore.getWebSocketModel);
        }
      });
    } catch (err) {
      console.error('Reconnection failed', err);
    }
  };

  const _invokeConnection = async (
    connection: HubConnection,
    model: WebSocketModel,
    appStore = useAppStore()
  ): Promise<void> => {
    const meetStore = useMeetStore();

    try {
      const connectModel = {
        coreId: model.coreId,
        companyRowId: model.companyRowId,
        userId: model.userId,
        userRowId: model.userRowId,
      };
      await connection.invoke('connect', connectModel);

      console.log('Successfully invoked method on the server'); //! DEBUG

      appStore.$patch((state) => {
        state.loading = false;
      });

      meetStore.$patch({
        connectionId: connection.connectionId,
      });
    } catch (err) {
      console.error('Failed to invoke method on the server: ' + err);
      appStore.$patch((state) => {
        state.loading = false;
      });
      // If the connection is not established, show a toast with the ability to reconnect
      await _retryConnection(appStore);
    }
  };

  const _startConnection = async (connection: HubConnection, appStore = useAppStore()): Promise<boolean> => {
    try {
      appStore.$patch((state) => {
        state.loading = true;
        state.isWaitingForCompleteLogin = false;
      });

      await connection.start();
      console.log('SignalR connection started', connection.state); //! DEBUG
      return true;
    } catch (err) {
      console.error('SignalR connection error: ' + err);
      appStore.$patch((state) => {
        state.loading = false;
      });
      return false;
    }
  };

  const startWebSockets = async (): Promise<boolean> => {
    const appStore = useAppStore();
    let isConnectSignalR = false;
    console.log('Starting WebSockets'); //! DEBUG

    try {
      const model = appStore.getWebSocketModel;
      if (!model) {
        console.error('Initialization of WebSockets failed - model is null', model);
        return false;
      }

      // If the connection is not initialized, initialize it
      if (!connection) {
        _registerConnectionEvents();
        if (!connection) {
          console.error('Initialization of WebSockets failed - connection is null', connection);
          return false;
        }
      }

      // Setting WebSocket as active
      isActive = true;
      console.log('Current connection state: ', connection.state); //! DEBUG

      appStore.$patch((state) => {
        state.loading = false;
      });

      // Trying to start the connection
      isConnectSignalR = await _startConnection(connection, appStore);

      // Trying to connect to the server
      if (isConnectSignalR) {
        await _invokeConnection(connection, model);
      }
      return isConnectSignalR;
    } catch (err) {
      console.error('Starting WebSockets failed', err);
      return false;
    }
  };

  const _initSignal = async (model: WebSocketModel): Promise<void> => {
    console.log('≥≥≥Configuring SignalR connection', model); //! DEBUG

    //?
    connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${model.webSocket}/chat`)
      .build();

    console.log('SignalR connection created', connection); //! DEBUG

    //?
    _registerConnectionEvents();

    _registerEventsVideo();

    await startWebSockets();
  };

  const initWebSockets = async (model: WebSocketModel | null): Promise<void> => {
    if (!model) {
      console.error('Initialization of WebSockets failed - model is null', model);
      return;
    }

    console.log('Checking if any WS is active', connection, isActive); //! DEBUG
    // Stop the current connection if it exists
    if (connection) {
      console.log('Stopping the current connection', connection); //! DEBUG
      await connection.stop();
    }

    console.log('≥≥≥Initializing WebSockets', model); //! DEBUG
    await _initSignal(model);
  };

  const stopWebSockets = async (): Promise<boolean> => {
    isActive = false;
    try {
      if (!connection) {
        console.warn('Connection is already undefined, nothing to stop'); //! DEBUG
        return false;
      }
      console.log('Stopping WebSockets - ', connection); //! DEBUG

      await connection.stop();
      console.log('Successfully stopped WebSocket connection');
      return true;
    } catch (err) {
      console.error('Stopping WebSockets failed', err);
      return false;
    }
  };

  // Video Calls - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/122
  const _registerEventsVideo = (): void => {
    if (!connection) {
      return;
    }

    const { t } = useI18n();
    const meetStore = useMeetStore();
    const messengerStore = useMessengerStore();
    const appStore = useAppStore();
    const audioFile = new Audio(appStore.getAppCallSoundPath);
    audioFile.loop = true;

    connection.on('call', async (data: CallSignalRModel) => {
      console.log('≥≥≥connection.on("call")≤≤≤', data); //! DEBUG
      if (meetStore.getCurrentRoomId === '') {
        meetStore.$patch({
          currentRoomId: data.roomName,
        });
      }
      meetStore.initRoom(data.roomName);
      const chainInfo = await meetStore.getChainInfo(data.from.chatId, data.roomName);

      if (chainInfo) {
        const existedChain = messengerStore.getChainById(chainInfo.chainId);
        if (!existedChain) {
          await messengerStore.chainById(chainInfo.chainId);
        }
        if (!existedChain) {
          console.error('Pushing message failed - chain is null', existedChain, chainInfo.chainId);
          return;
        }

        if (!existedChain?.muted && !isNativeMobile) {
          try {
            await audioFile.play();
          } catch (e) {
            console.error(e);
          }
        }
      }

      const chatTitle = data.isGroup ? data.from.title : chainInfo?.title || '';
      const caller = chainInfo?.users.filter((f) => f.id !== appStore.userId)[0] || null;
      const chatImage = data.from.image || null;

      meetStore.updateRoom(data.roomName, {
        isGroupCall: data.isGroup,
        userIds: chainInfo?.users.map((user) => user.id),
        isCalling: true,
      });

      const result = await componentMeetCallModal(chatTitle, chatImage, caller);

      if (result?.data) {
        meetStore.updateRoom(data.roomName, {
          isCalling: false,
        });
        await audioFile.pause();
        const meetStatus: MeetStatusEnum = result.data === 'confirm' ? MeetStatusEnum.Accept : MeetStatusEnum.Reject;

        const answerStatus = await meetStore.userAnswer(data.roomName, meetStatus);
        if (answerStatus === undefined) {
          meetStore.deleteRoom(data.roomName);
          await showToast(t('meet.meetRoom.userRejectCall', { user: data.from.title }), false);
          await meetStore.reject(data.roomName);
          return;
        }

        if (meetStatus === MeetStatusEnum.Accept) {
          if (meetStore.getCurrentRoomId !== data.roomName) {
            /** переход в новый звонок */
            await modalController.dismiss(null, 'end', 'callUser');
            await appMeets.disconnectFromRoom();
            meetStore.$patch({
              currentRoomId: data.roomName,
            });
          }
          if (await appMeets.checkDevices(data.roomName)) {
            await componentMeetRoom(t);
          } else {
            if (data.isGroup) {
              meetStore.updateRoom(data.roomName, {
                showActiveCallButton: true,
              });
            } else {
              await meetStore.reject(data.roomName);
            }
            const toast = await alertController.create({
              header: t('meet.meetRoom.deviceBlockedTitle'),
              message: t('meet.meetRoom.deviceBlockedMessage'),
            });
            await toast.present();
          }
        }
        if (meetStatus === MeetStatusEnum.Reject) {
          if (data.isGroup) {
            meetStore.updateRoom(data.roomName, {
              showActiveCallButton: true,
            });
          }
        }
      }
    });

    connection.on('callResult', async (data: CallResultSignalRModel) => {
      console.log('≥≥≥connection.on("callResult")≤≤≤', data); //! DEBUG
      switch (data.result) {
        case MeetStatusEnum.Accept:
          meetStore.$patch({
            currentRoomId: data.roomName,
          });
          meetStore.updateRoom(data.roomName, {
            isRoomActive: true,
            isCalling: false,
          });
          await showToast(t('meet.meetRoom.acceptCall', { user: data.user.fullName }), true);
          break;
        case MeetStatusEnum.Reject:
          /** если участник сам отменил входящий вызов */
          if (data.user.id === appStore.userId) {
            meetStore.updateRoom(data.roomName, {
              isRoomActive: false,
              isCalling: false,
              callUserId: null,
            });
            if (!meetStore.isGroupCall(data.roomName)) {
              meetStore.deleteRoom(data.roomName);
            }
          } else if (data.user.id !== appStore.userId) {
            meetStore.removeParticipant(data.user.id, data.roomName);
            /** пришло событие от другого участника */
            if (
              meetStore.userIds(data.roomName).length === 1 &&
              meetStore.userIds(data.roomName)[0] === appStore.userId
            ) {
              /** условие возможно только для инициатора звонка,
              т.к. для участников не приходит callResult c id инициатора */
              if (meetStore.isRoomActive(data.roomName)) {
                await modalController.dismiss(null, 'end', 'callUser');
                await appMeets.disconnectFromRoom(data.roomName);
              }
            }
          }
          await showToast(t('meet.meetRoom.userRejectCall', { user: data.user.fullName }), false);
          break;
        case MeetStatusEnum.Timeout:
          alert('VideoChat_NotAnswer');
          break;
        case MeetStatusEnum.BusyOnAnotherLine:
          alert('VideoChat_Busy');
          break;
        case MeetStatusEnum.Connecting:
          alert('VideoChat_Connecting');
          break;
      }
    });

    connection.on('callCancel', async (userId: number) => {
      if (meetStore.rooms[meetStore.getCurrentRoomId]) {
        meetStore.removeParticipant(userId);

        const chain = meetStore.getChain();

        const activeParticipants = chain ? await meetStore.getActiveParticipants(chain.chainId) : undefined;

        if (
          (meetStore.userIds() && meetStore.userIds().length === 1 && meetStore.userIds()[0] === appStore.userId) ||
          activeParticipants?.length === 0
        ) {
          if (meetStore.isCalling()) {
            meetStore.deleteRoom(meetStore.getCurrentRoomId);
            const topModal = await modalController.getTop();
            if (topModal && topModal?.id === 'incomingCall') {
              await audioFile.pause();
              await modalController.dismiss();
            }
            return;
          }
          meetStore.updateRoom(meetStore.getCurrentRoomId, {
            isGroupCall: false,
            userIds: [],
          });
          const topModal = await modalController.getTop();
          if (topModal && topModal?.id === 'callUser') {
            await modalController.dismiss(null, 'end', 'callUser');
          }
          await appMeets.disconnectFromRoom();
        }
      }
    });

    connection.on('joinToRoom', (message: any) => {
      console.log('≥≥≥connection.on("joinToRoom")≤≤≤', message); //! DEBUG
      console.log('joinToRoom', message);
    });

    connection.on('userSetVideo', (data: any) => {
      console.log('≥≥≥connection.on("userSetVideo")≤≤≤', data); //! DEBUG
      console.log('remoteUserSetVideo', data);
    });
  };

  webSocketsInstance = {
    initWebSockets,
    startWebSockets,
    stopWebSockets,
    typingEvents,
    isActive,
    connection,
  };

  return webSocketsInstance;
}
