import type { OverlayEventDetail } from '@ionic/core';
import { alertController } from '@ionic/core';

import { showToast } from './helper';

import { AppCardsActionEnum } from '@/@enums';
import type { AppActionButton, UserEntity } from '@/@types';
import { useI18n } from '@/i18n';
import { useUserStore } from '@/store';

interface IUsers {
  getMainUserAction(userData: UserEntity): {
    left: AppActionButton;
    right: AppActionButton;
  };
  onFollow(id: number): Promise<void>;
  onUnfollow(id: number): Promise<void>;
  getUserSubtitle(position: string, subDivision: string): string;
}

export function useUsersHelper(): IUsers {
  const onFollow = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const userStore = useUserStore();
    if (await userStore.onFollow(id)) {
      await showToast(t('subscribe.subscribedToUser'), true);
    }
  };

  const onUnfollow = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const userStore = useUserStore();

    const result = await unFollowConfirm();
    if (result) {
      if (await userStore.unFollow(id)) {
        await showToast(t('subscribe.unsubscribedFromUser'), true);
      }
    }
  };

  const unFollowConfirm = async (): Promise<boolean> => {
    const { t } = useI18n();
    const alert = await alertController.create({
      message: t('subscribe.unfollowConfirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert_buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert_buttons',
          role: 'confirm',
        },
      ],
    });

    await alert.present();

    return alert.onDidDismiss().then(async (result: OverlayEventDetail<boolean>) => {
      return result.role === 'confirm';
    });
  };

  const getMainUserAction = (userData: UserEntity): { left: AppActionButton; right: AppActionButton } => {
    const { t } = useI18n();
    const userStore = useUserStore();
    const currentUserId = () => userStore.current?.id ?? 0;
    const isCurrentUser = () => userData.id === currentUserId();

    const isFollowedByCurrentUser = (): boolean => userData.isFollowedByCurrentUser && !isCurrentUser();

    const actionData: { left: AppActionButton; right: AppActionButton } = {
      left: {
        title: '',
        type: 'main',
        action: AppCardsActionEnum.None,
        icon: '',
      },
      right: {
        title: '',
        type: 'main',
        action: AppCardsActionEnum.None,
        icon: '',
      },
    };

    if (isFollowedByCurrentUser()) {
      actionData.left.title = t('subscribe.unfollow');
      actionData.left.type = 'secondary';
      actionData.left.action = AppCardsActionEnum.UserUnfollow;
      actionData.left.icon = 'userDeleteCross';

      actionData.right.title = t('open');
      actionData.right.type = 'main';
      actionData.right.action = AppCardsActionEnum.Open;
    } else if (isCurrentUser()) {
      actionData.right.title = t('account.title');
      actionData.right.type = 'secondary';
      actionData.right.action = AppCardsActionEnum.Open;
    } else {
      actionData.right.title = t('subscribe.follow');
      actionData.right.type = 'main';
      actionData.right.action = AppCardsActionEnum.UserFollow;
      actionData.right.icon = 'userCheck';
    }

    return actionData;
  };

  const getUserSubtitle = (position: string, subDivision: string): string => {
    return `${subDivision ? subDivision : ''}${position && subDivision ? ', ' : ''}${position ? position : ''}`;
  };

  return {
    getMainUserAction,
    onFollow,
    onUnfollow,
    getUserSubtitle,
  };
}
