<template>
  <div class="post_create-image" @click="attachActionSheetPhoto()">
    <!-- Announcement create mode -->
    <template v-if="!coverImage">
      <div v-if="files.length === 1" class="image-wrapper">
        <file-preview :payload="files[0].payload" />
      </div>
      <div v-else class="null-image">
        <ion-icon :icon="icons.image" />
      </div>
    </template>

    <!-- Announcement edition mode -->
    <template v-else>
      <media-image
        v-if="files.length === 0"
        name="Announcement"
        :url="coverImage"
        cover
        @onView="attachActionSheetPhoto()"
      />
      <div v-else class="image-wrapper">
        <file-preview :payload="files[0].payload" @onView="attachActionSheetPhoto()" />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { IonIcon } from '@ionic/vue';
import { alertCircleOutline, imageOutline } from 'ionicons/icons';
import type { ComputedRef } from 'vue';
import { ref, watch, computed } from 'vue';

import { UploadFileTypes } from '@/@enums';
import type { FileModel } from '@/@types';
import { FilePreview, MediaImage } from '@/components';
import { filesHybrid, useErrors } from '@/helpers';
import { useI18n } from '@/i18n';

// Props
const props = defineProps({
  announcementCurrentPhoto: {
    type: String || null,
    default: () => null,
  },
  coverDeleteFlag: {
    type: Boolean,
    default: false,
  },
});

const icons = {
  image: imageOutline,
  alert: alertCircleOutline,
};

// Others
const { t } = useI18n();
const { handleError } = useErrors();

// Variables
const files = ref<FileModel[]>([]);
const isLoading: ComputedRef<boolean> = computed(() => filesHybrid.isLoading.value);
const coverImage: ComputedRef<string> = computed(() => props.announcementCurrentPhoto ?? '');

// Actions
const attachActionSheetPhoto = async () => {
  console.log('≥≥≥attachActionSheetPhoto'); //! DEBUG
  // Picking file
  const attachFiles = await filesHybrid.pickFiles(UploadFileTypes.SingleImage);

  // Saving file
  if (attachFiles.length > 0) {
    const attached = [];
    for (let i = 0; i < attachFiles.length; i++) {
      const _file = await filesHybrid.toFile(attachFiles[i]);
      if (_file !== undefined) {
        files.value.unshift(_file);
        attached.push({
          index: i,
          payload: _file.payload,
        });
      } else {
        handleError(true, undefined, t('uploadFileIsLarger', { size: import.meta.env.VITE_APP_MAX_SIZE }));
      }
    }

    // Uploading file
    for (const file of attached) {
      const uploadFile = await filesHybrid.uploadFile(attachFiles[file.index]);
      const index = files.value.findIndex((f) => f.payload === file.payload);
      if (~index && uploadFile !== undefined) {
        files.value.splice(index, 1, uploadFile);
      }
    }

    emit('onPhotoSelected', files.value[0].key);
    emit('onPhotoUploaded', files.value[0].image);
  }
};

// Watchers
watch(isLoading, () => {
  emit('onPhotoLoading', isLoading.value);
});

watch(
  () => props.coverDeleteFlag,
  (newFlag, oldFlag) => {
    if (newFlag !== oldFlag && newFlag) {
      files.value = [];
    }
  }
);

// Emits
const emit = defineEmits(['onPhotoSelected', 'onPhotoUploaded', 'onPhotoLoading']);
</script>
<style scoped lang="scss">
.post_create-image {
  position: relative;
  height: 9rem;
  border: 1px solid var(--ion-color-medium);
  border-radius: app-radius(md);
  margin-top: 1rem;
}
.post_create-image:hover {
  cursor: pointer;
  opacity: 0.7;
}
.post_create-image .image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.post_create-image .null-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.post_create-image .null-image ion-icon {
  font-size: 3rem;
  color: var(--ion-color-medium);
}
.post_create-image img {
  height: 9rem;
  width: 100%;
  object-fit: cover;
  border-radius: app-radius(md);
}
</style>
