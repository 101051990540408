import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import type { OverlayEventDetail } from '@ionic/core';
import { getPlatforms } from '@ionic/core';
import { isPlatform, toastController, alertController, menuController, actionSheetController } from '@ionic/vue';
import type { Route } from '@sentry/vue/types/router';
import { useDateFormat, useFavicon } from '@vueuse/core';
import type { CapacitorVideoPlayerPlugin } from 'capacitor-video-player';
import { CapacitorVideoPlayer } from 'capacitor-video-player';
import {
  chatboxOutline,
  calendarOutline,
  podiumOutline,
  colorWandOutline,
  megaphoneOutline,
  ribbonOutline,
  layersOutline,
  eyeOutline,
  downloadOutline,
  moveOutline,
  textOutline,
  trashOutline,
  shareSocialOutline,
  cloudUploadOutline,
  browsersOutline,
  alertCircleOutline,
  pencilOutline,
  bookmarkOutline,
  mailOutline,
  mailUnreadOutline,
  starOutline,
  notificationsOutline,
  notificationsOffOutline,
  copyOutline,
  openOutline,
  arrowUndoOutline,
  arrowRedoOutline,
  arrowUpCircleOutline,
  timerOutline,
  cloudDownloadOutline,
  starSharp,
  linkOutline,
  folderOpenOutline,
  documentOutline,
  checkmarkCircleOutline,
  closeOutline,
  archiveOutline,
} from 'ionicons/icons';
import { filter } from 'lodash';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import type { Router, RouteLocationNormalizedLoaded, RouteRecordName } from 'vue-router';

import DocIconAsStringOnly from '../../assets/icon/docIconAsStringOnly.svg';
import FolderIconAsStringOnly from '../../assets/icon/folderIconAsStringOnly.svg';
import UploadFolderIconAsStringOnly from '../../assets/icon/uploadFolderIconAsStringOnly.svg';
import UploadIconAsStringOnly from '../../assets/icon/uploadIconAsStringOnly.svg';
import WikiCompareAsStringOnly from '../../assets/icon/wikiCompareAsStringOnly.svg';
import WikiHistoryAsStringOnly from '../../assets/icon/wikiHistoryAsStringOnly.svg';
import WikiIconAsStringOnly from '../../assets/icon/wikiIconAsStringOnly.svg';

import { docBrowserMainMenuSheet, postCreateContextMenuSheet, wikiActionsMenuSheet } from './actionSheetComponents';
import {
  componentDocsCreateFile,
  componentDocsCreateFolder,
  componentDocsShareFile,
  componentDocsUploadFile,
  /* componentDocsUploadFolder, */
  componentPostCreateMobile,
  docBrowserMainMenu,
  postCreateContextMenuModal,
  wikiActionsMenu,
} from './modalComponents';

import {
  PostsFilterEnum,
  SendKeyEnum,
  FeedTypeEnum,
  GroupInvitationStatusEnum,
  GroupsAccessEnum,
  PostTypeActionEnum,
  UserRoleEnum,
  DocBrowserModeEnum,
  AppAlertTypeEnum,
  PageToPostCreateEnum,
  DefaultUserItemTypesEnum,
  GroupsTypeEnum,
  FileMenuActionEnum,
  DocumentTypeEnum,
  ActionAccessEnum,
  ConversationsFlagEnum,
  ConversationsTypeEnum,
  PostMenuActionEnum,
  WikiActionEnum,
  MessageActionEnum,
  CommentActionEnum,
  PostUploadFileEnum,
  DocsMenuActionEnum,
  CountriesEnum,
  IdeaTypeEnum,
  FeedContextMenuActionEnum,
  AppCardsActionEnum,
  UploadFileTypes,
  UserAdminAccessLevel,
  CalendarViewModeEnum,
} from '@/@enums';
import type {
  DocEntity,
  DocsMenuItemModel,
  GroupEntity,
  TopicColorModel,
  PostEntity,
  PostMenuItemModel,
  WikiModel,
  MessageEntity,
  MessageMenuModel,
  WikiActionsMenuMenuModel,
  TabCategories,
  CommentMenuModel,
  PostUploadFileMenuModel,
  FolderModel,
  AutoLoginModel,
} from '@/@types';
import {
  useUserFlow,
  useGroupsHelper,
  useOfficeHelper,
  useTaskManagementHelper,
  useUsersHelper,
  useWikiActionsHelper,
  setServiceUrl,
  useNetworksHelper,
  useErrors,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import {
  usePostStore,
  useAppStore,
  useUserStore,
  useDocStore,
  useNetworkStore,
  useGroupsStore,
  resetAllStores,
  useWikiStore,
} from '@/store';

export const appTimeout = 25;
export const appScrollTop = 250;
export const appDebounce = 250;

const { toString } = Object.prototype;

export const isDesktop =
  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) &&
  !getPlatforms().includes('ipad');
export const isDesktopOrTablet =
  !/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) ||
  isPlatform('ipad') ||
  isPlatform('tablet');
export const isNativeMobile = Capacitor.isNativePlatform();
export const isNativeAndroid = Capacitor.isNativePlatform() && isPlatform('android');
export const isNativeIOS = Capacitor.isNativePlatform() && isPlatform('ios');
export const isWebMobile = isPlatform('mobileweb');
export const isAnyMobile = (isNativeMobile || isWebMobile) && !isDesktop;

export function isBlob(value: any): boolean {
  if (typeof Blob === 'undefined') {
    return false;
  }

  return value instanceof Blob || toString.call(value) === '[object Blob]';
}

export function getUtcNow(minus?: number): Date {
  const date = new Date();
  const nowUtc = Date.UTC(
    minus ? date.getUTCFullYear() - minus : date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(nowUtc);
}

export function getIsoNow(): string {
  return getUtcNow().toISOString();
}

export function getLetters(text: string | null): string {
  if (text === null) {
    return '';
  }

  const name = text.trim().split(' ');
  let letters;
  if (name[1]) {
    letters = name[0][0] + name[1][0];
  } else {
    letters = name[0][0];
  }

  return letters;
}

export function stringToBlob(b64Data: string, fileName: string, contentType: string, sliceSize = 512): File {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new File(byteArrays, fileName, { type: contentType });
}

export const blobToString = (blob: Blob): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });

export const getUniqueKey = (url?: string | null): string | undefined => {
  if (!url) {
    console.error('URL is empty');
    return;
  }

  /*
   * Regex breakdown:
   * / - start of the regex,
   * \. - dot, [^/.] - any character except dot and slash,
   * + - one or more times,
   * $ - end of the string.
   * Removes dot (.) and characters after it, if no other dots (.) or slashes (/) follow.
   * Specifically targets file extensions at the string's end.
   */

  const path = new URL(url).pathname.replace(/\.[^/.]+$/, '');
  const key = path.substring(path.lastIndexOf('/') + 1);

  if (!key || key.trim() === '') {
    console.error('Key is empty');
    return;
  }

  return key;
};

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getExtension(nameFile: string): string | undefined {
  const re = /(?:\.([^.]+))?$/;

  const exec = re.exec(nameFile);

  if (exec !== null) return exec[1];

  return undefined;
}

export function getExtensionFromType(mimeType: string): string {
  const exec = mimeType.split('/');

  if (exec.length === 2) return exec[1];

  return '';
}

export const setVideoPlayer = async (): Promise<CapacitorVideoPlayerPlugin> => {
  return CapacitorVideoPlayer;
};

export const showToast = async (
  text: string,
  isSuccess: boolean,
  notAutoDismiss?: boolean,
  icon?: any | undefined
): Promise<void> => {
  const toast = await toastController.create({
    message: text,
    duration: notAutoDismiss ? 0 : 3000,
    position: 'top',
    icon: icon ? icon : isSuccess ? checkmarkCircleOutline : alertCircleOutline,
    cssClass: ['custom_toast', isSuccess ? 'success' : 'fail'],
  });
  await toast.present();
};

export const showDismissingToast = async (
  text: string,
  btnText: string,
  isSuccess: boolean,
  cancelText?: string // Text to close the notification. If not passed - then there is no close button.
): Promise<HTMLIonToastElement> => {
  const toast = await toastController.create({
    message: text,
    position: 'top',
    cssClass: ['custom_toast', isSuccess ? 'success' : 'fail'],
    /* icon: isSuccess ? checkmarkCircleOutline : alertCircleOutline, */
    buttons: cancelText
      ? [
          {
            text: cancelText,
            role: 'cancel',
          },
          {
            text: btnText,
            role: 'retry',
          },
        ]
      : [
          {
            text: btnText,
            role: 'retry',
          },
        ],
    layout: 'stacked',
  });
  await toast.present();

  return toast;
};

export const keyUpSend = (event: KeyboardEvent | MouseEvent): boolean => {
  const appStore = useAppStore();

  if (isNativeMobile) {
    return false;
  }

  const sendKey = appStore.getLocalSendKey;
  if (sendKey === SendKeyEnum.Enter && (event.ctrlKey || event.shiftKey)) {
    return false;
  } else {
    if ((sendKey === SendKeyEnum.CtrlEnter && event.ctrlKey) || sendKey === SendKeyEnum.Enter) {
      event.preventDefault();
      return true;
    } else {
      return false;
    }
  }
};

export const feedTypeHelper = async (
  route: undefined | null | RouteRecordName,
  updateOnlyType?: boolean
): Promise<void> => {
  const appStore = useAppStore();
  const postStore = usePostStore();
  const userStore = useUserStore();

  if (!appStore.isAuth) {
    return;
  }

  const currentUserId: ComputedRef<number> = computed(() => userStore.current?.id ?? 0);
  const feedType: ComputedRef<FeedTypeEnum> = computed(() => appStore.feedType);
  const feedFilter: ComputedRef<PostsFilterEnum> = computed(() => appStore.feedFilter);

  if (route === ROUTES_NAME.FEED) {
    if (!updateOnlyType) {
      await postStore.announcementsWithoutRead();
    }

    switch (feedType.value) {
      case FeedTypeEnum.Recommended:
        await postStore.postsRecommended();
        break;
      case FeedTypeEnum.AllPublic:
        await postStore.postsAll(feedFilter.value);
        break;
      case FeedTypeEnum.Announcement:
        await postStore.announcementsWithoutRead();
        break;
      case FeedTypeEnum.Following:
        await postStore.postsFollowing(feedFilter.value);
        break;
      case FeedTypeEnum.MyBookmarks:
        await postStore.postsBookmarks();
        break;
      case FeedTypeEnum.AllMy:
        await postStore.postsFromUserId(currentUserId.value);
        break;
      case FeedTypeEnum.Planned:
        await postStore.postsPlannedAll();
        break;

      default:
        await postStore.postsAll();
        break;
    }
  }
};

export const ideaTypeHelper = async (
  route: undefined | null | RouteRecordName
  // updateOnlyType?: boolean
): Promise<void> => {
  const appStore = useAppStore();
  const postStore = usePostStore();
  // const userStore = useUserStore();

  if (!appStore.isAuth) {
    return;
  }

  // const currentUserId: ComputedRef<number> = computed(
  //   () => userStore.current?.id ?? 0
  // );
  const ideaType: ComputedRef<IdeaTypeEnum> = computed(() => appStore.ideaType);

  if (route === ROUTES_NAME.IDEAS) {
    // if (!updateOnlyType) {
    //   await postStore.announcementsWithoutRead();
    // }

    switch (ideaType.value) {
      case IdeaTypeEnum.New:
        await postStore.postsNewIdeas();
        break;
      case IdeaTypeEnum.InProgress:
        await postStore.postsInProgressIdeas();
        break;
      case IdeaTypeEnum.Realized:
        await postStore.postsRealizedIdeas();
        break;
      case IdeaTypeEnum.Archived:
        await postStore.postsArchivedIdeas();
        break;
      case IdeaTypeEnum.MostLiked:
        await postStore.postsMostLikedIdeas();
        break;
      case IdeaTypeEnum.My:
        await postStore.postsMyIdeas();
        break;

      default:
        await postStore.postsNewIdeas();
        break;
    }
  }
};

export const invitationStatusHelper = (status: GroupInvitationStatusEnum): string => {
  const { t } = useI18n();
  switch (status) {
    case GroupInvitationStatusEnum.Join:
      return t('groupPage.manageModal.invitationStatus.successJoined');
    case GroupInvitationStatusEnum.JoinedInGroup:
      return t('groupPage.manageModal.invitationStatus.alreadyJoined');
    case GroupInvitationStatusEnum.NotAllowed:
      return t('groupPage.manageModal.invitationStatus.notAllowed');
    case GroupInvitationStatusEnum.ReceiveInvitation:
      return t('groupPage.manageModal.invitationStatus.invitationReceived');
    case GroupInvitationStatusEnum.SendError:
      return t('groupPage.manageModal.invitationStatus.invitationError');
    case GroupInvitationStatusEnum.SendInvite:
      return t('groupPage.manageModal.invitationStatus.invitationSent');
  }
  return '';
};

export const htmlToText = (html: string | null | undefined): string => {
  if (!html) return '';

  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = html;

  const links = tempDivElement.getElementsByTagName('a');
  for (const element of links) {
    if (element.href.startsWith('platform://')) {
      element.innerHTML = element.innerHTML.replace(/ /g, '_');
    }

    if (element.href.startsWith('platform://users') || element.href.startsWith('platform://groups')) {
      element.innerHTML = element.innerHTML.toLowerCase();
    } else if (!element.href.startsWith('platform://tags')) {
      element.innerHTML = element.href;
    }
  }

  return tempDivElement.textContent || tempDivElement.innerText || '';
};

export const convertHexToRgb = (str: string): string => {
  if (str !== null) {
    if (/^#([0-9a-f]{3}|[0-9a-f]{6})$/gi.test(str)) {
      let hex = str.slice(1);
      hex = hex.length == 3 ? hex.replace(/(.)/g, '$1$1') : hex;
      const rgb = parseInt(hex, 16);
      return [(rgb >> 16) & 255, (rgb >> 8) & 255, rgb & 255].join(',');
    }
  }
  return '0, 77, 96';
};

export const convertObjectToRGB = (obj: TopicColorModel | null): string | null => {
  // Проверяем, что все ключи существуют и содержат числовые значения
  if (obj && typeof obj.red === 'number' && typeof obj.green === 'number' && typeof obj.blue === 'number') {
    // Проверяем, что числа находятся в допустимом диапазоне (0-255)
    if (
      !isNaN(obj.red) &&
      !isNaN(obj.green) &&
      !isNaN(obj.blue) &&
      obj.red >= 0 &&
      obj.red <= 255 &&
      obj.green >= 0 &&
      obj.green <= 255 &&
      obj.blue >= 0 &&
      obj.blue <= 255
    ) {
      // Форматируем строки RGB
      return `rgb(${obj.red}, ${obj.green}, ${obj.blue})`;
    }
  }
  // Если объект не соответствует
  return null;
};

export const convertRGBToObject = (rgbString: string): TopicColorModel | null => {
  const regex = /(\d+), (\d+), (\d+)/;
  const matches = rgbString.match(regex);

  if (matches) {
    const [, red, green, blue] = matches;
    const rgbObject = {
      red: parseInt(red, 10),
      green: parseInt(green, 10),
      blue: parseInt(blue, 10),
    };
    return rgbObject;
  } else {
    // Если строка RGB не соответствует ожидаемому формату
    return null;
  }
};

export const clearStorage = async (): Promise<void> => {
  // Closing the menu if it exists
  await menuController?.close();

  // Resetting all stores except: appStore, userStore, networkStore
  resetAllStores(false);

  // If mobile platform - clear preferences storage
  if (isNativeMobile) {
    await Preferences.clear();
  }

  // Passing true as "noWS" in order to not initialize new WebSocket connection
  await useUserFlow().setupApp(true);
};

export const updateApp = async (): Promise<void> => {
  let checkUpdate = true;

  const ret = await Preferences.get({ key: 'updateApp' });
  if (ret.value !== null) {
    try {
      const lastUpdateApp = new Date(ret.value);
      const now = new Date();
      now.setTime(now.getTime() - 8 * 60 * 60 * 1000);
      checkUpdate = lastUpdateApp < now;
    } catch (e) {
      console.log(e);
    }
  }

  if (checkUpdate) {
    try {
      const result = await AppUpdate.getAppUpdateInfo();
      if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
        const { t } = useI18n();
        const alert = await alertController.create({
          message: t('updateIsAvailable', {
            app: import.meta.env.VITE_APP_NAME,
          }),
          buttons: [
            {
              text: t('cancel'),
              role: 'cancel',
            },
            {
              text: t('confirm'),
              role: 'confirm',
            },
          ],
          id: AppAlertTypeEnum.UpdateApp,
        });
        await alert.present();

        alert.onDidDismiss().then(async (event: OverlayEventDetail) => {
          if (event.role !== undefined) {
            await Preferences.set({
              key: 'updateApp',
              value: new Date().toISOString(),
            });
          }
          if (event.role === 'confirm') {
            await AppUpdate.openAppStore();
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
};

export const isValidURL = (str: string): boolean => {
  const pattern =
    '^(https?:\\/\\/)?' + // protocol
    '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
    '[a-zA-Z]{2,13})' + // extension
    '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
    '|localhost)' + // OR localhost
    '(\\:\\d{1,5})?' + // port
    '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
    '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
    '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
  const regex = new RegExp(pattern);
  return regex.test(str);
};

export const getGroupsWhereUserIsAdmin = (groups: GroupEntity[]): GroupEntity[] => {
  const newArr = [] as GroupEntity[];
  groups.forEach((element) => {
    if (element.accessType === GroupsAccessEnum.Admin) {
      newArr.push(element);
    }
  });
  return newArr;
};

export const getAnnouncementAccess = (groups: GroupEntity[], route: RouteLocationNormalizedLoaded): boolean => {
  const userStore = useUserStore();
  const currentUserRoleId = userStore.current?.roleId ?? 0;
  if (route.name === ROUTES_NAME.GROUP_BY_ID) {
    const { id } = route.params;
    return groups.some(
      (n) =>
        (n.accessType === GroupsAccessEnum.Admin && n.id === Number(id)) ||
        currentUserRoleId >= UserRoleEnum.Administrator
    );
  } else {
    return groups.some(
      (n) => n.accessType === GroupsAccessEnum.Admin || currentUserRoleId >= UserRoleEnum.Administrator
    );
  }
};

export const openPostCreateMenu = async (
  categories: { value: string; active: boolean; icon: string; title: string }[] | undefined = undefined
): Promise<void> => {
  const result =
    categories?.length === 1
      ? { data: categories[0].value }
      : isAnyMobile && categories
        ? await postCreateContextMenuSheet(categories)
        : await postCreateContextMenuModal();
  switch (result.data) {
    case PostTypeActionEnum.Text:
      await componentPostCreateMobile(PostTypeActionEnum.Text);
      break;
    case PostTypeActionEnum.Event:
      await componentPostCreateMobile(PostTypeActionEnum.Event);
      break;
    case PostTypeActionEnum.Poll:
      await componentPostCreateMobile(PostTypeActionEnum.Poll);
      break;
    case PostTypeActionEnum.Idea:
      await componentPostCreateMobile(PostTypeActionEnum.Idea);
      break;
    case PostTypeActionEnum.Badge:
      await componentPostCreateMobile(PostTypeActionEnum.Badge);
      break;
    case PostTypeActionEnum.Announcement:
      await componentPostCreateMobile(PostTypeActionEnum.Announcement);
      break;
    case PostTypeActionEnum.Task:
      await componentPostCreateMobile(PostTypeActionEnum.Task);
      break;
  }
};

export const canCreateIdeas = (): boolean => {
  const networkStore = useNetworkStore();
  const userStore = useUserStore();
  const externalCanCreateIdeas: ComputedRef<boolean> = computed(
    () => networkStore.settings?.externalCanCreateIdeas ?? false
  );
  const currentUserRoleId: ComputedRef<UserRoleEnum> = computed(() => userStore.current?.roleId ?? 0);

  return (
    (networkStore.settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Idea) &&
      !(currentUserRoleId.value <= UserRoleEnum.ExternalGroupUser && !externalCanCreateIdeas.value)) ??
    false
  );
};

export const getPostCreateCategories = (
  announcementAccess: boolean,
  route: RouteLocationNormalizedLoaded
): TabCategories[] => {
  const networkStore = useNetworkStore();
  const taskManagementHelper = useTaskManagementHelper();

  const icons = {
    chatbox: chatboxOutline,
    calendar: calendarOutline,
    podium: podiumOutline,
    color: colorWandOutline,
    megaphone: megaphoneOutline,
    ribbon: ribbonOutline,
    layers: layersOutline,
  };
  const { t } = useI18n();
  const categories = [
    {
      value: PostTypeActionEnum.Text,
      active: networkStore.settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Text) ?? false,
      icon: icons.chatbox,
      title: t('conversations.post.title'),
    },
    {
      value: PostTypeActionEnum.Announcement,
      active:
        (announcementAccess &&
          networkStore.settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Announcement)) ??
        false,
      icon: icons.megaphone,
      title: t('conversations.announcement.title'),
    },
    {
      value: PostTypeActionEnum.Event,
      active: networkStore.settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Event) ?? false,
      icon: icons.calendar,
      title: t('conversations.event.title'),
    },
    {
      value: PostTypeActionEnum.Poll,
      active: networkStore.settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Poll) ?? false,
      icon: icons.podium,
      title: t('conversations.poll.poll'),
    },
    {
      value: PostTypeActionEnum.Idea,
      active: canCreateIdeas(),
      icon: icons.color,
      title: t('conversations.idea.title'),
    },
    {
      value: PostTypeActionEnum.Badge,
      active: networkStore.settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Badge) ?? false,
      icon: icons.ribbon,
      title: t('conversations.badge.title'),
    },
    {
      value: PostTypeActionEnum.Task,
      active: taskManagementHelper.getAccessToCreateTask(),
      icon: icons.layers,
      title: t('taskManagement.tasks.title'),
    },
  ];

  if (route.name === ROUTES_NAME.IDEAS) {
    return [
      {
        value: PostTypeActionEnum.Idea,
        active: canCreateIdeas(),
        icon: icons.color,
        title: t('conversations.idea.title'),
      },
    ];
  } else {
    return categories;
  }
};

export const docBrowserHelper = async (): Promise<void> => {
  const docStore = useDocStore();
  const appStore = useAppStore();
  const group = docStore.activeGroup;
  const folder = docStore.activeFolder;

  if (!appStore.isAuth) {
    return;
  }

  if (folder?.id) {
    await docStore.allDocsFromFolderId(folder.id, DocBrowserModeEnum.All);
    return;
  }

  if (group?.id) {
    await docStore.allDocsFromGroupId(group.id);
    return;
  } else {
    await docStore.allDocs();
  }
};

export const shareFileHelper = async (fileId: number): Promise<void> => {
  const docStore = useDocStore();
  const { t } = useI18n();
  const result = await componentDocsShareFile();
  if (result.data !== undefined) {
    if (result.data.groupId !== undefined) {
      if (await docStore.shareFileToGroup(fileId, result.data.text, result.data.groupId)) {
        showToast(t('files.successShared'), true);
      } else {
        showToast(t('files.failedShared'), false);
      }
    } else {
      if (await docStore.shareFile(fileId, result.data.text)) {
        showToast(t('files.successShared'), true);
      } else {
        showToast(t('files.failedShared'), false);
      }
    }
  }
};

export const createPostAccessHelper = (
  page: PageToPostCreateEnum,
  isFab: boolean,
  isLoading: boolean,
  isGroupAdmin?: boolean,
  groupData?: GroupEntity
): boolean => {
  const groupStore = useGroupsStore();
  const userStore = useUserStore();
  const networkStore = useNetworkStore();

  const currentUserRoleId: ComputedRef<UserRoleEnum> = computed(() => userStore.current?.roleId ?? 0);
  const canPostGroups: ComputedRef<GroupEntity[]> = computed(() => {
    return groupStore.getCanPostGroups().data;
  });
  const networkAllowPostToFeed: ComputedRef<boolean> = computed(() => networkStore.settings?.allowPostToFeed ?? false);
  const defaultUserItemTypes = networkStore.settings?.defaultUserItemTypes !== undefined;
  // const appBottomMenuIsShow: ComputedRef<boolean> = computed(
  //   () => appStore.appBottomMenu
  // );

  // If the role is 12 or less - you can not create any posts
  if (currentUserRoleId.value <= UserRoleEnum.ExternalGroupUserLikeUpsert) return false;

  const emptyAndOff = !canPostGroups.value.length && !networkAllowPostToFeed.value;
  const emptyAndOn = !canPostGroups.value.length && networkAllowPostToFeed.value;

  // Display according the network settings + canPostGroups
  switch (true) {
    case !defaultUserItemTypes:
      return false;
    case emptyAndOff:
      return false;
    case emptyAndOn:
      return !isFab ? true : !isLoading;
  }

  // Display according page
  switch (page) {
    case PageToPostCreateEnum.Feed: {
      return !isFab ? true : !isLoading;
    }

    case PageToPostCreateEnum.Group: {
      const groupAccessToCreate = () => {
        if (!groupData) {
          return false;
        }
        if (groupData?.isOfficial && isGroupAdmin) {
          return true;
        } else if (!groupData?.isOfficial && canPostGroups.value.includes(groupData)) {
          return true;
        }
        return false;
      };

      if (!groupAccessToCreate()) {
        return false;
      } else {
        return !isFab ? true : !isLoading;
      }
    }

    case PageToPostCreateEnum.Ideas: {
      return !isFab ? true : !isLoading;
    }

    case PageToPostCreateEnum.Tag:
      return !isLoading;

    default:
      return false;
  }
};

export const getDocsFileMenuItems = (file: DocEntity): DocsMenuItemModel[] => {
  /* const isOpened = (): boolean => {
  switch (props.file.documentType) {
    case DocumentTypeEnum.Folder:
      return false;

    case DocumentTypeEnum.Wiki:
      return true;

    case DocumentTypeEnum.File: {
      const file = props.file.data as FileModel;
      if (
        (file.access.includes(ActionAccessEnum.Preview) && isNativeMobile) ||
        file.mimeType.startsWith('video') ||
        file.mimeType.startsWith('image') ||
        (file.type === 'pdf' && !isNativeMobile)
      ) {
        return true;
      }
      break;
    }
  }
  return false;
}; */

  const icons = {
    eye: eyeOutline,
    download: downloadOutline,
    move: moveOutline,
    text: textOutline,
    trash: trashOutline,
    share: shareSocialOutline,
    upload: cloudUploadOutline,
    browsers: browsersOutline,
    archive: archiveOutline,
  };

  const { t } = useI18n();

  // Store
  const docStore = useDocStore();
  const userStore = useUserStore();
  const groupStore = useGroupsStore();

  // Variables
  const activeGroup: ComputedRef<GroupEntity | null> = computed(() => docStore.activeGroup ?? null);

  const groupData: ComputedRef<GroupEntity> = computed(() => groupStore.getGroupById(Number(activeGroup.value)));

  const currentUserId: ComputedRef<number> = computed(() => userStore.current?.id ?? 0);

  const currentUserRoleId: ComputedRef<number> = computed(() => userStore.current?.roleId ?? 0);

  const isGroupAdmin: ComputedRef<boolean> = computed(
    () =>
      groupData.value.adminIds.includes(currentUserId.value) ||
      currentUserRoleId.value >= UserRoleEnum.SuperAdministrator
  );

  const canShare = computed(() => {
    if (activeGroup.value && groupData?.value.type === GroupsTypeEnum.PrivateHidden) {
      return isGroupAdmin.value;
    }
    return true;
  });

  const isFile: boolean = file.documentType === DocumentTypeEnum.File;
  const isFolder: boolean = file.documentType === DocumentTypeEnum.Folder;
  const isWiki: boolean = file.documentType === DocumentTypeEnum.Wiki;

  const menuItems = [
    {
      title: t('files.menu.download'),
      icon: icons.download,
      enabled: isFile,
      value: FileMenuActionEnum.Download,
    },

    {
      title: t('files.menu.move'),
      icon: icons.move,
      enabled: file.data.access.includes(ActionAccessEnum.Move),
      value: FileMenuActionEnum.Move,
    },

    {
      title: t('files.menu.rename'),
      icon: icons.text,
      enabled: file.documentType !== DocumentTypeEnum.Wiki && file.data.access.includes(ActionAccessEnum.Rename),
      value: FileMenuActionEnum.Rename,
    },

    {
      title: t('appPopoverMenu.delete.title'),
      icon: icons.trash,
      enabled: file.data.access.includes(ActionAccessEnum.Delete),
      value: FileMenuActionEnum.Delete,
    },

    {
      title: t('files.menu.share'),
      icon: icons.share,
      enabled: isFile && file.data.access.includes(ActionAccessEnum.Share) && canShare,
      value: FileMenuActionEnum.Share,
    },

    {
      title: t('conversations.conversationPostMenu.send.shareArchiveLink'),
      icon: icons.archive,
      enabled:
        (isFile || isFolder || isWiki) &&
        file.data.access.includes(ActionAccessEnum.Share) &&
        canShare &&
        canSendArchivedLink(),
      value: FileMenuActionEnum.ShareArchiveLink,
    },

    {
      title: t('files.menu.newVersion'),
      icon: icons.upload,
      enabled: isFile && file.data.access.includes(ActionAccessEnum.NewVersion),
      value: FileMenuActionEnum.NewVersion,
    },
  ] as DocsMenuItemModel[];

  return filter(menuItems, (n) => n.enabled);
};

export const getPostContextMenuItems = (
  postData: PostEntity,
  conversationsFlag: ConversationsFlagEnum,
  conversationsType?: string | ConversationsTypeEnum,
  shareFlag = false
): PostMenuItemModel[] => {
  const icons = {
    trash: trashOutline,
    alert: alertCircleOutline,
    pencil: pencilOutline,
    bookmark: bookmarkOutline,
    close: closeOutline,
    mail: mailOutline,
    mailUnread: mailUnreadOutline,
    star: starOutline,
    share: shareSocialOutline,
    notifications: notificationsOutline,
    notificationsOff: notificationsOffOutline,
    copy: copyOutline,
    open: openOutline,
    eye: eyeOutline,
    arrowUp: arrowUpCircleOutline,
    timer: timerOutline,
    archive: archiveOutline,
  };
  const { t } = useI18n();

  const mainItems = [
    {
      title: t('conversations.conversationPostMenu.sendNow'),
      icon: icons.arrowUp,
      value: PostMenuActionEnum.SendNow,
      active: !!postData.plannedPostData,
    },
    {
      title: t('conversations.conversationPostMenu.reSchedule'),
      icon: icons.timer,
      value: PostMenuActionEnum.ReSchedule,
      active: !!postData.plannedPostData,
    },
    {
      title: t('conversations.conversationPostMenu.viewPost'),
      icon: icons.open,
      value: PostMenuActionEnum.Open,
      active: conversationsFlag !== ConversationsFlagEnum.ConversationPage,
    },
    {
      title: t('appPopoverMenu.edit.title'),
      icon: icons.pencil,
      value: PostMenuActionEnum.Edit,
      active: postData.access.includes(ActionAccessEnum.Edit) && postData.messageType !== ConversationsTypeEnum.Task,
    },
    {
      title: t('conversations.conversationPostMenu.bookmark.addToBookmarks'),
      icon: icons.bookmark,
      value: PostMenuActionEnum.AddToBookmarks,
      active: !postData.isBookmarked,
    },
    {
      title: t('conversations.conversationPostMenu.bookmark.removeFromBookmarks'),
      icon: icons.close,
      value: PostMenuActionEnum.RemoveFromBookmarks,
      active: postData.isBookmarked,
    },
    {
      title: t('conversations.conversationPostMenu.attach.pin'),
      icon: icons.star,
      value: PostMenuActionEnum.Pin,
      active:
        (postData.isPinned === false || postData.isPinned === null) && postData.access.includes(ActionAccessEnum.Pin),
    },
    {
      title: t('conversations.conversationPostMenu.attach.unpin'),
      icon: icons.close,
      value: PostMenuActionEnum.UnPin,
      active: postData.isPinned && postData.access.includes(ActionAccessEnum.Pin),
    },
    {
      title: t('conversations.conversationPostMenu.following.followToPost'),
      icon: icons.notifications,
      value: PostMenuActionEnum.Follow,
      active: !postData.isFollowed,
    },
    {
      title: t('conversations.conversationPostMenu.following.unfollowFromPost'),
      icon: icons.notificationsOff,
      value: PostMenuActionEnum.UnFollow,
      active: postData.isFollowed,
    },
    {
      title: t('conversations.conversationPostMenu.violation.complain'),
      icon: icons.alert,
      value: PostMenuActionEnum.Complain,
      active: postData.isComplainable,
    },
    {
      title: t('appPopoverMenu.copy.title'),
      icon: icons.copy,
      value: PostMenuActionEnum.CopyText,
      active: postData.messageType !== ConversationsTypeEnum.Task,
    },
    {
      title: t('read.list'),
      icon: icons.eye,
      active: postData.access.includes(ActionAccessEnum.ObserveViewers),
      value: PostMenuActionEnum.ShowViewers,
    },
    {
      title: t('conversations.conversationPostMenu.send.title'),
      icon: icons.share,
      active: postData.access.includes(ActionAccessEnum.Share),
      value: PostMenuActionEnum.Share,
    },
    {
      title: t('files.menu.downloadAsPDF'),
      icon: icons.share,
      active: getAccesToDownloadPost(postData.messageType),
      value: PostMenuActionEnum.DownloadAsPDF,
    },
    {
      title: t('files.menu.downloadAsZIP'),
      icon: icons.share,
      active: getAccesToDownloadPost(postData.messageType),
      value: PostMenuActionEnum.DownloadAsZIP,
    },
    {
      title: t('appPopoverMenu.delete.title'),
      icon: icons.trash,
      active: postData.access.includes(ActionAccessEnum.Delete),
      handler: async () => {
        const alert = await alertController.create({
          message: t('conversations.conversationPostMenu.delete.deleteConfirm'),
          buttons: [
            {
              text: t('no'),
              role: 'cancel',
              cssClass: 'custom-alert_buttons',
            },
            {
              text: t('yes'),
              cssClass: 'custom-alert_buttons',
              handler: async () => {
                await actionSheetController.dismiss(2);
              },
            },
          ],
        });

        await alert.present();
        return false;
      },
    },
  ];
  const shareItems = [
    {
      title: t('conversations.conversationPostMenu.send.sendToMyEmail'),
      icon: icons.mail,
      value: PostMenuActionEnum.SendToEmail,
      active: true,
    },
    {
      title: t('conversations.conversationPostMenu.send.sendToExternalEMail'),
      icon: icons.mailUnread,
      value: PostMenuActionEnum.SendToExternalEmail,
      active: true,
    },
    {
      title: t('conversations.conversationPostMenu.send.shareArchiveLink'),
      icon: icons.archive,
      value: PostMenuActionEnum.ShareArchiveLink,
      active: canSendArchivedLink(),
    },
  ];
  return shareFlag ? filter(shareItems, (n) => n.active) : filter(mainItems, (n) => n.active);
};

export const getPostsFilterTitle = (label: PostsFilterEnum): string => {
  const { t } = useI18n();
  switch (label) {
    case PostsFilterEnum.All:
      return t('read.all');
    case PostsFilterEnum.Read:
      return t('read.reads');
    case PostsFilterEnum.Unread:
      return t('read.unreads');
    default:
      return t('read.all');
  }
};

export const getFeedFilterContextMenuItems = (): {
  title: string;
  value: FeedTypeEnum | null;
  subTitle: string;
  action: FeedContextMenuActionEnum;
  enabled: boolean;
}[] => {
  const { t } = useI18n();
  const appStore = useAppStore();
  const selectedFeedFilter: ComputedRef<PostsFilterEnum> = computed(() => appStore.feedFilter);
  const selectedFeedType: ComputedRef<FeedTypeEnum> = computed(() => appStore.feedType);
  const filterSelectIsShow: ComputedRef<boolean> = computed(
    () => selectedFeedType.value === FeedTypeEnum.AllPublic || selectedFeedType.value === FeedTypeEnum.Following
  );
  const mainItems = [
    {
      title: t('viewType'),
      value: null,
      subTitle: getPostsFilterTitle(selectedFeedFilter.value),
      action: FeedContextMenuActionEnum.SelectFilter,
      enabled: filterSelectIsShow.value,
    },
    {
      title: t('read.markAllAsRead'),
      value: null,
      subTitle: t('read.allReadDescription'),
      action: FeedContextMenuActionEnum.MarkAllAsRead,
      enabled: filterSelectIsShow.value,
    },
  ];
  return mainItems.filter((n) => n.enabled);
};

export const getFeedTypeContextMenuItems = (): {
  title: string;
  value: FeedTypeEnum | null;
  subTitle: string;
  action: FeedContextMenuActionEnum;
  enabled: boolean;
}[] => {
  const { t } = useI18n();
  const mainItems = [
    {
      title: t('feedPage.all'),
      value: FeedTypeEnum.AllPublic,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('feedPage.recommended'),
      value: FeedTypeEnum.Recommended,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('subscribe.subscriptions'),
      value: FeedTypeEnum.Following,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('feedPage.bookmarks'),
      value: FeedTypeEnum.MyBookmarks,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('feedPage.myPosts'),
      value: FeedTypeEnum.AllMy,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('feedPage.planned'),
      value: FeedTypeEnum.Planned,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    ...getFeedFilterContextMenuItems(),
  ];
  return mainItems.filter((n) => n.enabled);
};

export const getMessengerContextMenuItems = (
  ev: Event,
  message: MessageEntity,
  currentId: number | undefined,
  deleteFlag = false
): MessageMenuModel[] => {
  const { t } = useI18n();
  const icons = {
    arrowUndo: arrowUndoOutline,
    arrowRedo: arrowRedoOutline,
    copy: copyOutline,
    trash: trashOutline,
    pencil: pencilOutline,
  };
  const mainItems = [
    {
      title: t('appPopoverMenu.messengerMenu.reply'),
      value: MessageActionEnum.Reply,
      icon: icons.arrowUndo,
      active: true,
    },
    {
      title: t('appPopoverMenu.edit.title'),
      value: MessageActionEnum.Edit,
      icon: icons.pencil,
      active: message.editable && message.authorId === currentId,
    },
    {
      title: t('appPopoverMenu.copy.title'),
      value: MessageActionEnum.Copy,
      icon: icons.copy,
      active: !!message.bodyHtml,
    },
    {
      title: t('appPopoverMenu.messengerMenu.forward'),
      value: MessageActionEnum.Forward,
      icon: icons.arrowRedo,
      active: true,
    },
    {
      title: t('appPopoverMenu.messengerMenu.deleteForMe'),
      value: MessageActionEnum.DeleteForMe,
      icon: icons.trash,
      active: message.authorId !== currentId,
    },
    {
      title: t('appPopoverMenu.messengerMenu.deleteMessage'),
      value: MessageActionEnum.Delete,
      icon: icons.trash,
      active: message.authorId === currentId,
    },
  ];
  const deleteItems = [
    {
      title: t('appPopoverMenu.messengerMenu.deleteForMe'),
      value: MessageActionEnum.DeleteForMe,
      active: true,
    },
    {
      title: t('appPopoverMenu.messengerMenu.deleteForAll'),
      value: MessageActionEnum.DeleteForAll,
      active: message.authorId === currentId && message.editable,
    },
  ];
  return deleteFlag ? deleteItems.filter((item) => item.active) : mainItems.filter((item) => item.active);
};

export const getWikiActionsMenuItems = (wiki: WikiModel): WikiActionsMenuMenuModel[] => {
  const { t } = useI18n();
  const wikiStore = useWikiStore();
  const isOfficial: ComputedRef<boolean> = computed(() => wikiStore.isOfficial);
  const menuItems = [
    {
      title: t(`files.menu.downloadAsPDF`),
      value: WikiActionEnum.DownloadAsPDF,
      icon: cloudDownloadOutline,
      disabled: false,
    },
    {
      title: t(`files.menu.downloadAsDOCX`),
      value: WikiActionEnum.DownloadAsDOCX,
      icon: cloudDownloadOutline,
      disabled: false,
    },
    {
      title: t(`wiki.actions.edit`),
      value: WikiActionEnum.Edit,
      icon: pencilOutline,
      disabled: wiki?.version === 2,
    },
    {
      title: t(`wiki.actions.compare`),
      value: WikiActionEnum.Compare,
      icon: WikiCompareAsStringOnly,
      disabled: false,
    },
    {
      title: t(`wiki.actions.move`),
      value: WikiActionEnum.Move,
      icon: arrowUndoOutline,
      disabled: false,
    },
    {
      title: t(`wiki.actions.official`),
      value: WikiActionEnum.MakeOfficial,
      icon: isOfficial.value ? starSharp : starOutline,
      disabled: false,
    },
    {
      title: t(`wiki.actions.showRelations`),
      value: WikiActionEnum.ShowRelations,
      icon: linkOutline,
      disabled: false,
    },
    {
      title: t(`wiki.actions.showHistory`),
      value: WikiActionEnum.ShowHistory,
      icon: WikiHistoryAsStringOnly,
      disabled: false,
    },
    {
      title: t(`wiki.actions.delete`),
      value: WikiActionEnum.Delete,
      icon: trashOutline,
      disabled: false,
    },
  ];
  return menuItems.filter(({ disabled }) => !disabled);
};

export const openWikiActionsMenu = async (wiki: WikiModel): Promise<void> => {
  if (isAnyMobile) {
    const result = await wikiActionsMenuSheet(wiki);
    if (result.data !== undefined) {
      await useWikiActionsHelper().whichActionToMake(result.data, wiki);
    }
  } else {
    await wikiActionsMenu(wiki);
  }
};

export const getCommentContextMenuItems = (
  authorId: number,
  currentUserId: number,
  isEditable: boolean
): CommentMenuModel[] => {
  const { t } = useI18n();
  const menuItems = [
    {
      title: t('appPopoverMenu.copy.title'),
      value: CommentActionEnum.CopyText,
      icon: copyOutline,
      disabled: false,
    },
    {
      title: t('appPopoverMenu.commentMenu.deleteComment'),
      icon: trashOutline,
      disabled: !isEditable,
      handler: async () => {
        const alert = await alertController.create({
          message: t('appPopoverMenu.commentMenu.deleteConfirm'),
          buttons: [
            {
              text: t('no'),
              role: 'cancel',
              cssClass: 'custom-alert_buttons',
            },
            {
              text: t('yes'),
              cssClass: 'custom-alert_buttons',
              handler: async () => {
                await actionSheetController.dismiss(2);
              },
            },
          ],
        });

        await alert.present();
        return false;
      },
    },
  ];
  return menuItems.filter(({ disabled }) => !disabled);
};

export const getPostUploadFileMenuItems = (): PostUploadFileMenuModel[] => {
  const { t } = useI18n();
  const officeHelper = useOfficeHelper();

  const menuItems = [
    {
      title: t('files.menu.uploadFromDevice'),
      value: PostUploadFileEnum.UploadFromDevice,
      icon: cloudUploadOutline,
      disabled: false,
    },
    {
      title: t('files.menu.selectFromNetwork'),
      value: PostUploadFileEnum.UploadFromGroup,
      icon: folderOpenOutline,
      disabled: false,
    },
    {
      title: t('files.createDocument'),
      value: PostUploadFileEnum.CreateDocument,
      icon: documentOutline,
      disabled: !officeHelper.isUserGrantedForActions.value,
    },
    {
      title: t('createWiki'),
      value: PostUploadFileEnum.CreateWiki,
      icon: documentOutline,
      disabled: !officeHelper.isUserGrantedForActions.value,
    },
  ];
  return menuItems.filter(({ disabled }) => !disabled);
};

export const changeFavicon = (): void => {
  if (isNativeMobile) {
    return;
  }

  const networkStore = useNetworkStore();
  const icon = useFavicon();
  if (networkStore.network?.id.length > 0) {
    icon.value = useNetworksHelper().getNetworkLogoUrl(networkStore.network?.id);
  } else {
    icon.value = '';
  }
};

export const docBrowserMenuItems = (): {
  icon: string;
  disabled: boolean;
  value: DocsMenuActionEnum;
  title: string;
}[] => {
  const officeHelper = useOfficeHelper();
  const { t } = useI18n();

  const data = [
    {
      icon: DocIconAsStringOnly,
      disabled: !officeHelper.isUserGrantedForActions.value,
      value: DocsMenuActionEnum.CreateFile,
      title: t('files.createDocument'),
    },
    {
      icon: WikiIconAsStringOnly,
      disabled: !officeHelper.isUserGrantedForActions.value,
      value: DocsMenuActionEnum.CreateWiki,
      title: t('createWiki'),
    },
    {
      icon: FolderIconAsStringOnly,
      disabled: false,
      value: DocsMenuActionEnum.CreateFolder,
      title: t('files.createFolder.title'),
    },
    {
      icon: UploadIconAsStringOnly,
      disabled: false,
      value: DocsMenuActionEnum.UploadFile,
      title: t('files.uploadFile.title'),
    },
    {
      icon: UploadFolderIconAsStringOnly,
      disabled: true,
      value: DocsMenuActionEnum.UploadFolder,
      title: t('files.uploadFolder.title'),
    },
  ];

  return data.filter(({ disabled }) => !disabled);
};

export const docBrowserMenuActions = async (
  menuAction: DocsMenuActionEnum,
  router: Router,
  route: Route
): Promise<void> => {
  const docStore = useDocStore();

  const activeFolder: ComputedRef<FolderModel | null> = computed(() => docStore.activeFolder);

  const activeGroup: ComputedRef<GroupEntity | undefined> = computed(() => docStore.activeGroup ?? undefined);

  const routeToCreateWiki = async (
    groupId: number | undefined = undefined,
    folderId: number | undefined = undefined
  ) => {
    await router.push({
      name: ROUTES_NAME.WIKI_EDIT,
      query: { groupId, folderId },
    });
  };

  const groupStore = useGroupsStore();
  const { id } = route.params;

  const group =
    route.name === ROUTES_NAME.DOCS
      ? activeGroup.value
      : route.name === ROUTES_NAME.GROUP_BY_ID
        ? groupStore.getGroupById(Number(id))
        : (undefined as GroupEntity | undefined);

  switch (menuAction) {
    case DocsMenuActionEnum.UploadFile:
      // метод по загрузке файла
      await componentDocsUploadFile(UploadFileTypes.ManyDifferentFiles, undefined, undefined, undefined, group);
      break;
    case DocsMenuActionEnum.UploadFolder:
      // метод по загрузке папки - не рабочий
      /* await componentDocsUploadFolder(); */
      break;
    case DocsMenuActionEnum.CreateFile:
      // метод по созданию файла
      // метод по созданию документа
      await componentDocsCreateFile(true, group?.id || undefined, activeFolder.value?.id || undefined);

      break;
    case DocsMenuActionEnum.CreateFolder:
      // метод по созданию папки
      await componentDocsCreateFolder(group);
      break;
    case DocsMenuActionEnum.CreateWiki:
      // Routing to new wiki
      await routeToCreateWiki(group?.id, activeFolder.value?.id);
      break;
  }
};

export const docBrowserOpenMenu = async (router: Router, route: Route): Promise<void> => {
  const result = isAnyMobile ? await docBrowserMainMenuSheet() : await docBrowserMainMenu();

  if (result.data) {
    await docBrowserMenuActions(result.data, router, route);
  }
};

export const getPhoneCodeByCountryName = (name: CountriesEnum): string | null => {
  switch (name) {
    case CountriesEnum.Armenia:
      return '+374';
    case CountriesEnum.Austria:
      return '+43';
    case CountriesEnum.Belarus:
      return '+375';
    case CountriesEnum.Belgium:
      return '+32';
    case CountriesEnum.Canada:
      return '+1';
    case CountriesEnum.Czech:
      return '+420';
    case CountriesEnum.Uk:
      return '+44';
    case CountriesEnum.Germany:
      return '+49';
    case CountriesEnum.Kazakhstan:
    case CountriesEnum.Russia:
      return '+7';
    case CountriesEnum.Usa:
      return '+1';
    default:
      return null;
  }
};

export const getPhoneMaskByCountryCode = (code: string, length = 0): string | null => {
  switch (code) {
    case '+1':
      return '(###) ###-####';
    case '+7':
      return '(###) ###-##-##';
    case '+374':
      return '## ### ###';
    case '+43':
      return '##########';
    case '+375':
      return '## ###-##-##';
    case '+32':
      return '## ### ###';
    case '+420':
      return '### ### ###';
    case '+44':
      return '#### ### ####';
    case '+49': {
      switch (length) {
        case 13:
          return '### #### ####';
        case 12:
          return '#### #######';
        case 11:
          return '## ### ####';

        default:
          return '### #### ####';
      }
    }
    default:
      return null;
  }
};

export const formatDateHelper = (
  str: string | number,
  style: 'short' | 'day' | 'month' | 'year' | 'yearNumeric' | 'long' | 'week' | 'monthName'
): string => {
  if (!str) return '';

  const appStore = useAppStore();
  const language: ComputedRef<string> = computed(() => appStore.locale);

  const options = { locales: language.value };

  const formatDate = (format: string): string => {
    return useDateFormat(str, format, options).value;
  };

  switch (style) {
    case 'day':
      return formatDate('HH:mm');
    case 'month':
      return formatDate('DD MMM');
    case 'year':
      return formatDate('DD MMM YYYY');
    case 'yearNumeric':
      return formatDate('DD.MM.YYYY');
    case 'long':
      return formatDate('ddd, DD MMM YYYY, HH:mm');
    case 'short':
      return formatDate('DD.MM.YYYY HH:mm');
    case 'week':
      return formatDate('ddd');
    case 'monthName':
      return formatDate('MMMM');
    default:
      return '';
  }
};

export const autoNetworkUri = (): string | null => {
  if (isNativeMobile) {
    switch (import.meta.env.VITE_APP_NAME) {
      case 'Communex App':
        return null;
      case 'BVZ App':
        return 'bvz.intrakommuna.net';
      case 'AWO App':
        return 'awo.intrakommuna.net';
      default:
        return null;
    }
  }

  return null;
};

//TODO: https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1006
export const checkAutoLoginMode = async (): Promise<any> => {
  const { handleError } = useErrors();
  // console.log('≥≥≥Checking auto-login mode...'); //! DEBUG

  // console.log('≥≥≥Checking window.location.origin...'); //! DEBUG
  if (window.location.origin !== import.meta.env.VITE_KLINIKUM_ERDING_URL) {
    // console.log('≥≥≥Location:', window.location); //! DEBUG
    return;
  }

  let currentIP = '';

  try {
    // console.log('≥≥≥Retrieving IP address...'); //! DEBUG
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    currentIP = data.ip;
    // currentIP = 'testIP'; //! DEBUG
  } catch (error) {
    handleError(true, error, 'Error while fetching the IP address');
    return;
  }

  // Setting the default auto-login data
  const autoLogin = {
    autoLogin: false,
    user: {
      clientSecret: '',
      login: '',
      password: '',
      uri: '',
      fullURL: '',
      isEnterprise: false,
    },
  } as AutoLoginModel;

  // console.log('≥≥≥Checking current IP address...'); //! DEBUG
  if (currentIP && currentIP === import.meta.env.VITE_KLINIKUM_ERDING_IP) {
    // console.log('≥≥≥Generating auto-login data...'); //! DEBUG

    autoLogin.autoLogin = true;
    autoLogin.user = {
      clientSecret: import.meta.env.VITE_KLINIKUM_ERDING_CLIENT_SECRET,
      login: import.meta.env.VITE_KLINIKUM_ERDING_LOGIN,
      password: import.meta.env.VITE_KLINIKUM_ERDING_PASSWORD,
      uri: import.meta.env.VITE_KLINIKUM_ERDING_URI,
      fullURL: import.meta.env.VITE_KLINIKUM_ERDING_URL,
      isEnterprise: true,
    };

    // console.log('≥≥≥For DEV - setting the service URL...'); //! DEBUG
    setServiceUrl(import.meta.env.VITE_KLINIKUM_ERDING_URL_CORE);

    // console.log('≥≥≥For PROD - setting the service URL...'); //! DEBUG
    useAppStore().$patch({
      url: import.meta.env.VITE_KLINIKUM_ERDING_URL_CORE,
    });
  }
  return autoLogin;
};

export const performAutoLogin = async (): Promise<void> => {
  const { handleError } = useErrors();
  const result = await checkAutoLoginMode();
  if (result?.autoLogin) {
    // console.log('≥≥≥Performing auto-login...'); //! DEBUG

    const userNetworks = await useNetworkStore().networksByForm(result.user);

    if (!userNetworks) {
      console.error('Auto-login mode is not activated');
      handleError(true, undefined, 'Auto-login mode is not activated');
      return;
    }

    const klinikum_erding = userNetworks.find(
      (network) => network.id === import.meta.env.VITE_KLINIKUM_ERDING_COMPANY_ID
    );
    await useUserFlow().changeNetwork(klinikum_erding, result.user);
  }
};

export const appActionButtonAction = async (
  id: number | string,
  action: AppCardsActionEnum | WikiActionEnum //?
): Promise<void> => {
  const groupsHelper = useGroupsHelper();
  const usersHelper = useUsersHelper();
  switch (action) {
    case AppCardsActionEnum.GroupJoin:
      await groupsHelper.onJoinGroup(Number(id));
      break;

    case AppCardsActionEnum.GroupLeave:
      await groupsHelper.onLeaveGroup(Number(id));
      break;

    case AppCardsActionEnum.GroupSendRequest:
      await groupsHelper.onSendRequest(Number(id));
      break;

    case AppCardsActionEnum.UserFollow:
      await usersHelper.onFollow(Number(id));
      break;

    case AppCardsActionEnum.UserUnfollow:
      await usersHelper.onUnfollow(Number(id));
      break;

    case AppCardsActionEnum.ChangeNetwork:
      {
        const network = useNetworkStore().getNetworkById(id.toString());
        if (network) {
          await useUserFlow().setNetwork(network, null);
        }
      }
      break;

    default:
      break;
  }
};

export const replaceLinksToTags = (text: string): string => {
  //NOTE: This code looks for http/https links in a line of text and replaces them with tags
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (match, url) {
    const domain = new URL(url).hostname;
    return '<a target="_blank" href="' + url + '">' + domain + '</a>';
  });
};

export const getImageKeyFromLink = (url: string): string => {
  const regex = /\/media\/public\/(.*?)(?:\.\w+)?$/;
  const match = url.match(regex);
  console.log(match);
  if (match) {
    console.log(match[1]);
    return match[1];
  } else {
    return url;
  }
};

export const canSendArchivedLink = (): boolean => {
  const userStore = useUserStore();
  const networkStore = useNetworkStore();

  const currentUserRoleId: UserRoleEnum = userStore.current?.roleId ?? 0;

  const sendArchivedPost = networkStore.settings?.sendArchivedPost ?? UserAdminAccessLevel.None;

  switch (sendArchivedPost) {
    case UserAdminAccessLevel.None:
      return false;

    case UserAdminAccessLevel.All:
      return true;

    case UserAdminAccessLevel.Administrator:
      return currentUserRoleId >= UserRoleEnum.Administrator ? true : false;

    default:
      return false;
  }
};

export const calendarShowEvents = (isSmall: boolean, activeView: CalendarViewModeEnum): boolean =>
  isSmall && activeView === CalendarViewModeEnum.Month;

export const calendarShowBackBtn = (isSmall: boolean, activeView: CalendarViewModeEnum): boolean =>
  isSmall ? activeView === CalendarViewModeEnum.Day : activeView !== CalendarViewModeEnum.Year;

export const getAccesToDownloadPost = (postType: ConversationsTypeEnum): boolean => {
  const allowedTypes = [
    ConversationsTypeEnum.Text,
    ConversationsTypeEnum.Announcement,
    ConversationsTypeEnum.Event,
    ConversationsTypeEnum.Poll,
  ];
  return allowedTypes.includes(postType);
};
