<template>
  <div class="group-deleted">
    <conversations-text :text-data="groupDeletedData" :conversations-flag="conversationsFlag" />
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import type { ConversationsFlagEnum } from '@/@enums';
import { ConversationsText } from '@/components';

const props = defineProps({
  groupDeletedData: {
    type: String,
    required: true,
  },
  conversationsFlag: {
    type: String as PropType<ConversationsFlagEnum>,
    required: true,
  },
});

const groupDeletedData: ComputedRef<string> = computed(() => props.groupDeletedData);
</script>
<style scoped lang="scss">
.group-deleted {
  margin-top: 1rem;
}
</style>
