<template>
  <ion-item lines="none" @click.stop="openFile()">
    <div slot="start" class="file-block">
      <template v-if="status !== FileStatusEnum.Loading">
        <icons-provider
          class="file-icon"
          :name="fileObject.type"
          :icon-props="{
            width: '40',
            height: '40',
          }"
        />
      </template>
      <template v-else>
        <ion-buttons>
          <ion-button>
            <icons-provider slot="icon-only" :icon-props="{ width: '22', height: '22' }" name="circleAnim" />
          </ion-button>
        </ion-buttons>
      </template>
    </div>

    <ion-label>
      <p class="file-name">{{ fileObject.name }}</p>
      <p v-if="fileObject.text !== ''" class="file-text">
        {{ fileObject.text }}
      </p>
    </ion-label>

    <ion-button v-if="status !== FileStatusEnum.Loading" size="default" fill="clear" @click.stop="downloadFile()">
      <ion-icon slot="icon-only" :icon="icons.download" />
    </ion-button>
    <ion-button
      v-if="status === FileStatusEnum.Loading"
      size="default"
      fill="clear"
      @click.stop="stopDownload(fileObject.key)"
    >
      <ion-icon slot="icon-only" :icon="icons.close" />
    </ion-button>
  </ion-item>
</template>

<script lang="ts" setup>
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel } from '@ionic/vue';
import { closeOutline, documentOutline, downloadOutline } from 'ionicons/icons';
import type { PropType, Ref } from 'vue';
import { computed, onMounted, ref } from 'vue';

import { FileStatusEnum } from '@/@enums';
import type { FileModel } from '@/@types';
import { IconsProvider } from '@/components';
import { filesHybrid, isNativeMobile, showToast, useOfficeHelper } from '@/helpers';
import { useI18n } from '@/i18n';

// Props
const props = defineProps({
  file: {
    type: Object as PropType<FileModel>,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
    default: () => false,
  },
});

// Icons
const icons = {
  document: documentOutline,
  close: closeOutline,
  download: downloadOutline,
};

// Helpers
const { t } = useI18n();
const officeHelper = useOfficeHelper();

// Refs
const status: Ref<FileStatusEnum> = ref<FileStatusEnum>(FileStatusEnum.Init);
const fileObject = ref({
  key: '',
  name: '',
  text: '',
  icon: icons.document,
  type: '',
});

// Computed
const isOfficeFile = computed(() => officeHelper.isOfficeFile(props.file.type));

// Actions
const validateSize = (value: number) => {
  if (value) {
    let size;
    size = value / 1014;
    if (size >= 1024) {
      size = size * 0.001;
      size = size.toFixed(1);
      return size + ' MB';
    } else {
      size = size.toFixed(1);
      return size + ' KB';
    }
  }
  return '';
};

const openFile = async () => {
  if (status.value === FileStatusEnum.Loading) {
    return;
  }

  if (isOfficeFile.value) {
    const isOpened = await officeHelper.openOfficeView(
      String(props.file.id),
      false,
      {
        title: props.file?.name,
        publishedBy: props.file?.author,
        createdIn: props.file?.createdAt,
        lastEditedBy: props.file?.author,
        group: props.file?.group,
        folder: null, //TODO: add FolderModel
        type: props.file?.type,
      },
      true,
      false,
      null,
      null
    );

    if (!isOpened) {
      status.value = FileStatusEnum.Loading;
      emit('onLoading', true, props.file.key);
      status.value = await filesHybrid.openFile(props.file);
    }
  } else if (isNativeMobile || props.file.type === 'pdf') {
    status.value = FileStatusEnum.Loading;
    emit('onLoading', true, props.file.key);
    status.value = await filesHybrid.openFile(props.file);
  }
};

const downloadFile = async () => {
  if (status.value === FileStatusEnum.Loading) {
    return;
  }

  status.value = FileStatusEnum.Loading;
  emit('onLoading', true, props.file.key);
  status.value = await filesHybrid.downloadFile(props.file);

  if (status.value === FileStatusEnum.Success) {
    await showToast(t('files.successDownloaded'), true);
  }
};

const stopDownload = async (id: string) => {
  status.value = FileStatusEnum.Success;
  emit('onLoading', false, id);
  await showToast(t('files.downloadStopped'), true);
};

// Lifecycle
onMounted(async () => {
  fileObject.value.name = props.file.name + '.' + props.file.type;
  fileObject.value.text = validateSize(props.file.size).toString();
  fileObject.value.type = props.file.type;
  fileObject.value.key = props.file.key;
});

// Emits
const emit = defineEmits(['onLoading']);
</script>

<style scoped lang="scss">
.file-block {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 44px;
  margin-inline-end: app-padding(lg);
}
.file-block .file-icon {
  font-size: 40px;
}
.file-block ion-badge {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-item {
  --background: transparent;
  --inner-padding-end: 0;
  --padding-start: 0;
  margin-bottom: 0.5rem;
  border: 1px solid var(--ion-color-light-custom);
  border-radius: 4px;
  padding: 0.5rem;
  padding-right: 0;
}
ion-item:last-child {
  margin: 0;
}
ion-item ion-button {
  margin-left: auto;
  margin-right: 0;
}
ion-item:hover {
  cursor: pointer;
  opacity: 0.7;
}
ion-button ion-icon {
  color: var(--ion-color-medium);
}
.file-name {
  font-size: 1rem;
  color: var(--ion-color-dark);
}
.file-text {
  white-space: normal;
}
</style>
