<template>
  <docs-item-view
    :id="folder.id"
    :view-type="viewType"
    :document-type="DocumentTypeEnum.Folder"
    :name="folderName"
    :created-at="folder.createdAt"
    :author="folderAuthor"
    :group="folderGroupTitle"
    :icon="folderOutline"
    @onMenuOpen="openFolderMenu"
    @onItemClick="openFolder"
  />
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { folderOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import type { DocsViewTypeEnum } from '@/@enums';
import { DocumentTypeEnum, FileMenuActionEnum, ShareArchiveLinkType } from '@/@enums';
import type { FolderModel } from '@/@types';
import { DocsItemView } from '@/components';
import {
  componentDocsMoveFile,
  componentShareArchiveLink,
  componentTitleChange,
  docBrowserContextMenu,
  showToast,
  useErrors,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { useDocStore } from '@/store';

// Props
const props = defineProps({
  folder: {
    type: Object as PropType<FolderModel>,
    required: true,
  },
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
});

// Store
const docStore = useDocStore();

// Others
const { t } = useI18n();
const { handleError } = useErrors();

// Computed
const folderGroupTitle: ComputedRef<string> = computed(() =>
  props.folder.group ? props.folder.group.title : t('network.allNetwork')
);
const folderName: ComputedRef<string> = computed(() => props.folder.name);
const folderAuthor: ComputedRef<string> = computed(() => (props.folder.author ? props.folder.author?.fullName : ''));

// Actions
const openFolder = async () => {
  const folder = props.folder;
  emit('onFolderOpen', {
    id: folder.id,
    name: folder.name,
    author: folder.author,
    createdAt: folder.createdAt,
    group: folder.group,
  });
  docStore.$patch({
    selectedDocs: [],
  });
};

const openFolderMenu = async (ev: Event) => {
  const result = await docBrowserContextMenu(ev, {
    documentType: DocumentTypeEnum.Folder,
    data: props.folder,
  });
  switch (result.data) {
    case FileMenuActionEnum.Rename: {
      const result = await componentTitleChange(null, props.folder.name);
      if (result.data !== undefined) {
        await docStore.renameFolder(props.folder.id, result.data.title);
      }
      break;
    }
    case FileMenuActionEnum.Move: {
      const result = await componentDocsMoveFile(props.folder.id);
      if (result.data !== undefined) {
        if (await docStore.moveFolder(result.data.folderId, result.data.groupId, props.folder.id)) {
          await showToast(t('files.folderSuccessMoved'), true);
        } else {
          handleError(true, undefined, t('files.folderFailedMoved'));
        }
      }
      break;
    }

    case FileMenuActionEnum.ShareArchiveLink:
      await componentShareArchiveLink(props.folder.id, ShareArchiveLinkType.Folder);
      break;

    case FileMenuActionEnum.Delete: {
      const alert = await alertController.create({
        message: `${t('documents.popup.deleteFolder')} <strong> ${props.folder.name}? </strong>`,
        buttons: [
          {
            text: t('no'),
            role: 'cancel',
            cssClass: 'custom-alert_buttons',
          },
          {
            text: t('yes'),
            cssClass: 'custom-alert_buttons',
            handler: async () => {
              await docStore.deleteFolder(props.folder.id);
            },
          },
        ],
      });

      await alert.present();
      break;
    }
  }
};

// Emits
const emit = defineEmits(['onFolderOpen']);
</script>
