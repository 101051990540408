import type {
  ResponseGroupsModel,
  ResponseErrorModel,
  ResponseGroupModel,
  ResponseSuccessModel,
  ResponseUserAvatarModel,
  ResponseUsersModel,
  ResponseGroupAddUserModel,
  ResponseInviteExternalUsersModel,
  GroupCreateDataModel,
  ResponseFileModel,
} from '@/@types';
import { $api, axios } from '@/services';

export class GroupsApiService {
  async getGroups(): Promise<ResponseGroupsModel | ResponseErrorModel> {
    return axios.get('/groups/all');
  }
  async getGroupsByUserId(id: number): Promise<ResponseGroupsModel | ResponseErrorModel> {
    return axios.get(`/groups/byUser/${id}`);
  }
  async autocomplete(text: string, includeAllClosedGroups: boolean): Promise<ResponseGroupsModel | ResponseErrorModel> {
    return axios.post('/groups/autocomplete', {
      searchText: text,
      includeAllClosedGroups: includeAllClosedGroups,
    });
  }
  async loadMore(url: string): Promise<ResponseGroupsModel | ResponseErrorModel> {
    return axios.get(url);
  }
  async getGroupById(id: number): Promise<ResponseGroupModel | ResponseErrorModel> {
    return axios.get(`/groups/byId/${id}`);
  }
  async join(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/join/${groupId}`);
  }
  async leave(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/leave/${groupId}`);
  }
  async groupUpdate(groupData: GroupCreateDataModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/update/${groupData.id}`, {
      title: groupData.title,
      description: groupData.description,
      groupType: groupData.groupType,
      isOfficial: groupData.isOfficial,
      isMandant: groupData.isMandant,
    });
  }
  async groupCreate(groupData: GroupCreateDataModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/groups/create', {
      title: groupData.title,
      description: groupData.description,
      groupType: groupData.groupType,
      isOfficial: groupData.isOfficial,
      isMandant: groupData.isMandant,
    });
  }
  async updateAvatar(groupId: number, image: File): Promise<ResponseUserAvatarModel | ResponseErrorModel> {
    const response = await $api.file.upload(image);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      return axios.post(`/groups/uploadAvatar/${groupId}`, {
        key: model.data.key,
        type: model.data.type,
      });
    }

    return response as ResponseErrorModel;
  }
  async deleteAvatar(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/groups/deleteAvatar/${groupId}`);
  }
  async getUserRequests(groupId: number): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/groups/userRequests/${groupId}`);
  }
  async groupApproveUser(
    groupId: number,
    userId: number,
    isApproved: boolean
  ): Promise<ResponseGroupAddUserModel | ResponseErrorModel> {
    return axios.post(`/groups/userRequest/${groupId}`, {
      userId: userId,
      isApproved: isApproved,
    });
  }
  async updateCoverImage(groupId: number, image: File): Promise<ResponseUserAvatarModel | ResponseErrorModel> {
    const response = await $api.file.upload(image);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      return axios.post(`/groups/uploadCover/${groupId}`, {
        key: model.data.key,
        type: model.data.type,
      });
    }

    return response as ResponseErrorModel;
  }
  async deleteCover(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/groups/deleteCover/${groupId}`);
  }
  async groupInviteUsersById(groupId: number, usersIds: number[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/addUsers/${groupId}`, {
      usersIds: usersIds,
    });
  }
  async groupInviteUsersByEmail(
    groupId: number,
    userEmails: string[]
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/joinUsers/${groupId}`, {
      emails: userEmails,
    });
  }
  async groupInviteExternalUsers(
    groupId: number,
    userEmails: string[]
  ): Promise<ResponseInviteExternalUsersModel | ResponseErrorModel> {
    return axios.post(`/groups/inviteExternalUsers/${groupId}`, {
      emails: userEmails,
    });
  }
  async getCanPostGroups(searchText?: string): Promise<ResponseGroupsModel | ResponseErrorModel> {
    return axios.get(searchText !== undefined ? `/groups/canPost?searchText=${searchText}` : '/groups/canPost');
  }
  async getAdminGroups(searchText?: string): Promise<ResponseGroupsModel | ResponseErrorModel> {
    return axios.get(searchText !== undefined ? `/groups/groupsAdmin?searchText=${searchText}` : '/groups/groupsAdmin');
  }
  async removeUserFromGroup(groupId: number, userId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/groups/removeUser', { groupId, userId });
  }
  async updateShowMembers(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/updateGroupSetting/${groupId}`, { action: 0 });
  }
  async updateShowInformation(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/updateGroupSetting/${groupId}`, { action: 1 });
  }
  async updateShowDocs(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/updateGroupSetting/${groupId}`, { action: 2 });
  }
  async updateEnableLikes(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/updateGroupSetting/${groupId}`, { action: 3 });
  }
  async updateEnableComments(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/updateGroupSetting/${groupId}`, { action: 4 });
  }
  async updateEnableShared(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/updateGroupSetting/${groupId}`, { action: 5 });
  }
  async addAdmin(groupId: number, userId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/addAdmin/${groupId}`, { userId });
  }
  async removeAdmin(groupId: number, userId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/groups/removeAdmin/${groupId}`, { userId });
  }
  async deleteGroup(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/groups/delete/${groupId}`);
  }
}
