import type { ResponseAuthUserTokenModel, ResponseErrorModel } from '@/@types';
import { useErrors } from '@/helpers';
import { useAppStore, useNetworkStore } from '@/store';

export function useTokenHelper(): any {
  const appStore = useAppStore();

  const isTokenExpired = (): boolean => {
    return Date.parse(appStore.validUntil) < Date.now() || !appStore.accessToken;
  };

  const isCodeExpired = (): boolean => {
    return Date.parse(appStore.codeValid) < Date.now() || !appStore.code;
  };

  const isCodeAndTokenExpired = (): boolean => {
    const now = Date.now();
    return Date.parse(appStore.validUntil) < now || Date.parse(appStore.codeValid) < now;
  };

  const getNewToken = async (force: boolean): Promise<{ url: string; token: string | null; locale: string }> => {
    const { handleError } = useErrors();
    const tokenHelper = useTokenHelper();

    const appStore = useAppStore();
    const networkStore = useNetworkStore();

    if (tokenHelper.isCodeExpired()) {
      return {
        url: appStore.url,
        token: null,
        locale: appStore.locale,
      };
    }

    if (force || tokenHelper.isTokenExpired()) {
      try {
        appStore.$patch({
          loading: true,
        });

        const response = await fetch(`${appStore.url}${import.meta.env.VITE_APP_URL_API}/oauth/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            grantType: appStore.grantType,
            clientSecret: appStore.clientSecret,
            code: appStore.code,
            uid: appStore.homeRowId,
          }),
        });

        if (!response.ok && (response.type === 'cors' || response.status === 401)) {
          const error = (await response.json()) as ResponseErrorModel;
          appStore.$patch({
            errors: error.errorMessages,
          });
          handleError(true, error, 'Error while fetching token');
          throw error;
        }

        const responseToken = await response.json();

        if (responseToken.statusCode !== 200) {
          const error = responseToken as ResponseErrorModel;
          appStore.$patch({
            errors: error.errorMessages,
          });
          handleError(true, error, 'Error while fetching token');
          throw error;
        }

        const model = responseToken as ResponseAuthUserTokenModel;
        const { validUntil, accessToken, companyRowId } = model.data;
        appStore.$patch({
          validUntil,
          accessToken,
          companyRowId,
        });

        return {
          url: appStore.url,
          token: appStore.accessToken,
          locale: appStore.locale,
        };
      } catch (error) {
        console.error('Error while fetching token:', error);
        return { url: '', token: null, locale: '' };
      } finally {
        appStore.$patch({
          loading: false,
        });
        networkStore.$patch({
          loading: false,
        });
      }
    }

    return {
      url: appStore.url,
      token: appStore.accessToken,
      locale: appStore.locale,
    };
  };
  return {
    isTokenExpired,
    isCodeExpired,
    isCodeAndTokenExpired,
    getNewToken,
  };
}
