<template>
  <app-custom-accordion-item :with-bordered-title="false" small-header-title closed-is-default>
    <template #header>{{ $t('taskManagement.history.title') }}</template>
    <div v-if="taskData?.history" class="card-history">
      <div v-for="item in taskData.history" :key="item.id" class="item">
        <div class="marker">
          <div class="circle"></div>
          <div class="line"></div>
        </div>
        <div class="content">
          <div class="title">
            {{ taskManagementHelper.getHistoryTitle(item.actionType) }}
          </div>
          <div class="author">{{ item.author.fullName }}</div>
          <div class="date">
            {{ formatDateHelper(item.dateCreated, 'long') }}
          </div>
        </div>
      </div>
    </div>
  </app-custom-accordion-item>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';

import type { TaskManagementTaskModel } from '@/@types';
import { AppCustomAccordionItem } from '@/components';
import { formatDateHelper, useTaskManagementHelper } from '@/helpers';

defineProps({
  taskData: {
    type: Object as PropType<TaskManagementTaskModel>,
    required: true,
  },
});

const taskManagementHelper = useTaskManagementHelper();
</script>

<style scoped lang="scss">
.card-history {
  max-height: 9rem;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }

  .item {
    display: flex;

    .marker {
      position: relative;
      width: 1rem;
      margin-right: app-padding(md);
      .line {
        position: absolute;
        top: 1rem;
        left: 0.45rem;
        bottom: 0px;
        width: 0.1rem;
        background-color: var(--ion-color-light);
      }
      .circle {
        border: 0.2rem solid var(--ion-color-light);
        background: transparent;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        box-sizing: border-box;
      }
    }
    .content {
      padding-bottom: app-padding(lg);

      .author,
      .date {
        color: var(--ion-color-medium);
        font-size: 0.8rem;
      }
    }

    &:last-child {
      .marker {
        .line {
          display: none;
        }
      }
      .content {
        padding-bottom: unset;
      }
    }
  }
}
</style>
