import type { DocumentExtensionEnum } from '@/@enums';
import type {
  CreateWikiPayload,
  UpdateWikiPayload,
  UpdateWikiTemplatePayload,
  ResponseSuccessModel,
  ResponseErrorModel,
  ResponseWikiTempModel,
  ResponseWikiCreateModel,
  ResponseWikiRelationsModel,
  ResponseWikiVersionsModel,
  ResponseWikiHistoryById,
  ResponseWikiModel,
} from '@/@types';
import axios from '@/services/axios';

export class WikiApiService {
  async getWikiFromId(wikiId: number): Promise<ResponseWikiModel | ResponseErrorModel> {
    return axios.get(`/wiki/byId/${wikiId}`);
  }

  async create(payload: CreateWikiPayload): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/create', payload);
  }

  async update(payload: UpdateWikiPayload): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/update', payload);
  }

  async showRelations(id: number): Promise<ResponseWikiRelationsModel | ResponseErrorModel> {
    return axios.get(`/wiki/relations/${id}`);
  }

  async downloadWiki(id: number, documentExtension: DocumentExtensionEnum): Promise<Blob | ResponseErrorModel> {
    return axios.get(`/storage/wiki/${id}?type=${documentExtension}`, {
      responseType: 'blob',
    });
  }

  async markAsOfficial(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/markAsOfficial/${id}`);
  }

  async delete(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/delete/${id}`);
  }

  async deleteWikiAndReplace(
    id: number,
    relationWikiId: number | null = null,
    relationFileId: number | null = null
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    if (relationWikiId) {
      return axios.delete(`/wiki/deleteWithReplace/${id}?relationWikiId=${relationWikiId}`);
    }
    return axios.delete(`/wiki/deleteWithReplace/${id}?relationFileId=${relationFileId}`);
  }

  async getHistory(id: number): Promise<ResponseWikiVersionsModel | ResponseErrorModel> {
    return axios.get(`/wiki/history/${id}`);
  }

  async getHistoricalWikiById(id: number): Promise<ResponseWikiHistoryById | ResponseErrorModel> {
    return axios.get(`/wiki/historyById/${id}`);
  }

  // Template
  async getCurrentTemplate(): Promise<ResponseWikiTempModel | ResponseErrorModel> {
    return axios.get('/wiki/currentTemp');
  }

  async getTemplateById(id: number): Promise<ResponseWikiTempModel | ResponseErrorModel> {
    return axios.get(`/wiki/tempById/${id}`);
  }

  async updateCurrentTemplate(payload: UpdateWikiTemplatePayload): Promise<ResponseWikiTempModel | ResponseErrorModel> {
    return axios.post('/wiki/updateTemp', payload);
  }

  //TODO: waiting for backend
  async updateTemplateById(
    id: number,
    payload: UpdateWikiTemplatePayload
  ): Promise<ResponseWikiTempModel | ResponseErrorModel> {
    return axios.post(`/wiki/updateTempById/${id}`, payload);
  }

  async deleteTemplate(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/deleteTemp/${id}`);
  }

  // Tags
  async addTag(id: number, tagTexts: string[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/wiki/addTag', { id, tagTexts });
  }

  async deleteTag(id: number, tagId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/removeTag/${id}?tagId=${tagId}`);
  }
}
