import type { PickedFile } from '@capawesome/capacitor-file-picker';
import { alertController } from '@ionic/core';
import openAI from 'openai';
import type { OpenAI } from 'openai';
import { useI18n } from 'vue-i18n';

import { AiModeEnum, UserRoleEnum, aiAssistantAccessLevelEnum } from '@/@enums';
import type { ChatGPTMessage } from '@/@types';
import { filesHybrid, useErrors } from '@/helpers';
import { useNetworkStore, useAppStore, useUserStore, useAiAssistantStore } from '@/store';

const openai = new openAI({
  // baseURL: 'https://api.openai.com/v2',
  // communex-app service account api key - import.meta.env.VITE_OPEN_AI_NEW_API_KEY
  apiKey: import.meta.env.VITE_OPEN_AI_API_KEY,
  // organization: import.meta.env.VITE_OPEN_AI_ORG_ID,
  // project: import.meta.env.VITE_OPEN_AI_PROJECT_ID,
  dangerouslyAllowBrowser: true,
});

let aiAssistantInstance: IAiAssistant | null = null;

interface IAiAssistant {
  getAccessToAiAssistant: () => boolean;
  showSupportedFileTypes: (t: any) => Promise<boolean>;
  showSupportedImageTypes: (t: any) => Promise<boolean>;
  checkAssistant: (t: any) => Promise<void>;
  checkThread: (t: any) => Promise<void>;
  initChat: (threadId: string, t: any) => void;
  getAnswerFromAssistant: (
    assistantId: string,
    threadId: string,
    message: string,
    files: OpenAI.Files.FileObject[] | undefined
  ) => Promise<{
    userMessage: OpenAI.Beta.Threads.Message;
    assistantMessage: OpenAI.Beta.Threads.Message;
  } | null>;
  handleImage: (mode: AiModeEnum, image: PickedFile, prompt: string) => Promise<OpenAI.Images.ImagesResponse | null>;
  uploadFile: (file: PickedFile) => Promise<OpenAI.Files.FileObject | null>;
  listFiles: () => Promise<OpenAI.Files.FileObjectsPage | null>;
  retrieveFile: (fileId: string) => Promise<OpenAI.Files.FileObject | null>;
  deleteFile: (fileId: string) => Promise<OpenAI.Files.FileDeleted | null>;
  getFileContent: (fileId: string) => Promise<any | null>;
  waitForProcessing: (fileId: string) => Promise<OpenAI.Files.FileObject | null>;
  deleteAssistant: (assistant: OpenAI.Beta.Assistant) => Promise<boolean>;
  listAssistants: (params: OpenAI.Beta.AssistantListParams) => Promise<OpenAI.Beta.Assistants.Assistant[] | null>;
}

export function useAiAssistantHelper(): IAiAssistant {
  if (aiAssistantInstance) {
    return aiAssistantInstance;
  }

  // Checking
  const getAccessToAiAssistant = (): boolean => {
    const networkStore = useNetworkStore();
    const userStore = useUserStore();
    const currentUserRoleId: UserRoleEnum = userStore.current?.roleId ?? 0;

    const aiAssistantAccessLevel = networkStore.settings?.aiAssistantAccessLevel ?? aiAssistantAccessLevelEnum.None;

    switch (aiAssistantAccessLevel) {
      case aiAssistantAccessLevelEnum.None:
        return false;

      case aiAssistantAccessLevelEnum.User:
        return currentUserRoleId >= UserRoleEnum.User ? true : false;

      case aiAssistantAccessLevelEnum.Administrator:
        return currentUserRoleId >= UserRoleEnum.Administrator ? true : false;

      default:
        return false;
    }
  };

  const checkAssistant = async (t: any): Promise<void> => {
    const { handleError } = useErrors();

    if (!useAiAssistantStore().getAssistant?.id) {
      const result = await _createAssistant();
      if (!result) {
        handleError(true, undefined, t('aiAssistant.assistantCreate.error'));
        return;
      }
    }
  };

  const checkThread = async (t: any): Promise<void> => {
    const { handleError } = useErrors();
    const aiAssistantStore = useAiAssistantStore();

    const currentThread = aiAssistantStore.getThread;
    if (!currentThread) {
      const result = await _createThread();
      if (!result) {
        handleError(true, undefined, t('aiAssistant.threadCreate.error'));
        return;
      }
    }
  };

  // Default Flow Initialization
  const initChat = (threadId: string, t: any): void => {
    console.log('≥≥≥Initializing chat...'); //! DEBUG
    const greeting = {
      id: Date.now().toString(),
      role: 'assistant',
      content: t('aiAssistant.greeting'),
      created: Date.now(),
      language: useAppStore().locale,
      isProcessing: false,
    } as ChatGPTMessage;
    useAiAssistantStore().pushMessage(threadId, greeting);
  };

  const _createAssistant = async (): Promise<OpenAI.Beta.Assistant | null> => {
    try {
      // Create an assistant
      const assistant = await openai.beta.assistants.create({
        name: 'CommuneX Bot',
        instructions:
          "You are a CommuneX assistant, dedicated to helping customers navigate and utilize the CommuneX platform effectively. CommuneX is an advanced communication platform prioritizing security and offering a comprehensive suite of features designed to enhance collaboration and communication. These features include: - **Social Intranet:** Facilitate internal communication and collaboration within organizations. - **Homepage Builder:** Create and customize landing pages for different purposes. - **AI Assistant:** Provide intelligent assistance to users for various tasks. - **Document Management:** Organize, store, and manage documents securely. - **Knowledge Management:** Capture, distribute, and effectively use organizational knowledge. - **Top-Down Communication:** Streamline communication from management to employees. - **Customer Communication:** Enhance interactions with customers through various channels. - **Messenger:** Enable real-time messaging and chat. - **Online Wiki Editor:** Collaboratively create and edit wiki pages. - **Online Document Editing:** Edit documents online with real-time collaboration. - **Video Conferencing:** Conduct virtual meetings and conferences with ease. Your primary responsibilities are to: 1. Provide clear, concise, and accurate assistance to ensure users can effectively utilize the platform's features. 2. Address and resolve user inquiries and issues promptly. 3. Offer guidance on best practices for using CommuneX. 4. Continuously update your knowledge of the platform’s features and improvements. Your goal is to ensure a seamless and productive experience for all CommuneX users. Always write answers in a markdown format.",
        model: 'gpt-4o',
        tools: [{ type: 'code_interpreter' }, { type: 'file_search' }],
      });
      console.log('≥≥≥Created assistant:', assistant); //! DEBUG

      useAiAssistantStore().setAssistant(assistant);
      return assistant;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  };

  const _createThread = async (): Promise<OpenAI.Beta.Thread | null> => {
    try {
      // Create a thread
      const thread = await openai.beta.threads.create();
      console.log('≥≥≥Created thread:', thread); //! DEBUG
      useAiAssistantStore().setThread(thread);
      return thread;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  };

  const _messageToThread = async (
    threadId: string,
    body: OpenAI.Beta.Threads.MessageCreateParams
  ): Promise<OpenAI.Beta.Threads.Message | null> => {
    try {
      const message = await openai.beta.threads.messages.create(threadId, body);
      return message;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  };

  const _createRun = async (threadId: string, assistantId: string): Promise<OpenAI.Beta.Threads.Run | null> => {
    try {
      const run = await openai.beta.threads.runs.create(threadId, {
        assistant_id: assistantId,
      });
      return run;
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  };

  const _checkingRunStatus = async (threadId: string, runId: string): Promise<OpenAI.Beta.Threads.Run | null> => {
    let runStatus: OpenAI.Beta.Threads.Run;
    const timeout = 60000;
    const start = Date.now();

    while (Date.now() - start < timeout) {
      runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);
      switch (runStatus.status) {
        case 'completed':
          return runStatus;
        case 'failed':
          return runStatus;
        case 'in_progress':
          await new Promise((resolve) => setTimeout(resolve, 1000));
          break;
        default:
          console.error('Error checking run status');
          return null;
      }
    }

    console.error('Run timed out');
    return null;
  };

  const getAnswerFromAssistant = async (
    assistantId: string,
    threadId: string,
    message: string,
    files: OpenAI.Files.FileObject[] | undefined
  ): Promise<{
    userMessage: OpenAI.Beta.Threads.Message;
    assistantMessage: OpenAI.Beta.Threads.Message;
  } | null> => {
    if (!assistantId || !threadId || !message) {
      console.error(
        `Missing required parameters: assistantId is ${!!assistantId}, threadId is ${!!threadId}, message is ${!!message}`
      );
      return null;
    }

    const createObj = {
      role: 'user',
      content: message,
    } as OpenAI.Beta.Threads.MessageCreateParams;

    if (files) {
      createObj.attachments = [];
      files.forEach((file) => {
        createObj.attachments?.push({
          file_id: file.id,
          tools: [{ type: 'code_interpreter' }, { type: 'file_search' }],
        });
      });
    }

    try {
      // Add the user message to the thread
      let userMessage = await _messageToThread(threadId, createObj);
      if (!userMessage) {
        console.error('Error creating message');
        return null;
      }

      // Use runs to wait for the assistant response and then retrieve it
      const run = await _createRun(threadId, assistantId);
      if (!run) {
        console.error('Error creating run');
        return null;
      }

      // Check the status of the run for up to 30 seconds
      const status = await _checkingRunStatus(threadId, run.id);
      if (!status) {
        console.error('Failed to check run status or timed out');
        return null;
      }
      if (status.status === 'failed') {
        console.error('Run failed');
        return null;
      }

      // Get the last assistant message from the messages array
      const messages = await openai.beta.threads.messages.list(threadId);

      // Find the last user message for the current run
      userMessage = messages.data.find((msg) => msg.run_id === run.id && msg.role === 'user') || userMessage;

      // Find the last assistant message for the current run
      const assistantMessage = messages.data.find((msg) => msg.run_id === run.id && msg.role === 'assistant');

      if (!assistantMessage) {
        console.error('Error retrieving last message for run');
        return null;
      }

      return { userMessage, assistantMessage };
    } catch (error) {
      console.error('Error making API call:', error);
      return null;
    }
  };

  // File Management
  const showSupportedFileTypes = async (t: any): Promise<boolean> => {
    const alert = await alertController.create({
      header: t('aiAssistant.supportedFiles'),
      message: filesHybrid.aiSupportedFiles.map((file) => `- ${file}`).join('\n'),
      buttons: ['Ok'],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role === 'ok';
  };

  const uploadFile = async (file: PickedFile): Promise<OpenAI.Files.FileObject | null> => {
    const { handleError } = useErrors();

    if (!file?.blob) {
      console.error('File has no blob');
      return null;
    }

    try {
      const preparedFile = await openAI.toFile(file.blob, file.path, {
        type: file.mimeType,
      });

      const response = await openai.files.create({
        file: preparedFile,
        purpose: 'assistants', // 'assistants' | 'batch' | 'fine-tune'
      });

      return response;
    } catch (error) {
      handleError(true, error, useI18n().t('aiAssistant.upload.files.error'));
      return null;
    }
  };

  const listFiles = async (): Promise<OpenAI.Files.FileObjectsPage | null> => {
    try {
      return await openai.files.list();
    } catch (error) {
      console.error('Error listing file:', error);
      return null;
    }
  };

  const retrieveFile = async (fileId: string): Promise<OpenAI.Files.FileObject | null> => {
    try {
      return await openai.files.retrieve(fileId);
    } catch (error) {
      console.error('Error retrieving file:', error);
      return null;
    }
  };

  const deleteFile = async (fileId: string): Promise<OpenAI.Files.FileDeleted | null> => {
    try {
      return await openai.files.del(fileId);
    } catch (error) {
      console.error('Error deleting file:', error);
      return null;
    }
  };

  //?: any
  const getFileContent = async (fileId: string): Promise<any | null> => {
    try {
      return await openai.files.content(fileId);
    } catch (error) {
      console.error('Error getting file content:', error);
      return null;
    }
  };

  const waitForProcessing = async (fileId: string): Promise<OpenAI.Files.FileObject | null> => {
    try {
      return await openai.files.retrieve(fileId);
    } catch (error) {
      console.error('Error waiting for processing:', error);
      return null;
    }
  };

  // Image Management
  const showSupportedImageTypes = async (t: any): Promise<boolean> => {
    const alert = await alertController.create({
      header: t('aiAssistant.supportedImages'),
      message: filesHybrid.aiSupportedImages.map((file) => `- ${file}`).join('\n'),
      buttons: ['Ok'],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role === 'ok';
  };

  const handleImage = async (
    mode: AiModeEnum,
    image: PickedFile,
    prompt: string,
    n = 1
  ): Promise<OpenAI.Images.ImagesResponse | null> => {
    const { handleError } = useErrors();

    if (!image || mode === AiModeEnum.Default) {
      handleError(true, undefined, useI18n().t('aiAssistant.upload.images.error'));
      return null;
    }

    switch (mode) {
      case AiModeEnum.ImageVariation:
        return _createImageVariation(image, n);
      case AiModeEnum.ImageEditing:
        return _editImage(image, prompt, n);
      case AiModeEnum.ImageGeneration:
        return _generateImage(prompt, n);
      default:
        handleError(true, undefined, useI18n().t('aiAssistant.upload.images.error'));
        return null;
    }
  };

  const _createImageVariation = async (image: PickedFile, n = 1): Promise<OpenAI.Images.ImagesResponse | null> => {
    if (!image?.blob) {
      console.error('Image has no blob');
      return null;
    }

    try {
      const preparedImage = await openAI.toFile(image.blob, image.path, {
        type: image.mimeType,
      });

      const response = await openai.images.createVariation({
        image: preparedImage,
        model: 'dall-e-2',
        n,
        response_format: 'url',
        size: '512x512',
      });

      return response;
    } catch (error) {
      console.error('Error creating image variation:', error);
      return null;
    }
  };

  const _editImage = async (image: PickedFile, prompt: string, n = 1): Promise<OpenAI.Images.ImagesResponse | null> => {
    if (!image?.blob) {
      console.error('Image has no blob');
      return null;
    }

    try {
      const preparedImage = await openAI.toFile(image.blob, image.path, {
        type: image.mimeType,
      });

      const response = await openai.images.edit({
        image: preparedImage,
        prompt,
        model: 'dall-e-2',
        n,
        response_format: 'url',
        size: '512x512',
      });

      return response;
    } catch (error) {
      console.error('Error editing image:', error);
      return null;
    }
  };

  const _generateImage = async (prompt: string, n = 1): Promise<OpenAI.Images.ImagesResponse | null> => {
    try {
      const response = await openai.images.generate({
        prompt,
        model: 'dall-e-2',
        n,
        quality: 'hd',
        response_format: 'url',
        size: '512x512',
        style: null,
      });

      return response;
    } catch (error) {
      console.error('Error generating image:', error);
      return null;
    }
  };

  const listAssistants = async (
    params: OpenAI.Beta.AssistantListParams = {}
  ): Promise<OpenAI.Beta.Assistants.Assistant[] | null> => {
    try {
      const list = await openai.beta.assistants.list(params);
      // if (!response.data || response.has_more === false) {
      //   return null;
      // }
      return list.data;
    } catch (error) {
      console.error('Error listing assistants:', error);
      return null;
    }
  };

  const deleteAssistant = async (assistant: OpenAI.Beta.Assistant): Promise<boolean> => {
    try {
      // const assistant = await openai.beta.assistants.retrieve(assistantId);
      // console.log(
      //   `Assistant: ${assistant.id} - ${assistant.name} - ${new Date(assistant.created_at)}`
      // ); //! DEBUG
      console.log('≥≥≥Deleting assistant:', assistant); //! DEBUG
      await openai.beta.assistants.del(assistant.id);
      return true;
    } catch (error) {
      console.error('Error deleting assistant:', error);
      return false;
    }
  };

  /* //*Simulate the typing animation
  const _simulateTypingAnimation = async (message: string) => {
    const aiAssistantStore = useAiAssistantStore();

    const emptyObj = {
      id: Date.now().toString(),
      role: 'assistant',
      content: '...',
      created: Date.now(),
      language: 'en',
    } as ChatGPTMessage;

    aiAssistantStore.addMessage(emptyObj);

    const typingDelay = 50;
    const delay = Math.min(
      typingDelay,
      typingDelay / message.split(' ').length
    );
    const arrayOfChars = message.split('');

    emptyObj.content = '';
    aiAssistantStore.changeMessage(emptyObj.id, '');

    for (const char of arrayOfChars) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          aiAssistantStore.changeMessage(emptyObj.id, emptyObj.content + char);
          resolve();
        }, delay);
      });
    }
  };
*/

  aiAssistantInstance = {
    getAccessToAiAssistant,
    checkAssistant,
    checkThread,
    initChat,
    getAnswerFromAssistant,
    showSupportedFileTypes,
    uploadFile,
    listFiles,
    retrieveFile,
    deleteFile,
    getFileContent,
    waitForProcessing,
    showSupportedImageTypes,
    handleImage,
    deleteAssistant,
    listAssistants,
  };

  return aiAssistantInstance;
}
