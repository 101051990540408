<template>
  <docs-item-view
    :id="wiki.id"
    :view-type="viewType"
    :document-type="DocumentTypeEnum.Wiki"
    :name="wikiName"
    :created-at="wiki.createdAt"
    :author="wikiAuthor"
    :group="wikiGroupTitle"
    :icon="documentTextOutline"
    @onMenuOpen="openWikiMenu"
    @onItemClick="openWiki"
  />
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { documentTextOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import type { DocsViewTypeEnum } from '@/@enums';
import { DocumentTypeEnum, FileMenuActionEnum, ShareArchiveLinkType } from '@/@enums';
import type { DocEntity, WikiModel } from '@/@types';
import { DocsItemView } from '@/components';
import {
  componentDocsMoveFile,
  componentDocsShareFile,
  componentShareArchiveLink,
  docBrowserContextMenu,
  showToast,
  useErrors,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { useDocStore } from '@/store';

// Props
const props = defineProps({
  wiki: {
    type: Object as PropType<WikiModel>,
    required: true,
  },
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
});

// Store
const docStore = useDocStore();

// Helpers
const { t } = useI18n();
const { handleError } = useErrors();

// Computed
const wikiName: ComputedRef<string> = computed(() => props.wiki.name);
const isAttachment: ComputedRef<boolean> = computed(() => docStore.isAttachmentModal);
const selectedDocs: ComputedRef<DocEntity[]> = computed(() => docStore.selectedDocs);
const wikiGroupTitle: ComputedRef<string> = computed(() =>
  props.wiki.group ? props.wiki.group.title : t('network.allNetwork')
);
const wikiAuthor: ComputedRef<string> = computed(() => (props.wiki.author ? props.wiki.author?.fullName : ''));

// Actions
const openWiki = async (throughMenu?: boolean) => {
  if (isAttachment.value) {
    if (!selectedDocs.value.some((n) => n.data.id === props.wiki.id)) {
      docStore.$patch({
        selectedDocs: [...selectedDocs.value, { documentType: DocumentTypeEnum.Wiki, data: props.wiki }],
      });
    } else {
      docStore.$patch({
        selectedDocs: selectedDocs.value.filter((n) => n.data.id !== props.wiki.id),
      });
    }
  } else {
    if (throughMenu) {
      emit('onWikiPreview', props.wiki.id);
    } else {
      emit('onWikiOpen', props.wiki.id);
    }
  }
};

const openWikiMenu = async (ev: Event) => {
  const result = await docBrowserContextMenu(ev, {
    documentType: DocumentTypeEnum.Wiki,
    data: props.wiki,
  });
  switch (result.data) {
    case FileMenuActionEnum.Open:
      await openWiki(true);
      break;

    case FileMenuActionEnum.Move: {
      const result = await componentDocsMoveFile(null);
      if (result.data !== undefined) {
        if (await docStore.moveWiki(result.data.folderId, result.data.groupId, props.wiki.id)) {
          await showToast(t('wiki.successMoved'), true);
        } else {
          handleError(true, undefined, t('wiki.failedMoved'));
        }
      }
      break;
    }
    case FileMenuActionEnum.Share: {
      const result = await componentDocsShareFile();
      if (result.data !== undefined) {
        if (result.data.groupId !== undefined) {
          await docStore.shareFileToGroup(props.wiki.id, result.data.text, result.data.groupId);
        } else {
          await docStore.shareFile(props.wiki.id, result.data.text);
        }
      }
      break;
    }

    case FileMenuActionEnum.ShareArchiveLink:
      await componentShareArchiveLink(props.wiki.id, ShareArchiveLinkType.Wiki);
      break;

    case FileMenuActionEnum.Delete: {
      const alert = await alertController.create({
        message: `${t('documents.popup.deleteWiki')} <strong> ${props.wiki.name}? </strong>`,
        buttons: [
          {
            text: t('no'),
            role: 'cancel',
            cssClass: 'custom-alert_buttons',
          },
          {
            text: t('yes'),
            cssClass: 'custom-alert_buttons',
            handler: async () => {
              await docStore.deleteWiki(props.wiki.id);
            },
          },
        ],
      });

      await alert.present();
      break;
    }
  }
};

// Emits
const emit = defineEmits(['onWikiOpen', 'onWikiPreview']);
</script>
