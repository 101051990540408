import { cloneDeep, filter, find, includes, indexOf, merge, orderBy, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import type { AppMenuLanguagesEnum, FeedTypeEnum, IdeaTypeEnum, SendKeyEnum, ThemeAppEnum } from '@/@enums';
import { UsersFilterEnum } from '@/@enums';
import type {
  ErrorMessageModel,
  MediaModel,
  PasswordSaveModel,
  PasswordSettingsModel,
  PhoneConfirmModel,
  PhoneMobileVisibleUpdateModel,
  ResponseErrorModel,
  ResponsePasswordPolicyModel,
  ResponsePasswordRestoreModel,
  ResponsePhoneChangeModel,
  ResponseShortUsersModel,
  ResponseUserAvatarModel,
  ResponseUserModel,
  ResponseUsersModel,
  ShortUsersModel,
  SupportModel,
  UserCurrentModel,
  UserEntity,
  UserMessageModel,
  UserProfileUpdateModel,
  UsersIdsModel,
} from '@/@types';
import { defaultUser, defaultUsersIds } from '@/models';
import { $api } from '@/services';
import { type EntityState, useAppStore, useNetworkStore } from '@/store';

interface UserState extends EntityState<UserEntity> {
  current: UserCurrentModel | null;
  policy: PasswordSettingsModel | null;
  usersIds: UsersIdsModel;
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => ({
    data: [],
    errors: [],
    loading: false,
    current: null,
    policy: null,
    usersIds: cloneDeep(defaultUsersIds),
  }),
  getters: {
    getId: (state): number => state.current?.id ?? 0,
    getErrors:
      (state) =>
      (type = 'default'): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getUserProfile:
      (state) =>
      (id: number): UserEntity => {
        const index = state.data.findIndex((user: UserEntity) => user.id === id);

        if (~index) {
          return state.data[index];
        }
        return cloneDeep(defaultUser);
      },
    getUserAliasById: (state) => (id: number) => {
      return state.data.filter((n: UserEntity) => n.id === id)[0].mainAlias;
    },
    getUsersPage:
      (state) =>
      (withoutCurrent?: boolean): ShortUsersModel => {
        const result = { data: [], loadMoreUrl: null } as ShortUsersModel;
        const data = orderBy(state.data, (obj) => indexOf(state.usersIds.usersPage.ids, obj.id));
        result.data = withoutCurrent
          ? filter(data, (obj) => includes(state.usersIds.usersPage.ids, obj.id) && obj.id !== state.current?.id)
          : filter(data, (obj) => includes(state.usersIds.usersPage.ids, obj.id));
        result.loadMoreUrl = state.usersIds.usersPage.loadMoreUrl;
        return result;
      },
    getUsersList:
      (state) =>
      (withoutCurrent?: boolean): ShortUsersModel => {
        const result = { data: [], loadMoreUrl: null } as ShortUsersModel;
        const data = orderBy(state.data, (obj) => indexOf(state.usersIds.list.ids, obj.id));
        result.data = withoutCurrent
          ? filter(data, (obj) => includes(state.usersIds.list.ids, obj.id) && obj.id !== state.current?.id)
          : filter(data, (obj) => includes(state.usersIds.list.ids, obj.id));
        result.loadMoreUrl = state.usersIds.list.loadMoreUrl;
        return result;
      },
    getUsersChosen:
      (state) =>
      (mode: UsersFilterEnum): ShortUsersModel => {
        const ids =
          mode === UsersFilterEnum.ChosenMentions
            ? state.usersIds.chosen.mentions.ids
            : state.usersIds.chosen.others.ids;

        const loadMoreUrl =
          mode === UsersFilterEnum.ChosenMentions
            ? state.usersIds.chosen.mentions.loadMoreUrl
            : state.usersIds.chosen.others.loadMoreUrl;

        const data = orderBy(state.data, (obj) => indexOf(ids, obj.id));

        return {
          data: filter(data, (obj) => includes(ids, obj.id)),
          loadMoreUrl,
        };
      },
    getUsersFromGroupId:
      (state) =>
      (groupId: number | null): ShortUsersModel => {
        const index = state.usersIds.groups.findIndex((n) => n.groupId === groupId);
        const result = { data: [], loadMoreUrl: null } as ShortUsersModel;
        if (~index) {
          const data = orderBy(state.data, (obj) => indexOf(state.usersIds.groups[index].ids, obj.id));
          result.data = filter(data, (obj) => includes(state.usersIds.groups[index].ids, obj.id));
          result.loadMoreUrl = state.usersIds.groups[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getUsersFromProfileId:
      (state) =>
      (profileId: number | null): ShortUsersModel => {
        const index = state.usersIds.followers.findIndex((n) => n.userId === profileId);
        const result = { data: [], loadMoreUrl: null } as ShortUsersModel;
        if (~index) {
          const data = orderBy(state.data, (obj) => indexOf(state.usersIds.followers[index].ids, obj.id));
          result.data = filter(data, (obj) => includes(state.usersIds.followers[index].ids, obj.id));
          result.loadMoreUrl = state.usersIds.followers[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getFollowingUsers:
      (state) =>
      (userId: number | null): ShortUsersModel => {
        const index = state.usersIds.following.findIndex((n) => n.userId === userId);
        const result = { data: [], loadMoreUrl: null } as ShortUsersModel;
        if (~index) {
          const data = orderBy(state.data, (obj) => indexOf(state.usersIds.following[index].ids, obj.id));
          result.data = filter(data, (obj) => includes(state.usersIds.following[index].ids, obj.id));
          result.loadMoreUrl = state.usersIds.following[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getLoadMoreUrl:
      (state) =>
      (mode: UsersFilterEnum, userId?: number | null, groupId?: number | null): string | null => {
        let url = null as null | string;

        switch (mode) {
          case UsersFilterEnum.UsersPage:
            {
              url = state.usersIds.usersPage.loadMoreUrl;
            }
            break;

          case UsersFilterEnum.List:
            {
              url = state.usersIds.list.loadMoreUrl;
            }
            break;

          case UsersFilterEnum.ByGroup:
            {
              const index = state.usersIds.groups.findIndex((n) => n.groupId === groupId);
              if (~index) {
                url = state.usersIds.groups[index].loadMoreUrl;
              }
            }

            break;

          case UsersFilterEnum.Followers:
            {
              const index = state.usersIds.followers.findIndex((n) => n.userId === userId);
              if (~index) {
                url = state.usersIds.followers[index].loadMoreUrl;
              }
            }
            break;

          case UsersFilterEnum.Following:
            {
              const index = state.usersIds.following.findIndex((n) => n.userId === userId);
              if (~index) {
                url = state.usersIds.following[index].loadMoreUrl;
              }
            }
            break;
        }

        return url;
      },
  },
  actions: {
    async list(isPersist?: boolean): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.list();

      if (response.statusCode === 200) {
        const model = response as ResponseUsersModel;
        this.data = mergeById(this.data, model.data);

        if (isPersist) {
          this.usersIds.usersPage.ids = model.data.map((n) => n.id);
          this.usersIds.usersPage.loadMoreUrl = model.loadMoreUrl;
        } else {
          this.usersIds.list.ids = model.data.map((n) => n.id);
          this.usersIds.list.loadMoreUrl = model.loadMoreUrl;
        }

        this.loading = false;

        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
    },
    async loadMore(mode: UsersFilterEnum, userId?: number | null, groupId?: number | null): Promise<void> {
      this.errors = [];
      const url = this.getLoadMoreUrl(mode, userId, groupId);
      if (url !== null) {
        const response = await $api.user.loadMore(url);

        if (response.statusCode === 200) {
          const model = response as ResponseUsersModel;
          this.data = mergeById(this.data, model.data);
          updateUsersAfterLoadedMore(mode, model, userId, groupId);
          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
        return;
      }
      return;
    },
    async autocomplete(data: string, mode: UsersFilterEnum): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.autocomplete(data);

      if (response.statusCode === 200) {
        const model = response as ResponseUsersModel;

        this.data = mergeById(this.data, model.data);
        if (mode === UsersFilterEnum.UsersPage) {
          this.usersIds.usersPage.ids = model.data.map((n) => n.id);
          this.usersIds.usersPage.loadMoreUrl = model.loadMoreUrl;
        } else {
          this.usersIds.list.ids = model.data.map((n) => n.id);
          this.usersIds.list.loadMoreUrl = model.loadMoreUrl;
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
    },
    usersFromSearch(users: UserEntity[], loadMoreUrl: string | null = null): void {
      if (users.length) {
        this.data = mergeById(this.data, users);
        this.usersIds.chosen.others.ids = users.map((n) => n.id);
        this.usersIds.chosen.others.loadMoreUrl = loadMoreUrl;
      }
    },
    async currentUser(): Promise<UserCurrentModel | null> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.current();

      if (response.statusCode === 200) {
        const networkStore = useNetworkStore();
        const model = response as ResponseUserModel;
        const user = cloneDeep(model.data);
        user.isPolicyAccepted =
          !networkStore.settings?.isRequireAcceptPolicy ||
          (networkStore.settings?.isRequireAcceptPolicy && user.isPolicyAccepted);
        this.current = user;

        this.loading = false;
        return user;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return null;
    },
    async userProfileById(userId: number): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.getUserById(userId);

      if (response.statusCode === 200) {
        const model = response as ResponseUserModel;
        const user = cloneDeep(model.data);
        this.loading = false;
        this.upsert(user);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async updateAvatar(image: File): Promise<boolean> {
      this.errors = [];
      const response = await $api.user.updateAvatar(image);
      if (response.statusCode === 200 && this.current !== null) {
        const model = response as ResponseUserAvatarModel;
        this.current.avatar = {
          url: model.data.url,
          width: model.data.width,
          height: model.data.height,
        } as MediaModel;

        this.upsert(this.current);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteAvatar(): Promise<boolean> {
      this.errors = [];
      const response = await $api.user.deleteAvatar();
      if (response.statusCode === 200 && this.current !== null) {
        this.current.avatar = {
          url: null,
          width: null,
          height: null,
        } as MediaModel;

        this.upsert(this.current);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async acceptRules(): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.acceptRules();
      if (response.statusCode === 200) {
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async sendToSupport(data: SupportModel): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.tool.sendToSupport(data);
      if (response.statusCode === 200) {
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async passwordSettings(): Promise<PasswordSettingsModel | undefined> {
      this.errors = [];
      this.loading = true;
      const response = await $api.account.passwordSettings();
      if (response.statusCode === 200) {
        const policy = response as ResponsePasswordPolicyModel;

        this.loading = false;
        this.policy = policy.data;
        return policy.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return undefined;
    },
    async passwordPolicy(hash: string): Promise<PasswordSettingsModel | undefined> {
      this.errors = [];
      this.loading = true;
      const response = await $api.account.passwordPolicy(hash);
      if (response.statusCode === 200) {
        const policy = response as ResponsePasswordPolicyModel;

        this.loading = false;
        this.policy = policy.data;
        return policy.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return undefined;
    },
    async passwordRestore(data: string): Promise<{ hash: string; message: string } | undefined> {
      this.$reset();
      this.loading = true;
      const response = await $api.account.passwordRestore(data);
      if (response.statusCode === 200) {
        const model = response as ResponsePasswordRestoreModel;
        this.loading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return undefined;
    },
    async passwordSave(data: PasswordSaveModel): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.account.passwordSave(data);
      if (response.statusCode === 200) {
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async passwordUpdate(data: string): Promise<boolean> {
      if (this.current === null) {
        return false;
      }

      this.errors = [];
      this.loading = true;
      const response = await $api.account.passwordUpdate(data);
      if (response.statusCode === 200) {
        this.loading = false;
        this.current.mustChangePassword = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async followers(userId: number, search: string): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.followers(userId, search);
      if (response.statusCode === 200) {
        const model = response as ResponseUsersModel;

        this.data = mergeById(this.data, model.data);

        const index = this.usersIds.followers.findIndex((n) => n.userId === userId);

        if (~index) {
          this.usersIds.followers[index].ids = model.data.map((n) => n.id);
          this.usersIds.followers[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.usersIds.followers.push({
            userId: userId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.loading = false;

        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      this.loading = false;
    },
    async following(userId: number, search: string): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.following(userId, search);
      if (response.statusCode === 200) {
        const model = response as ResponseUsersModel;

        this.data = mergeById(this.data, model.data);

        const index = this.usersIds.following.findIndex((n) => n.userId === userId);

        if (~index) {
          this.usersIds.following[index].ids = model.data.map((n) => n.id);
          this.usersIds.following[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.usersIds.following.push({
            userId: userId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.loading = false;

        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      this.loading = false;
    },
    async unFollow(userId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.user.unFollow(userId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === userId);
        this.data[index].isFollowedByCurrentUser = false;
        return true;
      }
      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async onFollow(userId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.user.follow(userId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === userId);
        if (~index) {
          this.data[index].isFollowedByCurrentUser = true;
        }
        return true;
      }
      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async userProfileUpdate(data: UserProfileUpdateModel | PhoneMobileVisibleUpdateModel): Promise<boolean> {
      if (this.current === null) {
        return false;
      }
      this.errors = [];
      const response = await $api.user.userProfileUpdate(data);
      if (response.statusCode === 200) {
        const model = response as ResponseUserModel;
        if (this.current) {
          this.current = cloneDeep(model.data);
          const index = this.data.findIndex((n) => n.id === this.current?.id);
          if (~index) {
            this.data[index] = merge({}, this.data[index], model.data);
          }
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async phoneChange(phone: string): Promise<number | undefined> {
      this.errors = [];
      const response = await $api.account.phoneChange(phone);

      if (response.statusCode === 200) {
        const model = response as ResponsePhoneChangeModel;
        this.loading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return undefined;
    },
    async phoneConfirm(data: PhoneConfirmModel, phone: string): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.account.phoneConfirm(data);

      if (response.statusCode === 200) {
        this.loading = false;
        if (this.current) this.current.phoneMobile = phone;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async phoneDelete(): Promise<boolean> {
      if (this.current === null) {
        return false;
      }

      this.errors = [];
      const response = await $api.account.phoneDelete();
      if (response.statusCode === 200) {
        this.current.phoneMobile = '';
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async emailChange(email: string): Promise<boolean> {
      this.errors = [];
      const response = await $api.user.emailChange(email);

      if (response.statusCode === 200) {
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async setTheme(theme: ThemeAppEnum): Promise<void> {
      if (this.current === null) {
        return;
      }
      await $api.account.setTheme(theme);
    },
    async setLanguage(language: AppMenuLanguagesEnum): Promise<void> {
      if (this.current === null) {
        return;
      }
      await $api.account.setLanguage(language);
    },
    async setSendMode(mode: SendKeyEnum): Promise<void> {
      if (this.current === null) {
        return;
      }
      await $api.account.setSendMode(mode);
    },
    async pagesBackground(enabled: boolean): Promise<void> {
      if (this.current === null) {
        return;
      }
      await $api.account.pagesBackground(enabled);
    },
    async appSound(enabled: boolean): Promise<void> {
      if (this.current === null) {
        return;
      }
      await $api.account.appSound(enabled);
    },
    async emailConfirm(hash: string, email: string): Promise<boolean> {
      if (this.current === null) {
        return false;
      }

      this.errors = [];
      this.loading = true;
      const response = await $api.user.emailConfirm(hash);

      if (response.statusCode === 200) {
        this.loading = false;
        this.current.email = email;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    upsert(user: UserCurrentModel) {
      const index = this.data.findIndex(({ id }) => id === user.id);

      if (~index) {
        this.data[index] = cloneDeep(user);
      } else {
        this.data = [...this.data, user];
      }

      this.$patch({
        errors: [],
      });
    },
    async updateCoverImage(image: File): Promise<boolean> {
      this.errors = [];
      const response = await $api.user.updateCover(image);
      if (response.statusCode === 200 && this.current !== null) {
        const model = response as ResponseUserAvatarModel;
        this.current.cover = {
          url: model.data.url,
          width: model.data.width,
          height: model.data.height,
        } as MediaModel;

        this.upsert(this.current);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteCoverImage(): Promise<boolean> {
      this.errors = [];
      const response = await $api.user.deleteCover();
      if (response.statusCode === 200 && this.current !== null) {
        this.current.cover = {
          url: null,
          width: null,
          height: null,
        } as MediaModel;

        this.upsert(this.current);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async usersFromGroupId(groupId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.usersByGroup(groupId);

      if (response.statusCode === 200) {
        const model = response as ResponseUsersModel;

        this.data = mergeById(this.data, model.data);

        const index = this.usersIds.groups.findIndex((n) => n.groupId === groupId);

        if (~index) {
          this.usersIds.groups[index].ids = model.data.map((n) => n.id);
          this.usersIds.groups[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.usersIds.groups.push({
            groupId: groupId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    async removeUserFromGroup(groupId: number, userId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.removeUserFromGroup(groupId, userId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === userId);
        this.data.splice(index, 1);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async usersByGroupAutocomplete(groupId: number, text: string): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.user.usersByGroupAutocomplete(groupId, text);
      if (response.statusCode === 200) {
        const model = response as ResponseUsersModel;

        this.data = mergeById(this.data, model.data);

        const index = this.usersIds.groups.findIndex((n) => n.groupId === groupId);

        if (~index) {
          this.usersIds.groups[index].ids = model.data.map((n) => n.id);
          this.usersIds.groups[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.usersIds.groups.push({
            groupId: groupId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      this.loading = false;
    },
    async chooseUserAutocomplete(data: string, mode: UsersFilterEnum): Promise<void> {
      this.errors = [];
      const response = await $api.user.autocomplete(data);

      if (response.statusCode === 200) {
        const model = response as ResponseUsersModel;

        this.data = mergeById(this.data, model.data);
        if (mode == UsersFilterEnum.ChosenMentions) {
          this.usersIds.chosen.mentions.ids = model.data.map((n) => n.id);
          this.usersIds.chosen.mentions.loadMoreUrl = model.loadMoreUrl;
        } else {
          this.usersIds.chosen.others.ids = model.data.map((n) => n.id);
          this.usersIds.chosen.others.loadMoreUrl = model.loadMoreUrl;
        }

        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
    },
    async accountDelete(): Promise<boolean> {
      if (this.current === null) {
        return false;
      }

      this.errors = [];
      const response = await $api.user.accountDelete();
      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async updateDefaultFeedType(feedType: FeedTypeEnum): Promise<boolean> {
      if (this.current === null) {
        return false;
      }

      this.errors = [];
      const response = await $api.user.updateDefaultFeedType(feedType);
      if (response.statusCode === 200) {
        this.current.defaultFeedType = feedType;
        return true;
      }
      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async updateDefaultIdeaType(ideaType: IdeaTypeEnum): Promise<boolean> {
      if (this.current === null) return false;

      this.errors = [];
      const response = await $api.user.updateDefaultIdeaType(ideaType);
      if (response.statusCode === 200) {
        this.current.defaultIdeaType = ideaType;
        return true;
      }
      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    resetUsersIdsChosen(mode: UsersFilterEnum): void {
      if (mode === UsersFilterEnum.ChosenMentions) {
        this.usersIds.chosen.mentions.ids = [];
        this.usersIds.chosen.mentions.loadMoreUrl = null;
      } else {
        this.usersIds.chosen.others.ids = [];
        this.usersIds.chosen.others.loadMoreUrl = null;
      }
    },
    async loadUsers(currentUserId: number) {
      const appStore = useAppStore();
      switch (appStore.usersFilter) {
        case UsersFilterEnum.UsersPage:
          return await this.list(true);

        case UsersFilterEnum.Following:
          return await this.following(currentUserId, '');
      }
    },
    async postViewers(postId: number): Promise<UserMessageModel[]> {
      this.errors = [];
      const response = await $api.user.getPostViewers(postId);

      if (response.statusCode === 200) {
        const model = response as ResponseShortUsersModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return [];
    },
  },
  persist: true,
});

const mergeById = (a: UserEntity[], b: UserEntity[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const updateUsersAfterLoadedMore = (
  mode: UsersFilterEnum,
  model: ResponseUsersModel,
  userId?: number | null,
  groupId?: number | null
) => {
  const userStore = useUserStore();
  switch (mode) {
    case UsersFilterEnum.UsersPage:
      {
        userStore.usersIds.usersPage.ids = [...userStore.usersIds.usersPage.ids, ...model.data.map((n) => n.id)];
        userStore.usersIds.usersPage.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case UsersFilterEnum.List:
      {
        userStore.usersIds.list.ids = [...userStore.usersIds.list.ids, ...model.data.map((n) => n.id)];
        userStore.usersIds.list.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case UsersFilterEnum.ByGroup:
      {
        const index = userStore.usersIds.groups.findIndex((n) => n.groupId === groupId);
        if (~index) {
          userStore.usersIds.groups[index].ids = [
            ...userStore.usersIds.groups[index].ids,
            ...model.data.map((n) => n.id),
          ];
          userStore.usersIds.groups[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;

    case UsersFilterEnum.Followers:
      {
        const index = userStore.usersIds.followers.findIndex((n) => n.userId === userId);
        if (~index) {
          userStore.usersIds.followers[index].ids = [
            ...userStore.usersIds.followers[index].ids,
            ...model.data.map((n) => n.id),
          ];
          userStore.usersIds.followers[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;

    case UsersFilterEnum.Following:
      {
        const index = userStore.usersIds.following.findIndex((n) => n.userId === userId);
        if (~index) {
          userStore.usersIds.following[index].ids = [
            ...userStore.usersIds.following[index].ids,
            ...model.data.map((n) => n.id),
          ];
          userStore.usersIds.following[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;
  }
};
