<template>
  <div ref="colRef" class="app-col">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useWindowSize, useCssVar } from '@vueuse/core';
import { throttle } from 'lodash';
import { onMounted, ref, watch } from 'vue';

import { BreakpointsEnum } from '@/@enums';

// Props
const props = defineProps({
  offset: {
    type: String || undefined,
    default: () => undefined,
  },
  [`offset-${BreakpointsEnum.XS}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`offset-${BreakpointsEnum['2XS']}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`offset-${BreakpointsEnum.SM}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`offset-${BreakpointsEnum.MD}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`offset-${BreakpointsEnum.LG}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`offset-${BreakpointsEnum.XL}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`offset-${BreakpointsEnum['2XL']}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`offset-${BreakpointsEnum['3XL']}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  size: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum.XS}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum['2XS']}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum.SM}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum.MD}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum.LG}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum.XL}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum['2XL']}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum['3XL']}`]: {
    type: String || undefined,
    default: () => undefined,
  },
  [`size-${BreakpointsEnum['4XL']}`]: {
    type: String || undefined,
    default: () => undefined,
  },
}) as any;

const { width: innerWidth } = useWindowSize();
const win = typeof window !== 'undefined' ? window : undefined;
const SIZE_TO_MEDIA = {
  [BreakpointsEnum.XS]: `(min-width: ${useCssVar('--app-xs-size').value})`,
  [BreakpointsEnum['2XS']]: `(min-width: ${useCssVar('--app-2xs-size').value})`,
  [BreakpointsEnum.SM]: `(min-width: ${useCssVar('--app-sm-size').value})`,
  [BreakpointsEnum.MD]: `(min-width: ${useCssVar('--app-md-size').value})`,
  [BreakpointsEnum.LG]: `(min-width: ${useCssVar('--app-lg-size').value})`,
  [BreakpointsEnum.XL]: `(min-width: ${useCssVar('--app-xl-size').value})`,
  [BreakpointsEnum['2XL']]: `(min-width: ${useCssVar('--app-2xl-size').value})`,
  [BreakpointsEnum['3XL']]: `(min-width: ${useCssVar('--app-3xl-size').value})`,
  [BreakpointsEnum['4XL']]: `(min-width: ${useCssVar('--app-4xl-size').value})`,
};
// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
const SUPPORTS_VARS = win && !!win.CSS?.supports?.('--a: 0');
const BREAKPOINTS: BreakpointsEnum[] = [
  BreakpointsEnum.None,
  BreakpointsEnum.XS,
  BreakpointsEnum['2XS'],
  BreakpointsEnum.SM,
  BreakpointsEnum.MD,
  BreakpointsEnum.LG,
  BreakpointsEnum.XL,
  BreakpointsEnum['2XL'],
  BreakpointsEnum['3XL'],
  BreakpointsEnum['4XL'],
];

// Refs
const colRef = ref<HTMLDivElement | null>(null);

// Actions
const matchBreakpoint = (breakpoint: BreakpointsEnum) => {
  if (breakpoint === '') {
    return true;
  }
  if (window.matchMedia) {
    const mediaQuery = SIZE_TO_MEDIA[breakpoint];
    return window.matchMedia(mediaQuery).matches;
  }
  return false;
};

// Loop through all of the breakpoints to see if the media query
// matches and grab the column value from the relevant prop if so
const getColumns = (property: string) => {
  let matched;
  for (const breakpoint of BREAKPOINTS) {
    const matches = matchBreakpoint(breakpoint);
    // console.log('≥≥≥App-Col - getColumns - matches: ', matches); // !DEBUG
    // Grab the value of the property, if it exists and our
    // media query matches we return the value
    const propName = property + breakpoint;
    // console.log('≥≥≥App-Col - getColumns - propName: ', propName); // !DEBUG
    const columns = props[propName];
    // console.log('≥≥≥App-Col - getColumns - columns: ', columns); // !DEBUG
    if (matches && columns !== undefined) {
      matched = columns;
    }
  }
  // Return the last matched columns since the breakpoints
  // increase in size and we want to return the largest match
  return matched;
};

const calculateSize = () => {
  const columns = getColumns('size');
  // console.log('≥≥≥App-Col - calculateSize - columns: ', columns); // !DEBUG

  // If size wasn't set for any breakpoint
  // or if the user set the size without a value
  // it means we need to stick with the default and return
  // e.g. <app-col size-md>
  if (!columns || columns === '') {
    return;
  }

  // If the size is set to auto then don't calculate a size
  let colSize;
  if (columns === 'auto') {
    colSize = 'auto';
  } else if (SUPPORTS_VARS) {
    // If CSS supports variables we should use the grid columns var
    colSize = `calc(calc(${columns} / var(--ion-grid-columns, 12)) * 100%)`;
  } else {
    // Convert the columns to a percentage by dividing by the total number
    // of columns (12) and then multiplying by 100
    colSize = (columns / 12) * 100 + '%';
  }

  return {
    flex: `0 0 ${colSize}`,
    width: `${colSize}`,
    'max-width': `${colSize}`,
  };
};

// Called by push, pull, and offset since they use the same calculations
const calculatePosition = (property: string, modifier: string) => {
  const columns = getColumns(property);
  if (!columns) {
    return;
  }
  // If the number of columns passed are greater than 0 and less than
  // 12 we can position the column, else default to auto
  let amount;
  if (SUPPORTS_VARS) {
    // If CSS supports variables we should use the grid columns var
    amount = `calc(calc(${columns} / var(--ion-grid-columns, 12)) * 100%)`;
  } else if (columns > 0 && columns < 12) {
    // Convert the columns to a percentage by dividing by the total number
    // of columns (12) and then multiplying by 100
    amount = (columns / 12) * 100 + '%';
  } else {
    amount = 'auto';
  }

  return {
    [modifier]: amount,
  };
};

const calculateOffset = () => {
  return calculatePosition('offset', 'margin-left');
};

const calculatePull = () => {
  return calculatePosition('pull', 'right');
};

const calculatePush = () => {
  return calculatePosition('push', 'left');
};

const assignStyles = throttle(function () {
  if (colRef.value) {
    Object.assign(
      colRef.value.style,
      Object.assign(
        Object.assign(Object.assign(Object.assign({}, calculateOffset()), calculatePull()), calculatePush()),
        calculateSize()
      )
    );
  }
}, 500);

// Watchers
watch(
  () => props.size,
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(
  () => props[`size${BreakpointsEnum['2XS']}`],
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(
  () => props[`size${BreakpointsEnum.SM}`],
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(
  () => props[`size${BreakpointsEnum.MD}`],
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(
  () => props[`size${BreakpointsEnum.LG}`],
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(
  () => props[`size${BreakpointsEnum.XL}`],
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(
  () => props[`size${BreakpointsEnum['2XL']}`],
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(
  () => props[`size${BreakpointsEnum['3XL']}`],
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(
  () => props[`size${BreakpointsEnum['4XL']}`],
  (newValue, oldValue) => {
    if (oldValue !== undefined && newValue !== oldValue) {
      assignStyles();
    }
  },
  { immediate: true }
);

watch(innerWidth, () => {
  assignStyles();
});

// Lifecycle
onMounted(() => {
  assignStyles();
});
</script>

<style lang="scss" scoped>
.app-col {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
}
</style>
