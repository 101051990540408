import { Clipboard } from '@capacitor/clipboard';
import { type ToastButton, toastController } from '@ionic/vue';
import type { Feedback } from '@sentry/browser';
import type { FeedbackWidget } from '@sentry-internal/feedback/types/types';
import type { AxiosError } from 'axios';
import { bugOutline, closeCircleOutline, checkmarkOutline, copyOutline } from 'ionicons/icons';
import type { ErrorEvent } from 'node_modules/@sentry/types/types/event';

import { SentrySibling } from '../plugins/sentry';

import { isAnyMobile } from './helper';
import { useRequirementsHelper } from './useRequirementsHelper';
import { useTokenHelper } from './useTokenHelper';

import { RequirementsEnum, ResponseErrorTypeEnum } from '@/@enums';
import type { ResponseErrorModel } from '@/@types';
// import { useI18n } from '@/i18n';
import { useAppStore, useMessengerStore, useNetworkStore } from '@/store';
// import { $api } from '@/services';

interface IErrors {
  handleError: (
    showUser: boolean,
    error: AxiosError<ResponseErrorModel> | undefined | any, //TODO: Change to strict type AxiosError<ResponseErrorModel> | undefined
    message?: string
  ) => ResponseErrorTypeEnum | string;
  setSentryEvent: (event: ErrorEvent) => Promise<void>;
  setFeedbackWidget: (feedback: Feedback) => void;
  handleSuccessfulSubmit: () => Promise<void>;
  newShowToast: (
    id: string,
    message: string,
    isSuccess: boolean,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null
  ) => Promise<void>;
}

interface CurrentErrorEvent {
  id: string;
  message: string;
  serverDetails: ResponseErrorModel | null;
  sentryDetails: ErrorEvent | null;
  showUser?: boolean;
}

let instance: IErrors | null = null;

export function useErrors(): IErrors {
  if (instance) {
    return instance;
  }

  const networkStore = useNetworkStore();
  const appStore = useAppStore();
  const messengerStore = useMessengerStore();

  // const { t } = useI18n();
  const nativeClipboard = Clipboard;
  const isDev = import.meta.env.DEV;

  let movedToastTo = 0;
  let feedbackWidget: FeedbackWidget | null = null;
  let feedbackClass: Feedback | null = null;

  let currentError: CurrentErrorEvent = {
    id: '',
    message: '',
    serverDetails: null,
    sentryDetails: null,
    showUser: false,
  };

  const _resetCurrentError = (): void => {
    Object.assign(currentError, {
      id: '',
      message: '',
      serverDetails: null,
      sentryDetails: null,
      showUser: false,
    });
    movedToastTo = 0;
  };

  const _hideFeedbackWidget = async (): Promise<void> => {
    try {
      if (feedbackWidget && feedbackClass?.getWidget()) {
        // console.warn('Closing feedback widget'); //! DEBUG
        feedbackWidget.closeDialog();
        feedbackWidget.hideActor();
        feedbackClass.removeWidget(feedbackWidget);
        feedbackWidget = null;
      }
    } catch (error) {
      console.error('Error hiding feedback widget:', error); //! DEBUG
    }
  };

  const newShowToast = async (
    id: string,
    message: string,
    isSuccess: boolean,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null
  ): Promise<void> => {
    // Checking if we already have an existing toast
    toastController.getTop().then((t) => {
      if (t) {
        if (t.id === id || t.message === message) {
          t.dismiss();
        } else {
          movedToastTo =
            document.getElementById(`${t.id}`)?.shadowRoot?.querySelector('.toast-wrapper')?.getBoundingClientRect()
              .height || movedToastTo;
        }
      }
    });

    let toastButtons: ToastButton[] = [
      {
        text: '',
        icon: closeCircleOutline,
        role: 'close',
        side: 'end',
        handler: async () => {
          await toast.dismiss();
          // console.warn('Removing toast - 1'); //! DEBUG
          _hideFeedbackWidget();
        },
      },
      {
        text: '',
        icon: copyOutline,
        role: 'copy',
        side: 'end',
        handler: async () => {
          const string = `[${JSON.stringify(sentryDetails, null, 2)},\n${JSON.stringify(serverDetails, null, 2)}]`;
          if (isAnyMobile) {
            await nativeClipboard.write({ string });
          } else {
            navigator.clipboard.writeText(string);
          }

          await toast.dismiss();
          // console.warn('Removing toast - 1.5'); //! DEBUG
          _hideFeedbackWidget();
        },
      },
    ];

    if (!isDev) {
      toastButtons = toastButtons.pop() as ToastButton[];
    }

    const toast = await toastController.create({
      id: id,
      mode: 'md',
      message: message,
      duration: isSuccess ? 3000 : 0,
      animated: true,
      position: 'top',
      icon: isSuccess ? checkmarkOutline : bugOutline,
      cssClass: ['custom_toast', isSuccess ? 'success' : 'fail'],
      buttons: toastButtons,
    });

    toast.addEventListener('ionToastWillPresent', async () => {
      if (movedToastTo) {
        toast.style.marginTop = `${movedToastTo + 10}px`;
      }
    });
    toast.addEventListener('ionToastWillDismiss', _resetCurrentError);

    await toast.present();
  };

  const _handleUnauthorized = async (): Promise<void> => {
    // console.error('Unauthorized'); //! DEBUG
    const tokenHelper = useTokenHelper();
    await tokenHelper.getNewToken(true);
    return;
  };

  const _handleAccessDenied = async (): Promise<void> => {
    // console.error('Access denied'); //! DEBUG
    networkStore.isNetworkAvailable = false;
    return;
  };

  const _handleAccessDeniedForUser = async (): Promise<void> => {
    // console.error('Access denied for user'); //! DEBUG
    networkStore.isNetworkAvailable = false;
    return;
  };

  const _handleInvalidAcceptPolicy = async (): Promise<void> => {
    // console.error('Invalid accept policy'); //! DEBUG
    appStore.loading = false;
    networkStore.loading = false;
    messengerStore.loading = false;

    await useRequirementsHelper().check(RequirementsEnum.UsageRules);
    return;
  };

  const _showErrorEvent = async (error: CurrentErrorEvent): Promise<void> => {
    const eType = error.serverDetails?.errorType;
    const eStatus = error.serverDetails?.statusCode;

    let message: string = error.message;
    if (eType === ResponseErrorTypeEnum.UnknownError && !message) {
      const sentryMsg = error.sentryDetails?.message as any;
      if (sentryMsg) {
        const status = sentryMsg.status ? `status: ${sentryMsg.status}` : '';
        const statusText = sentryMsg.statusText ? `error: ${sentryMsg.statusText}` : '';
        const eventId = `event_id: ${error.sentryDetails?.event_id}`;
        message = `${status}, \n${statusText}, \n${eventId}`;
      } else {
        message = 'Unknown error';
      }
    }

    // Checking by error type
    switch (eType) {
      case ResponseErrorTypeEnum.Unauthorized:
        await _handleUnauthorized();
        break;
      case ResponseErrorTypeEnum.AccessDenied:
        await _handleAccessDenied();
        break;
      case ResponseErrorTypeEnum.AccessDeniedForUser:
        await _handleAccessDeniedForUser();
        break;
      case ResponseErrorTypeEnum.InvalidAcceptPolicy:
        await _handleInvalidAcceptPolicy();
        break;
      case ResponseErrorTypeEnum.BadRequest:
      case ResponseErrorTypeEnum.ApiNotValidModel:
      case ResponseErrorTypeEnum.NotFound:
      case ResponseErrorTypeEnum.InternalServerError:
      case ResponseErrorTypeEnum.BadGateway:
      case ResponseErrorTypeEnum.ServiceUnavailable:
      case ResponseErrorTypeEnum.GatewayTimeout:
        await newShowToast(error.id, message, false, error.sentryDetails, error.serverDetails);
        break;
    }

    // Checking by error status code
    switch (eStatus) {
      case 400:
      case 404:
      case 500:
      case 502:
      case 503:
      case 504:
        await newShowToast(error.id, message, false, error.sentryDetails, error.serverDetails);
        break;
    }

    // console.error('Unknown error:', error); //! DEBUG
    return;
  };

  const handleError = (
    showUser: boolean,
    error: AxiosError<ResponseErrorModel> | undefined | any, // TODO: Change to strict type AxiosError<ResponseErrorModel> | undefined
    message?: string
  ): ResponseErrorTypeEnum | string => {
    console.log('≥≥≥Error:', error); //! DEBUG
    console.log('≥≥≥Message:', message); //! DEBUG

    if (error && 'response' in error && error.response) {
      currentError = {
        id: '',
        message: error.response.data.errorMessages[0].errors[0] || '',
        serverDetails: {
          statusCode: error.response.status || 500,
          traceId: error.response.data.traceId || 'unknown traceId',
          errorType: error.response.data.errorType || ResponseErrorTypeEnum.UnknownError,
          errorMessages: error.response.data.errorMessages || [],
        },
        sentryDetails: null,
        showUser: showUser,
      };

      SentrySibling.captureException(error);
      return error.response.data.errorType || ResponseErrorTypeEnum.UnknownError;
    } else {
      currentError = {
        id: '',
        message: message || '',
        serverDetails: {
          statusCode: 500,
          traceId: 'unknown traceId',
          errorType: ResponseErrorTypeEnum.UnknownError,
          errorMessages: [],
        },
        sentryDetails: null,
        showUser: showUser,
      };

      SentrySibling.captureException(error);
      return ResponseErrorTypeEnum.UnknownError;
    }
  };

  const setSentryEvent = async (event: ErrorEvent): Promise<void> => {
    currentError.id = event?.event_id || 'unknown event_id';
    currentError.sentryDetails = event;

    if (currentError.showUser) {
      await _showErrorEvent(currentError);
      if (feedbackWidget) {
        feedbackWidget.showActor();
        // feedbackWidget.openDialog();
      }
    }
  };

  const setFeedbackWidget = (feedback: Feedback): void => {
    if (!currentError.showUser) {
      return;
    }

    if (!feedbackClass) {
      feedbackClass = feedback;
    }

    if (!feedbackWidget) {
      feedbackWidget = feedbackClass.createWidget();
    }
  };

  const handleSuccessfulSubmit = async (): Promise<void> => {
    // console.warn('Removing toast - 2'); //! DEBUG
    _hideFeedbackWidget();
    await newShowToast(
      currentError.id,
      'We received your report and already working on it!', //TODO: Translate message t('apiErrors.reportReceived')
      true,
      currentError.sentryDetails,
      currentError.serverDetails
    );
  };

  instance = {
    handleError,
    setSentryEvent,
    setFeedbackWidget,
    handleSuccessfulSubmit,
    newShowToast,
  };

  return instance;
}
