<template>
  <div v-if="withHeader" class="card-milestone-header">
    <ion-list-header class="custom-list-header" mode="md">
      {{ $t('taskManagement.milestones.title') }}
    </ion-list-header>
  </div>
  <div v-if="milestone" class="card-milestone" @click.stop="openMilestonesMenu($event)">
    <ion-label :title="milestone.title"
      >{{ milestone.title }}
      <p v-if="withDates">
        <task-management-milestone-dates :milestone-id="milestone.id" />
      </p>
    </ion-label>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel, IonListHeader } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { TaskManagementTaskModalMenuItemActionEnum } from '@/@enums';
import type { TaskManagementMilestoneModel, TaskManagementTaskModel } from '@/@types';
import { TaskManagementMilestoneDates } from '@/components';
import { useTaskManagementHelper } from '@/helpers';
import { useProjectsStore } from '@/store';

const props = defineProps({
  milestoneId: {
    type: Number,
    required: true,
  },
  withHeader: {
    type: Boolean,
    required: false,
  },
  withDates: {
    type: Boolean,
    default: false,
  },
  taskData: {
    type: Object as PropType<TaskManagementTaskModel>,
    required: true,
  },
});
const projectsStore = useProjectsStore();
const taskManagementHelper = useTaskManagementHelper();
const openMilestonesMenu = async (ev: Event) => {
  await taskManagementHelper.menuSelectionHandling(
    ev,
    TaskManagementTaskModalMenuItemActionEnum.Milestone,
    props.taskData,
    props.taskData.projectId
  );
};

const milestone: ComputedRef<TaskManagementMilestoneModel | undefined> = computed(() =>
  projectsStore.getMilestoneById(props.milestoneId)
);
</script>

<style scoped lang="scss">
.card-milestone {
  color: var(--ion-color-dark);
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  ion-label {
    overflow: hidden;
    white-space: nowrap;
  }
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
</style>
