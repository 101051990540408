import { homeOutline, starOutline } from 'ionicons/icons';
import { find } from 'lodash';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { AppCardsActionEnum } from '@/@enums';
import type { AppActionButton, NetworkEntity } from '@/@types';
import { useI18n } from '@/i18n';
import { useNetworkStore } from '@/store';

interface INetworks {
  getMainNetworkAction(networkData: NetworkEntity): {
    left: AppActionButton;
    right: AppActionButton;
  };
  getNetworkStatus(network: NetworkEntity): string;
  getNetworkLogoUrl(networkId: string): string | null;
}

export function useNetworksHelper(): INetworks {
  const getMainNetworkAction = (networkData: NetworkEntity): { left: AppActionButton; right: AppActionButton } => {
    const { t } = useI18n();
    const networkStore = useNetworkStore();
    const currentNetworkId: ComputedRef<string> = computed(() => networkStore.getNetwork.id);

    const actionData: { left: AppActionButton; right: AppActionButton } = {
      left: {
        title: '',
        type: 'main',
        action: AppCardsActionEnum.None,
        icon: '',
      },
      right: {
        title: '',
        type: 'main',
        action: AppCardsActionEnum.None,
        icon: '',
      },
    };

    actionData.right.title =
      currentNetworkId.value === networkData.id ? t('network.chooseModal.currentNetwork') : t('open');
    actionData.right.type = currentNetworkId.value === networkData.id ? 'secondary' : 'main';
    actionData.right.action =
      currentNetworkId.value === networkData.id ? AppCardsActionEnum.None : AppCardsActionEnum.ChangeNetwork;

    return actionData;
  };

  const getNetworkStatus = (network: NetworkEntity): string => {
    const icons = {
      home: homeOutline,
      star: starOutline,
    };
    if (network?.isHome) {
      return icons.home;
    }

    if (network?.isOfficial) {
      return icons.star;
    }
    return '';
  };

  const getNetworkLogoUrl = (networkId: string): string | null => {
    const networkStore = useNetworkStore();
    const network = find(networkStore.data, (n) => n.id === networkId);

    if (network) {
      if (network.logoMobile?.length) {
        return network.logoMobile;
      } else if (network.favicon?.length) {
        return network.favicon;
      } else if (network.logo?.length) {
        return network.logo;
      }
    }

    return null;
  };

  return {
    getMainNetworkAction,
    getNetworkStatus,
    getNetworkLogoUrl,
  };
}
