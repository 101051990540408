import { alertController } from '@ionic/vue';
import type {
  Room,
  Track,
  AudioTrack,
  VideoTrack,
  Participant,
  LocalTrack,
  LocalTrackPublication,
  LocalParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RemoteParticipant,
} from 'twilio-video';
import { connect, createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import type { Ref } from 'vue';
import { reactive, ref } from 'vue';

import { showToast } from '@/helpers/helper';
import { useI18n } from '@/i18n';
import { useAppStore, useMeetStore } from '@/store';

interface IUseMeets {
  localMedias: HTMLMediaElement[];
  remoteMedias: Map<number, HTMLMediaElement[]>;
  participantCount: Ref<number>;
  checkDevices(roomId: string): Promise<boolean>;
  connectToRoom(withVideo: boolean, roomId: string): Promise<boolean>;
  connectToDevices(roomId: string): Promise<void>;
  disconnectFromRoom(roomId?: string): Promise<void>;
  changeAudio(isEnabled: boolean): Promise<void>;
  changeVideo(isEnabled: boolean): Promise<void>;
}

function useMeets(): IUseMeets {
  const { t } = useI18n();
  let localMedias = reactive<HTMLMediaElement[]>([]);
  const remoteMedias = reactive<Map<number, HTMLMediaElement[]>>(new Map<number, HTMLMediaElement[]>());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let dominantSpeaker: RemoteParticipant;
  let activeRoom: Room | null = null;
  let tracks: (LocalTrack | MediaStreamTrack)[] = [];
  let localUserId = 0;
  const participantCount = ref<number>(0);

  const _createAudioTrack = async (tracks: (LocalTrack | MediaStreamTrack)[], roomId: string) => {
    try {
      const audioTrack = await createLocalAudioTrack();
      tracks.push(audioTrack);
      useMeetStore().$patch((state) => {
        state.rooms[roomId].isAudioAllowed = true;
      });
    } catch (error: any) {
      const domExc = error as DOMException;
      if (domExc.name === 'NotAllowedError') {
        useMeetStore().addError('audioTrack', 'NotAllowedError');
      } else {
        console.error(`Unable to audio: ${error}`);
      }
    }
  };

  const _createVideoTrack = async (tracks: (LocalTrack | MediaStreamTrack)[], roomId: string) => {
    try {
      const videoTrack = await createLocalVideoTrack({
        frameRate: 24,
        height: 720,
        width: 1280,
      });
      tracks.push(videoTrack);
      useMeetStore().$patch((state) => {
        state.rooms[roomId].isVideoAllowed = true;
      });
    } catch (error: any) {
      const domExc = error as DOMException;
      if (domExc.name === 'NotFoundError') {
        useMeetStore().addError('videoTrack', 'NotFoundError');
      } else {
        console.error(`Unable to audio: ${error}`);
      }
    }
  };
  const changeAudio = async (isEnabled: boolean): Promise<void> => {
    activeRoom?.localParticipant.audioTracks.forEach((publication) => publication.track.enable(isEnabled));
  };

  const changeVideo = async (isEnabled: boolean): Promise<void> => {
    activeRoom?.localParticipant.videoTracks.forEach((publication) => publication.track.enable(isEnabled));
  };

  const checkDevices = async (roomId: string): Promise<boolean> => {
    const meetStore = useMeetStore();
    meetStore.clearErrors();
    tracks = [];

    //по умолчанию всегда создаем аудио и видео-трек
    await _createAudioTrack(tracks, roomId);
    await _createVideoTrack(tracks, roomId);

    return tracks.length > 0;
  };

  const connectToRoom = async (withVideo: boolean, roomId: string): Promise<boolean> => {
    const appStore = useAppStore();
    const meetStore = useMeetStore();

    if (tracks.length === 0) {
      await checkDevices(roomId);
    }

    if (meetStore.deviceNotAllowed(roomId)) {
      const toast = await alertController.create({
        header: t('meet.meetRoom.deviceBlockedTitle'),
        message: t('meet.meetRoom.deviceBlockedMessage'),
      });
      await toast.present();
    }

    try {
      await meetStore.getToken(appStore.userId?.toString() ?? '', roomId);

      const currentCallToken = meetStore.callToken(roomId);

      activeRoom = await connect(currentCallToken, {
        name: roomId,
        tracks,
        dominantSpeaker: true,
      });
    } catch (error: any) {
      console.error(`Unable to connect to Room: ${error}`);
    } finally {
      if (activeRoom) {
        meetStore.$patch((state) => {
          state.rooms[roomId].isRoomActive = true;
        });

        if (withVideo) {
          await changeVideo(true);
        } else {
          await changeVideo(false);
        }
        localUserId = appStore.userId ?? 0;
        _initialize(activeRoom.participants, roomId);

        registerLocalEvents(activeRoom.localParticipant, roomId);

        _registerRoomEvents(roomId);

        participantCount.value = activeRoom.participants.size;
      }
    }

    return activeRoom !== null;
  };

  const connectToDevices = async (roomId: string): Promise<void> => {
    const foundDevices = await checkDevices(roomId);
    if (activeRoom && foundDevices) {
      await activeRoom.localParticipant.publishTracks(tracks);
      registerLocalEvents(activeRoom.localParticipant, roomId);
    }
  };
  const disconnectFromRoom = async (roomId?: string): Promise<void> => {
    localMedias = [];

    if (remoteMedias.size > 0) {
      remoteMedias.clear();
    }

    if (activeRoom !== null) {
      participantCount.value = 0;
      activeRoom.disconnect();
      activeRoom = null;
    }

    const meetStore = useMeetStore();

    const currentRoomId = roomId || meetStore.getCurrentRoomId;

    const chain = meetStore.getChain(currentRoomId);

    meetStore.$patch({
      isCallFromUserPage: false,
      withVideo: false,
    });

    const activeParticipants = chain ? await meetStore.getActiveParticipants(chain.chainId) : undefined;

    /** комнату можно удалить если в ней не осталось участников в групповом звонке
     * либо если завершен звонок тет-а-тет */
    if (activeParticipants?.length === 0 || !meetStore.isGroupCall()) {
      meetStore.deleteRoom(currentRoomId);
      meetStore.$patch({
        currentRoomId: '',
      });
    } else {
      meetStore.updateRoom(currentRoomId, {
        isRoomActive: false,
        isCalling: false,
        withVideo: false,
        callUserId: null,
        showActiveCallButton: activeParticipants && activeParticipants.length > 0,
      });
    }
    if (currentRoomId !== '') {
      await meetStore.reject(currentRoomId);
    }
  };

  const _registerRoomEvents = (roomId: string) => {
    if (activeRoom !== null) {
      activeRoom
        .on('disconnected', (room: Room) =>
          room.localParticipant.tracks.forEach((publication: LocalTrackPublication) =>
            _detachLocalTrack(publication.track)
          )
        )
        .on('participantConnected', async (participant: RemoteParticipant) => await _add(participant, roomId))
        .on('participantDisconnected', async (participant: RemoteParticipant) => await _remove(participant, roomId))
        .on('dominantSpeakerChanged', (dominantSpeaker: RemoteParticipant) => _loudest(dominantSpeaker));
    }
  };

  const _initialize = (items: Map<Participant.SID, RemoteParticipant>, roomId: string) => {
    if (items) {
      items.forEach((participant) => _registerParticipantEvents(participant, roomId));
    }
  };

  const _add = async (participant: RemoteParticipant, roomId: string) => {
    if (participant) {
      useMeetStore().addParticipant(+participant.identity, roomId);
      const user = useMeetStore().getUser(+participant.identity, roomId);
      _registerParticipantEvents(participant, roomId);

      participantCount.value += 1;

      await showToast(t('meet.meetRoom.userConnected', { user: user?.fullName }), true);
    }
  };

  const _remove = async (participant: RemoteParticipant, roomId: string) => {
    if (participant) {
      useMeetStore().removeParticipant(+participant.identity, roomId);
      const user = useMeetStore().getUser(+participant.identity, roomId);

      participantCount.value -= 1;

      await showToast(t('meet.meetRoom.userDisconnected', { user: user?.fullName }), true);
    }
  };

  const _loudest = (participant: RemoteParticipant) => {
    dominantSpeaker = participant;
  };

  const _registerParticipantEvents = (participant: RemoteParticipant, roomId: string) => {
    if (participant) {
      useMeetStore().addParticipant(+participant.identity, roomId);
      participant.tracks.forEach((publication) => _subscribeRemote(+participant.identity, publication));
      participant.on('trackPublished', (publication) => _subscribeRemote(+participant.identity, publication));
      participant.on('trackUnpublished', (publication?: RemoteTrackPublication) => {
        if (publication?.track) {
          _detachRemoteTrack(+participant.identity, publication.track);
        }
      });
    }
  };

  const registerLocalEvents = (participant: LocalParticipant, roomId: string) => {
    if (participant) {
      useMeetStore().addParticipant(+participant.identity, roomId);

      participant.tracks.forEach((publication) => _subscribeLocal(publication));
      participant.on('trackPublished', (publication) => {
        _subscribeLocal(publication);
      });
      participant.on('trackUnpublished', (publication?: LocalTrackPublication) => {
        if (publication?.track) {
          _detachLocalTrack(publication.track);
        }
      });
    }
  };

  function _subscribeRemote(identity: number, publication: RemoteTrackPublication | any) {
    if (publication?.on) {
      publication.on('subscribed', (track: RemoteTrack) => _attachRemoteTrack(identity, track));
      publication.on('unsubscribed', (track: RemoteTrack) => _detachRemoteTrack(identity, track));
    }
  }

  function _subscribeLocal(publication: LocalTrackPublication | any) {
    if (publication?.on) {
      _attachLocalTrack(publication.track);
      publication.on('subscribed', (track: LocalTrack) => _attachLocalTrack(track));
      publication.on('unsubscribed', (track: LocalTrack) => _detachLocalTrack(track));
    }
  }

  function _attachLocalTrack(track: LocalTrack) {
    if (_isAttachable(track)) {
      track.on('enabled', (item: RemoteTrack) => {
        console.log('trackSwitchedOn', localUserId, item.sid, item.kind, item.isEnabled);
        if (item.kind === 'audio') {
          useMeetStore().userVoiceMute(localUserId, true);
        }
        if (item.kind === 'video') {
          useMeetStore().userVideoMute(localUserId, true);
        }
      });
      track.on('disabled', (item: RemoteTrack) => {
        console.log('trackSwitchedOff', localUserId, item.sid, item.kind, item.isEnabled);
        if (item.kind === 'audio') {
          useMeetStore().userVoiceMute(localUserId, false);
        }
        if (item.kind === 'video') {
          useMeetStore().userVideoMute(localUserId, false);
        }
      });

      const element = track.attach();
      element.id = track.id;
      localMedias.push(element);

      if (track.kind === 'audio') {
        useMeetStore().userVoiceMute(localUserId, track.isEnabled);
      }
      if (track.kind === 'video') {
        useMeetStore().userVideoMute(localUserId, track.isEnabled);
      }
    }
  }

  function _attachRemoteTrack(identity: number, track: RemoteTrack) {
    if (_isAttachable(track)) {
      track.on('enabled', (item: RemoteTrack) => {
        console.log('trackSwitchedOn', identity, item.sid, item.kind, item.isEnabled);
        if (item.kind === 'audio') {
          useMeetStore().userVoiceMute(identity, true);
        }
        if (item.kind === 'video') {
          useMeetStore().userVideoMute(identity, true);
        }
      });
      track.on('disabled', (item: RemoteTrack) => {
        console.log('trackSwitchedOff', identity, item.sid, item.kind, item.isEnabled);
        if (item.kind === 'audio') {
          useMeetStore().userVoiceMute(identity, false);
        }
        if (item.kind === 'video') {
          useMeetStore().userVideoMute(identity, false);
        }
      });

      const element = track.attach();
      element.id = track.sid;
      const userTracks = remoteMedias.get(identity);
      if (userTracks !== undefined) {
        userTracks.push(element);
        remoteMedias.set(identity, userTracks);
      } else {
        remoteMedias.set(identity, [element]);
      }

      if (track.kind === 'audio') {
        useMeetStore().userVoiceMute(identity, track.isEnabled);
      }
      if (track.kind === 'video') {
        useMeetStore().userVideoMute(identity, track.isEnabled);
      }
    }
  }

  function _detachLocalTrack(track: LocalTrack) {
    if (_isDetachable(track)) {
      track.stop();
      track.detach().forEach((el) => {
        //el.parentElement?.remove()
        el.remove();
      });
    }
  }

  function _detachRemoteTrack(identity: number, track: RemoteTrack) {
    if (_isDetachable(track)) {
      track.detach().forEach((el) => {
        //el.parentElement?.remove()
        el.remove();
      });
      const userTracks = remoteMedias.get(identity);

      if (userTracks !== undefined) {
        remoteMedias.delete(identity);
      }
    }
  }

  function _isAttachable(track: Track): track is AudioTrack | VideoTrack {
    return !!track && ((track as AudioTrack).attach !== undefined || (track as VideoTrack).attach !== undefined);
  }

  function _isDetachable(track: Track): track is AudioTrack | VideoTrack {
    return !!track && ((track as AudioTrack).detach !== undefined || (track as VideoTrack).detach !== undefined);
  }

  return {
    localMedias,
    remoteMedias,
    participantCount,
    checkDevices,
    connectToRoom,
    connectToDevices,
    disconnectFromRoom,
    changeAudio,
    changeVideo,
  };
}

let _appLocal: IUseMeets | undefined;

const getApp = () => {
  if (_appLocal === undefined) {
    _appLocal = useMeets();
  }
  return _appLocal;
};

export const appMeets = getApp();
