<template>
  <div class="wiki">
    <div v-for="(item, index) in wikiData" :key="`wiki_${index}`" class="wiki-block" @click.stop="openWiki(item.id)">
      <div class="icon">
        <icons-provider
          :name="'wiki'"
          :icon-props="{
            width: '40',
            height: '40',
          }"
        />
      </div>
      <div class="info">
        <ion-label class="title">{{ item.name }}</ion-label>
        <ion-text class="text" v-html="getInfo(item)" />
      </div>

      <ion-button
        v-if="status !== FileStatusEnum.Loading"
        size="default"
        fill="clear"
        class="download-button"
        @click.stop="downloadWiki(item)"
      >
        <ion-icon slot="icon-only" :icon="icons.download" />
      </ion-button>
      <ion-button
        v-if="status === FileStatusEnum.Loading"
        size="default"
        fill="clear"
        class="download-button"
        @click.stop="stopDownload()"
      >
        <ion-icon slot="icon-only" :icon="icons.close" />
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonText, IonLabel, IonButton, IonIcon } from '@ionic/vue';
import { closeOutline, downloadOutline } from 'ionicons/icons';
import type { PropType, Ref } from 'vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { WikiVersionEnum, FileStatusEnum, DocumentExtensionEnum } from '@/@enums';
import type { WikiModel } from '@/@types';
import { IconsProvider } from '@/components';
import { filesHybrid, showToast, isBlob, useErrors } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useWikiStore } from '@/store';

// Icons
const icons = {
  close: closeOutline,
  download: downloadOutline,
};

defineProps({
  wikiData: {
    type: Object as PropType<WikiModel[]>,
    required: true,
  },
});
const { t } = useI18n();
const router = useRouter();
const { handleError } = useErrors();

// Store
const wikiStore = useWikiStore();

// Variables
const emit = defineEmits(['onLoading']);
const status: Ref<FileStatusEnum> = ref<FileStatusEnum>(FileStatusEnum.Init);

// Actions
const getInfo = (item: WikiModel) => {
  if (item.version === WikiVersionEnum.V1) {
    return item.bodyHtml;
  } else if (item.version === WikiVersionEnum.V2) {
    const info = [] as string[];
    item.wikiContent.body.forEach((element) => {
      info.push(element.name);
    });
    return info;
  } else {
    return '';
  }
};

const openWiki = async (wikiId: number) => {
  await router.push({
    name: ROUTES_NAME.WIKI_BY_ID,
    params: { id: wikiId },
  });
};

const downloadWiki = async (item: WikiModel) => {
  if (status.value === FileStatusEnum.Loading) {
    return;
  }

  status.value = FileStatusEnum.Loading;
  emit('onLoading', true, item.id);

  const response = await wikiStore.downloadWiki(item.id, DocumentExtensionEnum.PDF);
  if (isBlob(response)) {
    status.value = await filesHybrid.downloadWiki(item, response as Blob);
    status.value === FileStatusEnum.Success
      ? await showToast(t('files.successDownloaded'), true)
      : handleError(true, undefined, 'Error while downloading wiki');
  }

  status.value === FileStatusEnum.Success
    ? await showToast(t('files.successDownloaded'), true)
    : handleError(true, undefined, 'Error while downloading wiki');
};

const stopDownload = async () => {
  status.value = FileStatusEnum.Success;
  await showToast(t('files.downloadStopped'), true);
};
</script>
<style scoped lang="scss">
ion-button ion-icon {
  color: var(--ion-color-medium);
}
.download-button {
  margin-left: auto;
  margin-right: 0;
}
.wiki {
  margin-top: 1rem;
}
.wiki-block {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  padding-right: 0;
  border: 1px solid var(--ion-color-light-custom);
  border-radius: 4px;
}
.wiki-block:not(:last-child) {
  margin-bottom: 0.5rem;
}
.wiki-block .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 44px;
  flex-shrink: 0;
}
.wiki-block .icon ion-icon {
  font-size: 2.5rem;
  width: 100%;
}
.wiki-block .info {
  margin-left: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.wiki-block ion-badge {
  border-radius: 4px;
  font-size: 0.7rem;
  width: 44px;
}
.wiki-block:hover {
  cursor: pointer;
  opacity: 0.7;
}
.wiki-block .info ion-text {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--ion-color-medium);
}
</style>
