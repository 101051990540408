<template>
  <div class="badge">
    <ion-text class="post-title" color="medium">{{ $t('conversations.badge.title') }}</ion-text>

    <div class="badge-block">
      <div class="icon">
        <badge-item :badge-id="badgeData.id" :type="BadgeDisplayType.Post" />
      </div>
      <div class="info">
        <ion-label class="assigned-to">{{ $t('conversations.badge.assignedTo') }}</ion-label>
        <ion-item v-for="item in badgeData.assignedTo" :key="`assigned_${item.id}`" mode="md" lines="none" class="text">
          <user-list-item :user="item" />
        </ion-item>
        <div class="post-text">
          <conversations-text :text-data="text" :conversations-flag="conversationsFlag" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonText, IonLabel, IonItem } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import type { ConversationsFlagEnum } from '@/@enums';
import { BadgeDisplayType } from '@/@enums';
import type { PostAttachedBadgeModel } from '@/@types';
import { ConversationsText, UserListItem, BadgeItem } from '@/components';

const props = defineProps({
  badgeData: {
    type: Object as PropType<PostAttachedBadgeModel>,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  conversationsFlag: {
    type: String as PropType<ConversationsFlagEnum>,
    required: true,
  },
});

const badgeData: ComputedRef<PostAttachedBadgeModel> = computed(() => props.badgeData);
</script>
<style scoped lang="scss">
.badge {
  margin-top: app-padding(lg);
  &-block {
    position: relative;
    display: flex;
    align-items: start;
    margin-top: app-padding(lg);
    .icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 128px;
      flex-shrink: 0;
      transition: all 0.5s linear;
    }
  }
}

.badge-block .info {
  margin-left: app-padding(lg);
  height: 100%;
  .assigned-to {
    font-weight: bold;
  }
  ion-item {
    --background: transparent;
    margin-top: app-padding(md);
    --inner-padding-start: 0;
    --padding-start: 0;
  }
  .post-text {
    margin-top: app-padding(md);
  }
}

@include respond-to-max-width(sm) {
  .badge-block {
    .icon {
      width: 64px;
    }
  }
}
</style>
