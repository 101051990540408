<template>
  <docs-item-view
    :id="file.id"
    :view-type="viewType"
    :document-type="DocumentTypeEnum.File"
    :name="fileName"
    :created-at="file.createdAt"
    :author="fileAuthor"
    :group="fileGroupTitle"
    :icon="fileIcon"
    :status="status"
    :file="file"
    :size="fileSize"
    @onDownloadStop="stopDownload"
    @onMenuOpen="openFileMenu"
    @onItemClick="openFile"
  />
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { documentOutline, imageOutline, videocamOutline } from 'ionicons/icons';
import type { ComputedRef, PropType, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { DocsViewTypeEnum } from '@/@enums';
import { DocumentTypeEnum, FileMenuActionEnum, FileStatusEnum, ShareArchiveLinkType, UploadFileTypes } from '@/@enums';
import type { DocEntity, FileModel } from '@/@types';
import { DocsItemView } from '@/components';
import {
  componentDocsMoveFile,
  componentDocsUploadFile,
  componentTitleChange,
  docBrowserContextMenu,
  filesHybrid,
  isNativeMobile,
  showToast,
  useOfficeHelper,
  shareFileHelper,
  isAnyMobile,
  docBrowserContextMenuSheet,
  componentShareArchiveLink,
  useErrors,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { useDocStore } from '@/store';

// Props
const props = defineProps({
  file: {
    type: Object as PropType<FileModel>,
    required: true,
  },
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
});

// Icons
const icons = {
  video: videocamOutline,
  image: imageOutline,
  document: documentOutline,
};

// Store
const docStore = useDocStore();
const officeHelper = useOfficeHelper();

// Helpers
const { t } = useI18n();
const { handleError } = useErrors();

// Refs
const status: Ref<FileStatusEnum> = ref<FileStatusEnum>(FileStatusEnum.Init);
const isOffice = officeHelper.isOfficeFile(props.file?.type);
const fileExtensionsNotForPdfPreview: Ref<string[]> = ref(['mp3', 'tiff', 'pbm', 'tga', 'mp4']);

// Computed
const isAttachment: ComputedRef<boolean> = computed(() => docStore.isAttachmentModal);
const selectedDocs: ComputedRef<DocEntity[]> = computed(() => docStore.selectedDocs);
const fileGroupTitle: ComputedRef<string> = computed(() =>
  props.file.group ? props.file.group.title : t('network.allNetwork')
);
const fileName: ComputedRef<string> = computed(() => props.file.name + '.' + props.file.type);
const fileAuthor: ComputedRef<string> = computed(() => (props.file.author ? props.file.author?.fullName : ''));
const fileSize: ComputedRef<string> = computed(() => {
  if (props.file.size) {
    let size;
    size = props.file.size / 1014;
    if (size >= 1024) {
      size = size * 0.001;
      size = size.toFixed(1);
      return size + ' MB';
    } else {
      size = size.toFixed(1);
      return size + ' KB';
    }
  }
  return '';
});
const fileIcon: ComputedRef<string> = computed(() => {
  if (props.file.mimeType.startsWith('video')) {
    return icons.video;
  } else if (props.file.mimeType.startsWith('image')) {
    return icons.image;
  } else {
    return icons.document;
  }
});

// Actions
const isImage = async (): Promise<boolean> => {
  status.value = FileStatusEnum.Loading;

  if (!props.file.mimeType.startsWith('image')) {
    status.value = FileStatusEnum.Error;

    return false;
  }

  const isSupported = await filesHybrid.isImageFormatSupported(props.file.image?.url ?? '');

  if (!isSupported) {
    status.value = FileStatusEnum.Error;
    handleError(true, undefined, t('files.imageNotSupported'));
    return false;
  }

  status.value = FileStatusEnum.Success;
  return true;
};

const _handleAttachment = (): void => {
  if (!selectedDocs.value.some((n) => n.data.id === props.file.id)) {
    docStore.$patch({
      selectedDocs: [...selectedDocs.value, { documentType: DocumentTypeEnum.File, data: props.file }],
    });
  } else {
    docStore.$patch({
      selectedDocs: selectedDocs.value.filter((n) => n.data.id !== props.file.id),
    });
  }
};

const _handleVideo = (): void => {
  emit('onVideoView', props.file);
};

const _handleImage = (): void => {
  emit('onImageView', props.file);
};

const _handleOffice = async (): Promise<void> => {
  const isOpened = await officeHelper.openOfficeView(
    props.file.id.toString(),
    false,
    {
      title: props.file?.name,
      publishedBy: props.file?.author,
      group: props.file?.group,
      type: props.file?.type,
    },
    true,
    false,
    props.file?.group?.id ?? null,
    props.file?.parentFolderId ?? null
  );

  if (!isOpened) {
    status.value = FileStatusEnum.Loading;
    status.value = await filesHybrid.openFile(props.file);
  }
};

const _handlePdf = async (): Promise<void> => {
  status.value = FileStatusEnum.Loading;
  status.value = await filesHybrid.openFile(props.file);
};

/*
TODO Wiki: add wiki action
const _handleWiki = (): void => {};
*/

const downloadFile = async (): Promise<void> => {
  status.value = FileStatusEnum.Loading;

  status.value = await filesHybrid.downloadFile(props.file);

  status.value === FileStatusEnum.Success
    ? await showToast(t('files.successDownloaded'), true)
    : handleError(true, undefined, t('files.failedDownloaded'));
};

const stopDownload = async (id: number) => {
  status.value = FileStatusEnum.Success;
  emit('onLoading', false, id);
  await showToast(t('files.downloadStopped'), true);
};

const renameFile = async (): Promise<void> => {
  status.value = FileStatusEnum.Loading;

  const result = await componentTitleChange(null, props.file.name, true, props.file?.description || '');

  if (!result.data) {
    console.error('Failed to rename file');
    status.value = FileStatusEnum.Error;
    return;
  }

  status.value = (await docStore.renameFile(props.file.id, result.data.title, result.data.text))
    ? FileStatusEnum.Success
    : FileStatusEnum.Error;

  status.value === FileStatusEnum.Success
    ? await showToast(t('files.fileSuccessRenamed'), true)
    : handleError(true, undefined, t('files.fileFailedRenamed'));
};

const moveFile = async (): Promise<void> => {
  status.value = FileStatusEnum.Loading;

  const result = await componentDocsMoveFile(null);

  if (!result.data) {
    console.error('Failed to move file');
    return;
  }

  status.value = (await docStore.moveFile(result.data.folderId, result.data.groupId, props.file.id))
    ? FileStatusEnum.Success
    : FileStatusEnum.Error;

  status.value === FileStatusEnum.Success
    ? await showToast(t('files.fileSuccessMoved'), true)
    : handleError(true, undefined, t('files.fileFailedMoved'));
};

const deleteFile = async (): Promise<void> => {
  status.value = FileStatusEnum.Loading;

  const alert = await alertController.create({
    message: `${t('documents.popup.deleteFile')} <strong>${props.file.name}?</strong>`,
    buttons: [
      {
        text: t('no'),
        role: 'cancel',
        cssClass: 'custom-alert_buttons',
        handler: () => {
          status.value = FileStatusEnum.Success;
        },
      },
      {
        text: t('yes'),
        cssClass: 'custom-alert_buttons',
        handler: async () => {
          status.value = (await docStore.deleteFile(props.file.id)) ? FileStatusEnum.Success : FileStatusEnum.Error;
        },
      },
    ],
  });

  await alert.present();

  if (status.value !== FileStatusEnum.Loading) {
    status.value === FileStatusEnum.Success
      ? await showToast(t('files.fileSuccessDeleted'), true)
      : handleError(true, undefined, t('files.fileFailedDeleted'));
  }
};

const uploadNewVersion = async (): Promise<void> => {
  status.value = FileStatusEnum.Loading;

  status.value = (await componentDocsUploadFile(UploadFileTypes.SingleAnyFile, true, props.file, true))
    ? FileStatusEnum.Success
    : FileStatusEnum.Error;

  status.value === FileStatusEnum.Success
    ? await showToast(t('files.fileSuccessUploaded'), true)
    : handleError(true, undefined, t('files.fileFailedUploaded'));
};

//TODO: https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1410
const openFile = async (): Promise<void> => {
  try {
    if (isAttachment.value) {
      _handleAttachment();
      return;
    }

    // Video
    if (props.file.mimeType.startsWith('video')) {
      _handleVideo();
      return;
    }

    // Image
    if (await isImage()) {
      _handleImage();
      return;
    }

    // Office documents
    if (isOffice) {
      await _handleOffice();
      return;
    }

    // PDF or on Mobile - making PDFs out of files
    const forPdfOrMobile =
      props.file.type === 'pdf' || (isNativeMobile && !fileExtensionsNotForPdfPreview.value.includes(props.file.type));
    if (forPdfOrMobile) {
      await _handlePdf();
      return;
    }

    //TODO Wiki: add wiki action

    // Download
    await downloadFile();
  } catch (error) {
    status.value = FileStatusEnum.Error;
    handleError(true, error, t('errorResponse'));
  }
};

const openFileMenu = async (ev: Event) => {
  let result;
  if (isAnyMobile) {
    result = await docBrowserContextMenuSheet({
      documentType: DocumentTypeEnum.File,
      data: props.file,
    });
  } else {
    result = await docBrowserContextMenu(ev, {
      documentType: DocumentTypeEnum.File,
      data: props.file,
    });
  }

  switch (result.data) {
    case FileMenuActionEnum.Open:
      {
        status.value = FileStatusEnum.Loading;
        status.value = await filesHybrid.openFile(props.file);

        if (status.value === FileStatusEnum.Error) {
          handleError(true, undefined, t('errorResponse'));
        }
      }
      break;
    case FileMenuActionEnum.Download:
      await downloadFile();
      break;
    case FileMenuActionEnum.Rename:
      await renameFile();
      break;
    case FileMenuActionEnum.Move:
      await moveFile();
      break;

    case FileMenuActionEnum.Share:
      await shareFileHelper(props.file.id);
      break;

    case FileMenuActionEnum.ShareArchiveLink:
      await componentShareArchiveLink(props.file.id, ShareArchiveLinkType.File);
      break;

    case FileMenuActionEnum.Delete:
      await deleteFile();
      break;
    case FileMenuActionEnum.NewVersion:
      await uploadNewVersion();
      break;
  }
};

// Emits
const emit = defineEmits(['onImageView', 'onVideoView', 'onLoading']);
</script>
