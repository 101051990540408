import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/vue';
import { useCssVar } from '@vueuse/core/index';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

import {
  SendKeyEnum,
  ThemeAppEnum,
  InterfaceSizeAppEnum,
  FeedTypeEnum,
  NotificationsTypeEnum,
  TopicsFilterEnum,
  PagesBackgroundEnum,
  GroupsFilterEnum,
  UsersFilterEnum,
  PostsFilterEnum,
  DataViewMode,
  IdeaTypeEnum,
} from '@/@enums';
import type {
  ErrorMessageModel,
  ResponseAuthCoreTokenModel,
  ResponseErrorModel,
  ResponseNetworkResourcesModel,
  WebSocketModel,
  RequestAuthHomeUser,
} from '@/@types';
import { isWebMobile, isNativeMobile, getServiceUrl, useTokenHelper, useLayoutHelper } from '@/helpers';
import { DEFAULT_LOCALE, SUPPORT_LOCALES } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { $api } from '@/services';

export type AppState = {
  url: string;
  appVersion: string;
  grantType: string;
  clientSecret: string;

  code: string;
  codeValid: string;
  homeRowId: string;

  accessToken: string;
  /** @deprecated The param should not be used */
  expiresIn: string;
  validUntil: string;
  locale: string;

  sendKey: SendKeyEnum;
  interfaceSize: InterfaceSizeAppEnum;
  theme: ThemeAppEnum;
  errors: ErrorMessageModel[];
  loading: boolean;
  isWaitingForCompleteLogin: boolean;
  feedType: FeedTypeEnum;
  ideaType: IdeaTypeEnum;
  feedFilter: PostsFilterEnum;
  notificationsType: NotificationsTypeEnum;
  localNotifications: boolean;
  coreId: string;
  companyRowId: string;
  userId: number | null;
  userRowId: string;
  webSocket: string;

  appHeaderHeight: number;
  appBottomMenuHeight: number;

  companyResources: object[];
  onlineUsers: number[];
  //Домашняя страница
  homePage: { name: string; id?: number };
  appBottomMenu: boolean;
  feedPostsCount: number;

  tagsType: TopicsFilterEnum;
  isKeyboardShown: boolean;
  pagesBackground: PagesBackgroundEnum;

  groupsFilter: GroupsFilterEnum;
  groupsViewMode: DataViewMode;
  usersFilter: UsersFilterEnum;
  usersViewMode: DataViewMode;

  networksViewMode: DataViewMode;

  anyLongPressIsActive: boolean;
  showLoaderOnHeader: boolean;

  isXSWidth: boolean;
  is2XSWidth: boolean;
  isSMWidth: boolean;
  isMDWidth: boolean;
  isLGWidth: boolean;
  isXLWidth: boolean;
  is2XLWidth: boolean;
  is3XLWidth: boolean;
  is4XLWidth: boolean;
  appGridPadding: string;
  maxItemsInHeaderMenu: number;

  searchText: string;
  searchHistory: string[];

  appColors: string[];
  isMiddleButtonClicked: boolean;
};

export const getLocalUrl = (): string => `${import.meta.env.VITE_APP_URL_CORE}`;

export const useAppStore = defineStore({
  id: 'app',
  state: (): AppState =>
    ({
      url: getServiceUrl(),
      appVersion: '',
      grantType: '',
      clientSecret: '',
      code: '',
      codeValid: '',
      homeRowId: '',
      accessToken: '',
      tokenValid: '',
      expiresIn: '',
      validUntil: '',
      coreId: '',
      companyRowId: '',
      userId: null,
      userRowId: '',
      webSocket: import.meta.env.VITE_APP_URL_SIGNALR,
      errors: [],
      loading: false,
      isWaitingForCompleteLogin: false,
      feedType: FeedTypeEnum.Recommended,
      ideaType: IdeaTypeEnum.New,
      feedFilter: PostsFilterEnum.All,
      notificationsType: NotificationsTypeEnum.All,
      localNotifications: true,
      locale: SUPPORT_LOCALES.includes(window.navigator.language.substring(0, 2).toLowerCase())
        ? window.navigator.language.substring(0, 2).toLowerCase()
        : DEFAULT_LOCALE,
      sendKey: SendKeyEnum.Enter,
      interfaceSize: InterfaceSizeAppEnum.Middle,
      appHeaderHeight: 0,
      appBottomMenuHeight: 0,
      theme: window.matchMedia?.('(prefers-color-scheme: dark)').matches ? ThemeAppEnum.Dark : ThemeAppEnum.Light,
      companyResources: [],
      homePage: { name: ROUTES_NAME.FEED },
      groupsFilter: GroupsFilterEnum.ByUser,
      groupsViewMode: DataViewMode.Grid,
      usersFilter: UsersFilterEnum.UsersPage,
      usersViewMode: DataViewMode.Grid,
      networksViewMode: DataViewMode.List,
      onlineUsers: [],
      appBottomMenu: isWebMobile || isNativeMobile,
      feedPostsCount: 20,
      tagsType: TopicsFilterEnum.Popular,
      isKeyboardShown: false,
      pagesBackground: PagesBackgroundEnum.CustomImage,
      anyLongPressIsActive: false,
      showLoaderOnHeader: false,

      isXSWidth: useLayoutHelper().isXSWidth(),
      is2XSWidth: useLayoutHelper().is2XSWidth(),
      isSMWidth: useLayoutHelper().isSMWidth(),
      isMDWidth: useLayoutHelper().isMDWidth(),
      isLGWidth: useLayoutHelper().isLGWidth(),
      isXLWidth: useLayoutHelper().isXLWidth(),
      is2XLWidth: useLayoutHelper().is2XLWidth(),
      is3XLWidth: useLayoutHelper().is3XLWidth(),
      is4XLWidth: useLayoutHelper().is4XLWidth(),
      appGridPadding: useCssVar('--app-grid-padding').value,
      maxItemsInHeaderMenu: 8,

      searchText: '',
      searchHistory: [],

      appColors: [],
      isMiddleButtonClicked: false,
    }) as AppState,
  getters: {
    isAuth: (state): boolean =>
      state.userId !== null &&
      state.userId > 0 &&
      state.grantType.length > 0 &&
      state.clientSecret.length > 0 &&
      state.code.length > 0 &&
      state.companyRowId.length > 0,
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getWebSocketModel(state): WebSocketModel | null {
      if (state.url === null || state.userId === null || state.webSocket.length === 0) {
        return null;
      }

      return {
        baseUrl: state.url,
        userId: state.userId,
        coreId: state.coreId,
        companyRowId: state.companyRowId,
        userRowId: state.userRowId,
        webSocket: state.webSocket,
      };
    },
    getLocalSendKey(state): SendKeyEnum {
      return state.sendKey;
    },
    getLocalInterfaceSize(state): InterfaceSizeAppEnum {
      return state.interfaceSize;
    },
    getLocalTheme(state): ThemeAppEnum {
      return state.theme;
    },
    isDarkTheme:
      () =>
      (theme: ThemeAppEnum): boolean => {
        switch (theme) {
          case ThemeAppEnum.Light:
            return false;

          case ThemeAppEnum.Dark:
            return true;

          case ThemeAppEnum.System: {
            return window.matchMedia?.('(prefers-color-scheme: dark)').matches ? true : false;
          }
        }
      },
    getLocalPagesBackground(state): PagesBackgroundEnum {
      return state.pagesBackground;
    },
    getAppLogoPath(state): string {
      const logo = !this.isDarkTheme(state.theme)
        ? import.meta.env.VITE_APP_LOGO_LIGHT
        : import.meta.env.VITE_APP_LOGO_DARK;
      return `/logo/${logo}`;
    },
    getAppLogoPathWithColor:
      () =>
      (color: ThemeAppEnum): string => {
        const appStore = useAppStore();
        const logo = !appStore.isDarkTheme(color)
          ? import.meta.env.VITE_APP_LOGO_LIGHT
          : import.meta.env.VITE_APP_LOGO_DARK;
        return `/logo/${logo}`;
      },
    getAppIconPath(state): string {
      const icon = !this.isDarkTheme(state.theme)
        ? import.meta.env.VITE_APP_ICON_LIGHT
        : import.meta.env.VITE_APP_ICON_DARK;
      return `/icon/${icon}`;
    },
    getAppIconPathWithColor:
      () =>
      (color: ThemeAppEnum): string => {
        const appStore = useAppStore();
        const icon = !appStore.isDarkTheme(color)
          ? import.meta.env.VITE_APP_ICON_DARK
          : import.meta.env.VITE_APP_ICON_LIGHT;
        return `/icon/${icon}`;
      },
    getBeginChatImagePath(): string {
      const appStore = useAppStore();
      const isSMWidth = appStore.isSMWidth;
      const image = isSMWidth ? import.meta.env.VITE_CHAT_IMAGE_WEB : import.meta.env.VITE_CHAT_IMAGE_MOBILE;
      return `/images/chat/${image}`;
    },
    getBeginChatBackgroundPath(state): string {
      const image = !this.isDarkTheme(state.theme)
        ? import.meta.env.VITE_CHAT_BACKGROUND_DARK
        : import.meta.env.VITE_CHAT_BACKGROUND_LIGHT;
      return `/images/chat/${image}`;
    },
    getAppPatternPath(state): string {
      const image = !this.isDarkTheme(state.theme)
        ? import.meta.env.VITE_APP_PATTERN_DARK
        : import.meta.env.VITE_APP_PATTERN_LIGHT;
      return `/images/${image}`;
    },
    getAppNotificationSoundPath(): string {
      return `/audio/${import.meta.env.VITE_APP_NOTIFICATION_SOUND}`;
    },
    getAppCallSoundPath(): string {
      return `/audio/${import.meta.env.VITE_APP_CALL_SOUND}`;
    },
    getLocalFeedType(state): FeedTypeEnum {
      return state.feedType;
    },
    getLocalIdeaType(state): IdeaTypeEnum {
      return state.ideaType;
    },
    isPhone(): boolean {
      const appStore = useAppStore();
      const isSMWidth = appStore.isSMWidth;
      const isNativePhone =
        Capacitor.isNativePlatform() && (isPlatform('iphone') || (isPlatform('android') && !isPlatform('tablet')));
      return isNativePhone || !isSMWidth;
    },
  },
  actions: {
    setKeyboardShown(state: boolean) {
      this.isKeyboardShown = state;
    },
    async homeCode(data: RequestAuthHomeUser): Promise<boolean> {
      this.$patch({
        errors: [],
      });
      const response = await $api.auth.homeCode(data);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthCoreTokenModel;
        const { accessToken, validUntil, coreId, companyRowId, userId, userRowId } = model.data;

        this.$patch({
          grantType: 'homeAuthorizationCode',
          clientSecret: data.clientSecret,
          code: accessToken,
          codeValid: validUntil,
          homeRowId: companyRowId,
          coreId,
          companyRowId,
          userId,
          userRowId,
        });

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async token(force = false): Promise<boolean> {
      //TO:DO #775 To think about implementation
      /* if (this.code === null) {
        this.errors = [
          {
            key: 'token',
            errors: ['user token is null'],
          },
        ];

        return false;
      }

      this.$patch({
        errors: [],
      });

      const response = await $api.auth.token({
        grantType: this.grantType,
        clientSecret: this.clientSecret,
        code: this.code,
        uid: this.homeRowId,
      } as RequestTokenUserModel);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthUserTokenModel;
        const { validUntil, accessToken } = model.data;

        this.$patch({
          errors: [],
          validUntil,
          accessToken,
        });

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false; */

      const tokenHelper = useTokenHelper();
      const dataAuth = (await tokenHelper.getNewToken(force)) as {
        url: string;
        token: string | null;
        locale: string;
      };
      return dataAuth.token !== null;
    },
    toggleTheme(theme: ThemeAppEnum) {
      switch (theme) {
        case ThemeAppEnum.Light:
          {
            this.$patch({
              theme: ThemeAppEnum.Light,
            });
            document.body.classList.remove('dark');
          }
          break;

        case ThemeAppEnum.Dark:
          {
            this.$patch({
              theme: ThemeAppEnum.Dark,
            });
            document.body.classList.add('dark');
          }
          break;

        case ThemeAppEnum.System:
          {
            const preparedTheme = window.matchMedia?.('(prefers-color-scheme: dark)').matches
              ? ThemeAppEnum.Dark
              : ThemeAppEnum.Light;
            this.$patch({
              theme: ThemeAppEnum.System,
            });
            preparedTheme === ThemeAppEnum.Dark
              ? document.body.classList.add('dark')
              : document.body.classList.remove('dark');
          }
          break;

        default:
          break;
      }
    },
    toggleBackground(background: PagesBackgroundEnum) {
      switch (background) {
        case PagesBackgroundEnum.CustomImage:
          this.$patch({
            pagesBackground: PagesBackgroundEnum.CustomImage,
          });
          break;

        case PagesBackgroundEnum.Default:
          this.$patch({
            pagesBackground: PagesBackgroundEnum.Default,
          });
          break;

        default:
          break;
      }
    },
    async getCompanyResources(): Promise<void> {
      const response = await $api.network.getResources(this.clientSecret);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkResourcesModel;
        this.companyResources = cloneDeep(model.resources);
        return model.resources;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return undefined;
    },
    usersOnline(data: number[]) {
      this.onlineUsers = data;
    },
    userConnected(userId: number) {
      const index = this.onlineUsers.findIndex((u: number) => u === userId);
      if (index < 0) {
        this.onlineUsers.push(userId);
      }
    },
    userDisconnected(userId: number) {
      this.onlineUsers = this.onlineUsers.filter((u: number) => u !== userId);
    },
    setShowLoaderOnHeader(state: boolean) {
      this.showLoaderOnHeader = state;
    },
  },
  persist: true,
});
