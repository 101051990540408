import { format } from 'date-fns';
import {
  assign,
  // unionBy,
  // find,
} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import { defineStore } from 'pinia';

import type { DocBrowserTypeEnum, DocsFilterTypeEnum, ShareArchiveLinkType } from '@/@enums';
import { DocsSortingTypeEnum, DocBrowserModeEnum, DocumentTypeEnum } from '@/@enums';
import type {
  ResponseErrorModel,
  ResponseDocsModel,
  DocEntity,
  ResponseTreeFolderModel,
  TreeFolderModel,
  GroupEntity,
  FileModel,
  FolderModel,
  ResponseDocsCreateFileModel,
  ResponseDocsCreateFolderModel,
  ResponsePostModel,
  DocsDataModel,
  DocsDataModeModel,
  ErrorMessageModel,
  DocsDataByFolderModel,
  DocsDataByGroupModel,
} from '@/@types';
import { DateHelper } from '@/helpers';
import { useI18n } from '@/i18n';
import { defaultDocsData } from '@/models/docs';
import { $api } from '@/services';
import { usePostStore } from '@/store';

const { t } = useI18n();

interface DocState {
  errors: ErrorMessageModel[];
  loading: boolean;
  selectedFolder: TreeFolderModel | null;
  selectedGroup: GroupEntity | null;
  sortingType: DocsSortingTypeEnum;
  filterType: DocsFilterTypeEnum | null;
  filterBy: string | null;
  searchText: string;
  searchEverywhere: boolean;
  browserMode: DocBrowserModeEnum;
  browserType: null | DocBrowserTypeEnum;
  selectedDocs: DocEntity[];
  isAttachmentModal: boolean;
  activeFolder: FolderModel | null;
  activeGroup: GroupEntity | null;
  docs: DocsDataModel;
  currentScreenY: number;
  mainScreenY: number;
}
export const useDocStore = defineStore({
  id: 'docs',
  state: (): DocState => ({
    errors: [],
    loading: false,
    selectedFolder: null,
    selectedGroup: null,
    sortingType: DocsSortingTypeEnum.DateDESC,
    filterType: null,
    filterBy: null,
    searchEverywhere: true,
    searchText: '',
    browserMode: DocBrowserModeEnum.All,
    browserType: null,
    selectedDocs: [],
    isAttachmentModal: false,
    activeFolder: null,
    activeGroup: null,
    docs: cloneDeep(defaultDocsData),
    currentScreenY: 0,
    mainScreenY: 0,
  }),
  getters: {
    getCategoriesForFilterByAuthor(state): any {
      const categories = [] as string[];

      const object = getCurrentResultObject(state.browserMode, state.activeGroup?.id, state.activeFolder?.id);
      if (object) {
        object.data.forEach((element) => {
          if (element.data.author) {
            const author = element.data.author.fullName;
            if (element.data.author.fullName !== undefined) {
              if (!categories.includes(author)) {
                categories.push(author);
              }
            }
          }
        });
      }

      return categories;
    },
    getCategoriesForFilterByDate(state): any {
      let categories = [] as string[];

      const object = getCurrentResultObject(state.browserMode, state.activeGroup?.id, state.activeFolder?.id);
      if (object) {
        const dates = object.data.map((obj) => format(new Date(obj.data.createdAt), 'dd.MM.yyyy'));
        categories = [...new Set(dates)].map((str) => str);
      }

      return categories;
    },
    getCategoriesForFilterByGroup(state): any {
      const categories = [] as string[];

      const object = getCurrentResultObject(state.browserMode, state.activeGroup?.id, state.activeFolder?.id);
      if (object) {
        object.data.forEach((element) => {
          const groupTitle = element.data?.group ? element.data.group.title : t('network.allNetwork');
          if (groupTitle !== undefined) {
            if (!categories.includes(groupTitle)) {
              categories.push(groupTitle);
            }
          }
        });
      }

      return categories;
    },
    getDocs: (state) => (): DocEntity[] => {
      if (state.docs.search.data.length > 0) {
        return state.docs.search.data;
      }
      if (state.activeFolder?.id) {
        const index = state.docs.folders.findIndex((n) => n.folderId === state.activeFolder?.id);
        if (~index) {
          return state.docs.folders[index].data;
        } else {
          return [];
        }
      }

      if (state.activeGroup?.id) {
        const index = state.docs.groups.findIndex((n) => n.groupId === state.activeGroup?.id);
        if (~index) {
          return state.docs.groups[index].data;
        } else {
          return [];
        }
      }

      switch (state.browserMode) {
        case DocBrowserModeEnum.All:
        case DocBrowserModeEnum.Follow:
          return state.docs.all.data;

        case DocBrowserModeEnum.Recent:
          return state.docs.recent.data;

        case DocBrowserModeEnum.Uploaded:
          return state.docs.uploaded.data;

        case DocBrowserModeEnum.Search:
          return state.docs.search.data;
      }
      return [];
    },
    getDocsLoadMoreUrl: (state) => (): string | null => {
      if (state.activeFolder?.id) {
        const index = state.docs.folders.findIndex((n) => n.folderId === state.activeFolder?.id);
        if (~index) {
          return state.docs.folders[index].loadMoreUrl;
        } else {
          return null;
        }
      }

      if (state.activeGroup?.id) {
        const index = state.docs.groups.findIndex((n) => n.groupId === state.activeGroup?.id);
        if (~index) {
          return state.docs.groups[index].loadMoreUrl;
        } else {
          return null;
        }
      }

      switch (state.browserMode) {
        case DocBrowserModeEnum.All:
        case DocBrowserModeEnum.Follow:
          return state.docs.all.loadMoreUrl;

        case DocBrowserModeEnum.Recent:
          return state.docs.recent.loadMoreUrl;

        case DocBrowserModeEnum.Uploaded:
          return state.docs.uploaded.loadMoreUrl;

        case DocBrowserModeEnum.Search:
          return state.docs.search.loadMoreUrl;
      }
      return null;
    },
    getClientY: (state) => (): number => {
      if (state.activeFolder?.id) {
        const index = state.docs.folders.findIndex((n) => n.folderId === state.activeFolder?.id);
        if (~index) {
          return state.docs.folders[index].folderScreenY;
        } else {
          return 0;
        }
      } else {
        return state.mainScreenY;
      }
    },
    getLastSearchResult: (state) => (): DocsDataModeModel => {
      const mode = state.browserMode;
      switch (mode) {
        case DocBrowserModeEnum.All:
        case DocBrowserModeEnum.Follow:
          return state.docs.all;

        case DocBrowserModeEnum.Recent:
          return state.docs.recent;

        case DocBrowserModeEnum.Uploaded:
          return state.docs.uploaded;

        case DocBrowserModeEnum.Search:
          return state.docs.search;
      }
      return state.docs.all;
    },
  },
  actions: {
    async allDocsFromGroupId(groupId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.doc.getAllDocsFromGroupId(groupId, this.browserMode);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        const index = this.docs.groups.findIndex((n) => n.groupId === groupId);

        if (~index) {
          this.docs.groups[index].data = sortDocs(this.sortingType, model.data);
          this.docs.groups[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.docs.groups.push({
            groupId: groupId,
            data: sortDocs(this.sortingType, model.data),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    async allDocsFromFolderId(folderId: number, mode: DocBrowserModeEnum): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.doc.getAllDocsFromFolderId(folderId, mode);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        const index = this.docs.folders.findIndex((n) => n.folderId === folderId);
        if (~index) {
          this.docs.folders[index].data = sortDocs(this.sortingType, model.data);
          this.docs.folders[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.docs.folders.push({
            folderId: folderId,
            data: sortDocs(this.sortingType, model.data),
            loadMoreUrl: model.loadMoreUrl,
            folderScreenY: 0,
          });
        }
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    async loadMore(): Promise<void> {
      const url = this.getDocsLoadMoreUrl();
      if (url !== null) {
        this.errors = [];
        const response = await $api.doc.loadMore(url);

        if (response.statusCode === 200) {
          const model = response as ResponseDocsModel;
          updateDocsAfterLoadedMore(this.browserMode, model, this.activeGroup?.id, this.activeFolder?.id);
          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }

        return;
      }
      return;
    },
    async allDocs(): Promise<void> {
      this.loading = true;
      const response = await $api.doc.getAllDocs(this.browserMode);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        updateDocsAfterResponse(this.browserMode, this.sortingType, model);
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    async allDocsFromNetworkOnly(): Promise<void> {
      this.loading = true;
      const response = await $api.doc.getAllDocsFromNetworkOnly();

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        updateDocsAfterResponse(this.browserMode, this.sortingType, model);
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    async docsAutocomplete(search: string, groupId?: number, folderId?: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      let response;

      if (this.searchEverywhere) {
        if (groupId) {
          response = await $api.doc.getAllDocsFromGroupIdByText(search, this.browserMode, groupId);
        } else {
          response = await $api.doc.getAllDocsByText(search, this.browserMode);
        }
      } else {
        if (folderId) {
          response = await $api.doc.getAllDocsFromFolderIdByText(search, this.browserMode, folderId);
        } else if (groupId && !folderId) {
          response = await $api.doc.getAllDocsFromGroupIdByText(search, this.browserMode, groupId);
        } else {
          response = await $api.doc.getAllDocsByText(search, this.browserMode);
        }
      }

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        updateDocsAfterResponse(this.browserMode, this.sortingType, model, groupId, folderId);
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    docsFromSearch(docs: DocEntity[], loadMoreUrl: string | null = null): void {
      if (docs.length) {
        this.docs.search.data = docs;
        this.docs.search.loadMoreUrl = loadMoreUrl;
      }
    },
    async getFoldersTree(): Promise<TreeFolderModel[] | undefined> {
      const response = await $api.doc.getFoldersTree();

      if (response.statusCode === 200) {
        const model = response as ResponseTreeFolderModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },
    async getFoldersTreeByGroupId(groupId: number): Promise<TreeFolderModel[] | undefined> {
      const response = await $api.doc.getFoldersTreeByGroupId(groupId);

      if (response.statusCode === 200) {
        const model = response as ResponseTreeFolderModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },
    async createFolder(folderName: string, parentFolderId: number | null, groupId: number | null): Promise<boolean> {
      const response = await $api.doc.createFolder(folderName, parentFolderId, groupId);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsCreateFolderModel;
        updateDocsAfterCreatedAny(
          this.browserMode,
          this.sortingType,
          [model.data],
          groupId || undefined,
          parentFolderId || undefined
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async createFiles(files: FileModel[], folderId: number | null, groupId: number | null): Promise<DocEntity[] | []> {
      const response = await $api.doc.createFiles(files, folderId, groupId);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsCreateFileModel;
        updateDocsAfterCreatedAny(
          this.browserMode,
          this.sortingType,
          model.data,
          groupId || undefined,
          folderId || undefined
        );
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return [];
    },
    async renameFolder(folderId: number, name: string): Promise<boolean> {
      const response = await $api.doc.renameFolder(folderId, name);

      if (response.statusCode === 200) {
        updateDocsAfterRename(
          this.browserMode,
          this.sortingType,
          folderId,
          name,
          this.activeGroup?.id,
          this.activeFolder?.id
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async renameFile(fileId: number, name: string, description: string): Promise<boolean> {
      const response = await $api.doc.renameFile(fileId, name, description);

      if (response.statusCode === 200) {
        updateDocsAfterRename(
          this.browserMode,
          this.sortingType,
          fileId,
          name,
          this.activeGroup?.id,
          this.activeFolder?.id,
          description
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async uploadNewVersion(file: FileModel, fileInfo: FileModel): Promise<boolean> {
      const response = await $api.doc.uploadNewVersion(file.id, fileInfo);

      if (response.statusCode === 200) {
        updateDocsAfterUploadNewVersion(
          this.browserMode,
          file.id,
          fileInfo,
          this.activeGroup?.id,
          this.activeFolder?.id
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async moveFile(toFolderId: number | null, toGroupId: number | null, fileId: number): Promise<boolean> {
      const response = await $api.doc.moveFile(toFolderId, toGroupId, fileId);

      if (response.statusCode === 200) {
        updateDocsAfterDelete(
          this.browserMode,
          fileId,
          DocumentTypeEnum.File,
          this.activeGroup?.id,
          this.activeFolder?.id
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async moveFolder(toFolderId: number | null, toGroupId: number | null, folderId: number): Promise<boolean> {
      const response = await $api.doc.moveFolder(toFolderId, toGroupId, folderId);
      if (response.statusCode === 200) {
        updateDocsAfterDelete(
          this.browserMode,
          folderId,
          DocumentTypeEnum.Folder,
          this.activeGroup?.id,
          this.activeFolder?.id
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async deleteFile(fileId: number): Promise<boolean> {
      const response = await $api.file.delete(fileId);

      if (response.statusCode === 200) {
        updateDocsAfterDelete(
          this.browserMode,
          fileId,
          DocumentTypeEnum.File,
          this.activeGroup?.id,
          this.activeFolder?.id
        );

        return true;
      }

      return false;
    },
    async deleteWiki(wikiId: number): Promise<boolean> {
      const response = await $api.wiki.delete(wikiId);

      if (response.statusCode === 200) {
        updateDocsAfterDelete(
          this.browserMode,
          wikiId,
          DocumentTypeEnum.Wiki,
          this.activeGroup?.id,
          this.activeFolder?.id
        );

        return true;
      }

      return false;
    },
    async deleteFolder(folderId: number): Promise<boolean> {
      const response = await $api.doc.deleteFolder(folderId);

      if (response.statusCode === 200) {
        updateDocsAfterDelete(
          this.browserMode,
          folderId,
          DocumentTypeEnum.Folder,
          this.activeGroup?.id,
          this.activeFolder?.id
        );

        return true;
      }

      return false;
    },
    async moveWiki(toFolderId: number | null, toGroupId: number | null, wikiId: number): Promise<boolean> {
      const response = await $api.doc.moveWiki(toFolderId, toGroupId, wikiId);

      if (response.statusCode === 200) {
        updateDocsAfterDelete(
          this.browserMode,
          wikiId,
          DocumentTypeEnum.Wiki,
          this.activeGroup?.id,
          this.activeFolder?.id
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async shareFile(fileId: number, text: string): Promise<boolean> {
      const response = await $api.doc.shareFile(fileId, text);

      if (response.statusCode === 200) {
        const postStore = usePostStore();
        const model = response as ResponsePostModel;
        postStore.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async shareFileToGroup(fileId: number, text: string, groupId: number): Promise<boolean> {
      const response = await $api.doc.shareFileToGroup(fileId, text, groupId);

      if (response.statusCode === 200) {
        const postStore = usePostStore();
        const model = response as ResponsePostModel;
        postStore.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async sortDocs(sortingType: DocsSortingTypeEnum): Promise<void> {
      updateDocsAfterSort(this.browserMode, sortingType, this.activeGroup?.id, this.activeFolder?.id);
    },
    updateActiveFolderScreenY(): void {
      if (this.activeFolder !== null) {
        const index = this.docs.folders.findIndex((n) => n.folderId === this.activeFolder?.id);
        this.docs.folders[index].folderScreenY = this.currentScreenY;
      }
    },
    async shareDocumentArchiveLink(id: number, emails: string[], type: ShareArchiveLinkType): Promise<boolean> {
      this.errors = [];
      const response = await $api.doc.shareDocumentArchiveLink(id, emails, type);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
  },
  persist: true,
});

// const mergeById = (a: DocEntity[], b: DocEntity[]) => {
//   return unionBy(a, b, 'id').map((obj) => {
//     const match = find(b, { id: obj.data.id });
//     return match ? Object.assign({}, obj, match) : obj;
//   });
// };

const sortDocs = (sortingType: DocsSortingTypeEnum, data: DocEntity[]): DocEntity[] => {
  switch (sortingType) {
    case DocsSortingTypeEnum.NameASC:
      return data.sort((a, b) =>
        a.data.name.localeCompare(b.data.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );

    case DocsSortingTypeEnum.NameDESC:
      return data.sort((a, b) =>
        b.data.name.localeCompare(a.data.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );

    case DocsSortingTypeEnum.GroupASC:
      return orderBy(data, ['data.group.title'], ['asc']);

    case DocsSortingTypeEnum.GroupDESC:
      return orderBy(data, ['data.group.title'], ['desc']);

    case DocsSortingTypeEnum.AuthorASC:
      return orderBy(data, ['data.author.fullName'], ['asc']);

    case DocsSortingTypeEnum.AuthorDESC:
      return orderBy(data, ['data.author.fullName'], ['desc']);

    case DocsSortingTypeEnum.DateASC:
      return orderBy(data, ['data.createdAt'], ['asc']);

    case DocsSortingTypeEnum.DateDESC:
      return orderBy(data, ['data.createdAt'], ['desc']);

    case DocsSortingTypeEnum.SizeASC:
      return orderBy(data, ['data.size'], ['asc']);

    case DocsSortingTypeEnum.SizeDESC:
      return orderBy(data, ['data.size'], ['desc']);
  }
};

const updateDocsAfterResponse = (
  mode: DocBrowserModeEnum,
  sortingType: DocsSortingTypeEnum,
  model: ResponseDocsModel,
  groupId?: number | undefined,
  folderId?: number | undefined
) => {
  if (folderId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByFolderModel | undefined;
    if (object) {
      object.data = sortDocs(sortingType, model.data);
      object.loadMoreUrl = model.loadMoreUrl;
    }
    return;
  }

  if (groupId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByGroupModel | undefined;
    if (object) {
      object.data = sortDocs(sortingType, model.data);
      object.loadMoreUrl = model.loadMoreUrl;
    }
    return;
  }

  const docStore = useDocStore();
  switch (mode) {
    case DocBrowserModeEnum.All:
    case DocBrowserModeEnum.Follow:
      docStore.docs.all.data = sortDocs(sortingType, model.data);
      docStore.docs.all.loadMoreUrl = model.loadMoreUrl;
      break;

    case DocBrowserModeEnum.Recent:
      docStore.docs.recent.data = sortDocs(sortingType, model.data);
      docStore.docs.recent.loadMoreUrl = model.loadMoreUrl;
      break;

    case DocBrowserModeEnum.Uploaded:
      docStore.docs.uploaded.data = sortDocs(sortingType, model.data);
      docStore.docs.uploaded.loadMoreUrl = model.loadMoreUrl;
      break;

    case DocBrowserModeEnum.Search:
      docStore.docs.search.data = sortDocs(sortingType, model.data);
      docStore.docs.search.loadMoreUrl = model.loadMoreUrl;
      break;
  }
};

const updateDocsAfterSort = (
  mode: DocBrowserModeEnum,
  sortingType: DocsSortingTypeEnum,
  groupId?: number | undefined,
  folderId?: number | undefined
) => {
  if (folderId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByFolderModel | undefined;
    if (object) {
      object.data = sortDocs(sortingType, object.data);
    }
    return;
  }

  if (groupId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByGroupModel | undefined;
    if (object) {
      object.data = sortDocs(sortingType, object.data);
    }
    return;
  }

  const docStore = useDocStore();
  switch (mode) {
    case DocBrowserModeEnum.All:
    case DocBrowserModeEnum.Follow:
      docStore.docs.all.data = sortDocs(sortingType, docStore.docs.all.data);
      break;

    case DocBrowserModeEnum.Recent:
      docStore.docs.recent.data = sortDocs(sortingType, docStore.docs.recent.data);
      break;

    case DocBrowserModeEnum.Uploaded:
      docStore.docs.uploaded.data = sortDocs(sortingType, docStore.docs.uploaded.data);
      break;

    case DocBrowserModeEnum.Search:
      docStore.docs.search.data = sortDocs(sortingType, docStore.docs.search.data);
      break;
  }
};

const updateDocsAfterLoadedMore = (
  mode: DocBrowserModeEnum,
  model: ResponseDocsModel,
  groupId?: number | undefined,
  folderId?: number | undefined
) => {
  if (folderId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByFolderModel | undefined;
    if (object) {
      object.data = [...object.data, ...model.data];
      object.loadMoreUrl = model.loadMoreUrl;
    }
    return;
  }

  if (groupId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByGroupModel | undefined;
    if (object) {
      object.data = [...object.data, ...model.data];
      object.loadMoreUrl = model.loadMoreUrl;
    }
    return;
  }

  switch (mode) {
    case DocBrowserModeEnum.All:
    case DocBrowserModeEnum.Follow:
    case DocBrowserModeEnum.Recent:
    case DocBrowserModeEnum.Uploaded:
    case DocBrowserModeEnum.Search:
      {
        const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataModeModel | undefined;
        if (object) {
          object.data = [...object.data, ...model.data];
          object.loadMoreUrl = model.loadMoreUrl;
        }
      }
      return;
  }
};

const updateDocsAfterCreatedAny = (
  mode: DocBrowserModeEnum,
  sortingType: DocsSortingTypeEnum,
  docs: DocEntity[],
  groupId?: number | undefined,
  folderId?: number | undefined
) => {
  if (folderId || groupId) {
    // При загрузке в папку или группу файл так же помещаем вверх списка all
    const object = getCurrentResultObject(DocBrowserModeEnum.All, undefined, undefined) as
      | DocsDataModeModel
      | undefined;
    if (object) {
      object.data = [...object.data, ...docs];
      object.data = sortDocs(sortingType, object.data);
    }

    if (folderId) {
      const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByFolderModel | undefined;
      if (object) {
        object.data = [...object.data, ...docs];
        object.data = sortDocs(sortingType, object.data);
      }
      return;
    }

    if (groupId) {
      const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByGroupModel | undefined;
      if (object) {
        object.data = [...object.data, ...docs];
        object.data = sortDocs(sortingType, object.data);
      }
      return;
    }
  }

  switch (mode) {
    case DocBrowserModeEnum.All:
    case DocBrowserModeEnum.Follow:
    case DocBrowserModeEnum.Recent:
    case DocBrowserModeEnum.Uploaded:
      {
        const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataModeModel | undefined;
        if (object) {
          object.data = [...object.data, ...docs];
          object.data = sortDocs(sortingType, object.data);
        }
      }
      break;
  }
  return;
};

const updateDocsAfterRename = (
  mode: DocBrowserModeEnum,
  sortingType: DocsSortingTypeEnum,
  id: number,
  name: string,
  groupId?: number | undefined,
  folderId?: number | undefined,
  description = ''
) => {
  if (folderId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByFolderModel | undefined;
    if (object) {
      const index = object.data.findIndex((n: DocEntity) => n.data.id === id);
      if (~index) {
        object.data[index].data.name = name;
        object.data[index].data.description = description;
      }
    }
    return;
  }

  if (groupId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByGroupModel | undefined;
    if (object) {
      const index = object.data.findIndex((n: DocEntity) => n.data.id === id);
      if (~index) {
        object.data[index].data.name = name;
        object.data[index].data.description = description;
      }
    }
    return;
  }

  switch (mode) {
    case DocBrowserModeEnum.All:
    case DocBrowserModeEnum.Follow:
    case DocBrowserModeEnum.Recent:
    case DocBrowserModeEnum.Uploaded:
      {
        const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataModeModel | undefined;
        if (object) {
          const index = object.data.findIndex((n: DocEntity) => n.data.id === id);
          if (~index) {
            object.data[index].data.name = name;
            object.data[index].data.description = description;
          }
        }
      }
      return;
  }
  return;
};

const updateDocsAfterDelete = (
  mode: DocBrowserModeEnum,
  id: number,
  docType: DocumentTypeEnum,
  groupId?: number | undefined,
  folderId?: number | undefined
) => {
  if (folderId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByFolderModel | undefined;
    if (object) {
      const index = object.data.findIndex((n: DocEntity) => n.data.id === id && n.documentType === docType);
      if (~index) {
        object.data.splice(index, 1);
      }
    }
    return;
  }

  if (groupId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByGroupModel | undefined;
    if (object) {
      const index = object.data.findIndex((n: DocEntity) => n.data.id === id && n.documentType === docType);
      if (~index) {
        object.data.splice(index, 1);
      }
    }
    return;
  }

  switch (mode) {
    case DocBrowserModeEnum.All:
    case DocBrowserModeEnum.Follow:
    case DocBrowserModeEnum.Recent:
    case DocBrowserModeEnum.Uploaded:
      {
        const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataModeModel | undefined;
        if (object) {
          const index = object.data.findIndex((n: DocEntity) => n.data.id === id && n.documentType === docType);
          if (~index) {
            object.data.splice(index, 1);
          }
        }
      }
      return;
  }
  return;
};

const updateDocsAfterUploadNewVersion = (
  mode: DocBrowserModeEnum,
  fileId: number,
  file: FileModel,
  groupId?: number | undefined,
  folderId?: number | undefined
) => {
  const fileData = {
    apiUrl: file.apiUrl,
    createdAt: DateHelper.getIsoNow(),
    image: file.image,
    key: file.key,
    mimeType: file.mimeType,
    videoPreview: file.videoPreview,
    webUrl: file.webUrl,
  } as FileModel;

  if (folderId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByFolderModel | undefined;
    if (object) {
      const index = object.data.findIndex((n) => n.data.id === fileId);
      if (~index) {
        object.data[index].data = assign({}, object.data[index].data, fileData);
      }
    }
    return;
  }

  if (groupId) {
    const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataByGroupModel | undefined;
    if (object) {
      const index = object.data.findIndex((n) => n.data.id === fileId);
      if (~index) {
        object.data[index].data = assign({}, object.data[index].data, fileData);
      }
    }
    return;
  }

  switch (mode) {
    case DocBrowserModeEnum.All:
    case DocBrowserModeEnum.Follow:
    case DocBrowserModeEnum.Recent:
    case DocBrowserModeEnum.Uploaded:
      {
        const object = getCurrentResultObject(mode, groupId, folderId) as DocsDataModeModel | undefined;
        if (object) {
          const index = object.data.findIndex((n) => n.data.id === fileId);
          if (~index) {
            object.data[index].data = assign({}, object.data[index].data, fileData);
          }
        }
      }
      break;
  }
  return;
};

const getCurrentResultObject = (
  mode: DocBrowserModeEnum,
  groupId?: number | undefined,
  folderId?: number | undefined
): DocsDataByGroupModel | DocsDataByFolderModel | DocsDataModeModel | undefined => {
  const docStore = useDocStore();

  if (folderId) {
    const index = docStore.docs.folders.findIndex((n) => n.folderId === folderId);
    if (~index) {
      return docStore.docs.folders[index];
    }
    return undefined;
  }

  if (groupId) {
    const index = docStore.docs.groups.findIndex((n) => n.groupId === groupId);
    if (~index) {
      return docStore.docs.groups[index];
    }
    return undefined;
  }

  switch (mode) {
    case DocBrowserModeEnum.All:
    case DocBrowserModeEnum.Follow:
      return docStore.docs.all;

    case DocBrowserModeEnum.Recent:
      return docStore.docs.recent;

    case DocBrowserModeEnum.Uploaded:
      return docStore.docs.uploaded;

    case DocBrowserModeEnum.Search:
      return docStore.docs.search;
  }

  return undefined;
};
