<template>
  <div :title="eventPeriod + '\n' + event.title" class="event-body">
    <div class="vuecal__event-title" v-html="event.title" />
    <span class="vuecal__event-time">
      {{ eventPeriod }}
      <span v-if="daysBetweenCount > 0" class="days">
        {{ ' +' + daysBetweenCount }}
      </span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import type { CalendarEventModel } from '@/@types';
import { DateHelper, formatDateHelper } from '@/helpers';

const props = defineProps({
  event: {
    type: Object as PropType<CalendarEventModel>,
    required: true,
  },
});

const daysBetweenCount: ComputedRef<number> = computed(() => {
  return DateHelper.getDaysBetweenDates(new Date(props.event.start), new Date(props.event.end));
});

const eventPeriod: ComputedRef<string> = computed(() => {
  return formatDateHelper(props.event.start, 'day') + ' - ' + formatDateHelper(props.event.end, 'day');
});
</script>
<style scoped lang="scss">
.event-body {
  padding: 4px;
}
.vuecal__event .vuecal__event-title {
  font-size: 0.9rem;
}
.vuecal__event .vuecal__event-time {
  font-size: 0.7rem;
}
.vuecal__event .vuecal__event-time .days {
  font-size: 0.6rem;
}
.vuecal__cell-date {
  font-size: 1rem;
}
@include respond-to-min-width(2xl) {
  .vuecal__event .vuecal__event-title {
    font-size: 1rem;
  }
  .vuecal__event .vuecal__event-time {
    font-size: 0.8rem;
  }
  .vuecal__event .vuecal__event-time .days {
    font-size: 0.7rem;
  }
  .vuecal__cell-date {
    font-size: 1.125rem;
  }
}
</style>
