<template>
  <ion-button mode="md" size="default" fill="clear" class="menu" @click.stop="openPostMenu($event, post)">
    <!-- TODO: Place the icon in icons-provider when it is available -->
    <ion-icon v-if="conversationsFlag === ConversationsFlagEnum.SharedPost" slot="icon-only" :icon="openOutline" />
    <icons-provider v-else slot="icon-only" :icon-props="{ width: '22', height: '22' }" name="menuDots" />
  </ion-button>
</template>

<script lang="ts" setup>
import { Clipboard } from '@capacitor/clipboard';
import { IonIcon, IonButton } from '@ionic/vue';
import { openOutline } from 'ionicons/icons';
import type { PropType } from 'vue';
import 'swiper/css';
import { useRouter } from 'vue-router';

import {
  ConversationsFlagEnum,
  FeedTypeEnum,
  PostMenuActionEnum,
  ConversationsTypeEnum,
  ShareArchiveLinkType,
  DocumentExtensionEnum,
  FileStatusEnum,
} from '@/@enums';
import type { PostEntity } from '@/@types';
import { IconsProvider } from '@/components';
import {
  componentAppDatePickerPopover,
  DateHelper,
  postContextMenu,
  postContextMenuSheet,
  showToast,
  componentPostEdit,
  componentSendPostToExternalEmail,
  componentPostComplain,
  isNativeMobile,
  htmlToText,
  isAnyMobile,
  componentShareArchiveLink,
  filesHybrid,
  isBlob,
  useErrors,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { usePostStore } from '@/store';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Props
const props = defineProps({
  post: {
    type: Object as PropType<PostEntity>,
    required: true,
  },
  conversationsFlag: {
    type: String as PropType<ConversationsFlagEnum>,
    required: true,
  },
  feedType: {
    type: String as PropType<FeedTypeEnum | null>,
    default: FeedTypeEnum.Recommended,
  },
});

// Store
const postStore = usePostStore();

// Helpers
const { t } = useI18n();
const { handleError } = useErrors();
const clipboard = Clipboard;

// Router
const router = useRouter();

// Actions
const switchActions = async (ev: Event, result: PostMenuActionEnum | undefined, postData: PostEntity) => {
  switch (result) {
    case PostMenuActionEnum.Share:
      {
        const innerResult = await postContextMenuSheet(
          postData,
          ConversationsFlagEnum.ConversationPage,
          ConversationsTypeEnum.Announcement,
          true
        );
        await switchActions(ev, innerResult.data, postData);
      }
      break;
    case PostMenuActionEnum.Open:
      await openPost(postData.id);
      break;
    case PostMenuActionEnum.Delete:
      if (
        !postData.plannedPostData
          ? await postStore.postDelete(postData.id)
          : await postStore.switchPlannedDelete(postData)
      ) {
        if (props.conversationsFlag === ConversationsFlagEnum.ConversationPage) {
          await router.push({
            name: ROUTES_NAME.FEED,
          });
        }
      } else {
        handleError(true, undefined, t('conversations.conversationPostMenu.delete.postNotDeleted'));
      }
      break;

    case PostMenuActionEnum.AddToBookmarks:
      await addToBookmarks(postData.id);
      break;

    case PostMenuActionEnum.RemoveFromBookmarks:
      await removeFromBookmarks(postData.id);
      break;

    case PostMenuActionEnum.Pin:
      if (await postStore.pinPost(postData.id)) {
        await showToast(t('conversations.conversationPostMenu.attach.postPinned'), true);
      } else {
        handleError(true, undefined, t('conversations.conversationPostMenu.attach.postNotPinned'));
      }
      break;

    case PostMenuActionEnum.UnPin:
      if (await postStore.unPinPost(postData.id)) {
        await showToast(t('conversations.conversationPostMenu.attach.postUnpinned'), true);
      } else {
        handleError(true, undefined, t('conversations.conversationPostMenu.attach.postNotUnpinned'));
      }
      break;

    case PostMenuActionEnum.Follow:
      if (await postStore.followToPost(postData.id)) {
        await showToast(t('conversations.conversationPostMenu.following.followed'), true);
      } else {
        handleError(true, undefined, t('conversations.conversationPostMenu.following.notFollowed'));
      }
      break;

    case PostMenuActionEnum.UnFollow:
      if (await postStore.unFollowFromPost(postData.id)) {
        await showToast(t('conversations.conversationPostMenu.following.unfollowed'), true);
      } else {
        handleError(true, undefined, t('conversations.conversationPostMenu.following.notUnfollowed'));
      }
      break;

    case PostMenuActionEnum.Edit:
      await componentPostEdit(postData);
      break;

    case PostMenuActionEnum.SendToEmail:
      if (await postStore.sendPostToEmail(postData.id)) {
        await showToast(t('conversations.conversationPostMenu.send.mailSend'), true);
      } else {
        handleError(true, undefined, t('conversations.conversationPostMenu.send.mailNotSend'));
      }
      break;

    case PostMenuActionEnum.SendToExternalEmail:
      await componentSendPostToExternalEmail(postData.id);
      break;

    case PostMenuActionEnum.ShareArchiveLink:
      {
        await componentShareArchiveLink(postData.id, ShareArchiveLinkType.UserItem);
      }
      break;

    case PostMenuActionEnum.DownloadAsPDF:
      {
        const response = await postStore.postDownload(postData.messageType, DocumentExtensionEnum.PDF, postData.id);
        if (isBlob(response)) {
          const status = await filesHybrid.downloadPost(postData, response as Blob);
          status === FileStatusEnum.Success
            ? await showToast(t('files.successDownloaded'), true)
            : handleError(true, undefined, 'Error while switching post');
        }
      }
      break;

    case PostMenuActionEnum.DownloadAsZIP:
      {
        const response = await postStore.postDownload(postData.messageType, DocumentExtensionEnum.ZIP, postData.id);
        if (isBlob(response)) {
          const status = await filesHybrid.downloadPost(postData, response as Blob);
          status === FileStatusEnum.Success
            ? await showToast(t('files.successDownloaded'), true)
            : handleError(true, undefined, 'Error while switching post');
        }
      }
      break;

    case PostMenuActionEnum.Complain:
      await componentPostComplain(postData.id);
      break;

    case PostMenuActionEnum.CopyText: {
      let text = '';
      if (props.post.messageType === ConversationsTypeEnum.Idea && props.post.ideaData) {
        text = htmlToText(
          props.post.ideaData.title +
            ` ${t('conversations.idea.problem')}: ` +
            props.post.ideaData.problem +
            ` ${t('conversations.idea.solution')}: ` +
            props.post.ideaData.solution
        );
      } else {
        text = htmlToText(props.post.bodyHtml);
      }
      if (isNativeMobile) {
        await clipboard.write({
          string: text,
        });
      } else {
        await navigator.clipboard.writeText(text);
      }

      await showToast(t('appPopoverMenu.copy.textCopied'), true);
      break;
    }

    case PostMenuActionEnum.SendNow:
      await postStore.switchPlannedForce(postData);
      break;
    case PostMenuActionEnum.ReSchedule:
      {
        const result = await componentAppDatePickerPopover(
          ev,
          DateHelper.addMinutesToDateInISO(new Date().toISOString(), 15),
          true,
          true,
          5
        );
        if (postData.plannedPostData && result.data && !DateHelper.isDateInPast(result.data)) {
          postData.plannedPostData.plannedDate = result.data;
          await postStore.switchPlannedUpdate(postData);
        } else if (result.data && DateHelper.isDateInPast(result.data)) {
          handleError(true, undefined, t('validations.pastDate'));
        }
      }
      break;
  }
};

const openPostMenu = async (ev: Event, postData: PostEntity) => {
  if (props.conversationsFlag === ConversationsFlagEnum.SharedPost) {
    return await openPost(postData.id);
  }

  const isAnnouncement = props.feedType === FeedTypeEnum.Announcement ? ConversationsTypeEnum.Announcement : '';
  let result;
  if (isAnyMobile) {
    result = await postContextMenuSheet(postData, ConversationsFlagEnum.ConversationPage, isAnnouncement);
  } else {
    result = await postContextMenu(postData, props.conversationsFlag, isAnnouncement);
  }

  await switchActions(ev, result.data, postData);
};

const addToBookmarks = async (id: number) => {
  if (await postStore.addBookmark(id)) {
    await showToast(t('conversations.conversationPostMenu.bookmark.bookmarkAdded'), true);
  } else {
    handleError(true, undefined, t('conversations.conversationPostMenu.bookmark.bookmarkNotAdd'));
  }
};

const removeFromBookmarks = async (id: number) => {
  if (await postStore.removeBookmark(id)) {
    await showToast(t('conversations.conversationPostMenu.bookmark.bookmarkRemoved'), true);
  } else {
    handleError(true, undefined, t('conversations.conversationPostMenu.bookmark.bookmarkNotRemoved'));
  }
};

const openPost = async (id: number) => {
  await router.push({
    name: ROUTES_NAME.POST_BY_ID,
    params: { id: id },
  });
};
</script>

<style scoped lang="scss">
ion-button {
  &.menu {
    @include resetStyleFromIonicButton;
    --color: var(--ion-color-medium);
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --ripple-color: transparent;
    --padding-end: 0;
    --padding-start: 0;
    margin: 0;
    z-index: 1;

    &:hover {
      ion-icon {
        opacity: 0.7;
      }
    }
  }
}
</style>
