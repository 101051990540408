import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { DocEditAccessEnum, UserRoleEnum } from '@/@enums';
import { isNativeMobile, isWebMobile } from '@/helpers/helper';
import { componentOfficeView } from '@/helpers/modalComponents';
import { ROUTES_NAME } from '@/router';
import type { OfficeProps } from '@/store';
import { useNetworkStore, useOfficeStore, useUserStore } from '@/store';

interface IOfficeHelper {
  openOfficeView: (
    payload: string,
    temp: boolean,
    props: Partial<OfficeProps> | null,
    onNewTab: boolean,
    autoSave: boolean,
    groupId: number | null,
    folderId: number | null
  ) => Promise<boolean>;
  isOfficeFile: (fileType: string) => boolean;
  isUserGrantedForActions: ComputedRef<boolean>;
}

export function useOfficeHelper(): IOfficeHelper {
  const router = useRouter();
  const userStore = useUserStore();
  const officeStore = useOfficeStore();
  const networkStore = useNetworkStore();

  const OFFICE_EXTENSIONS = ['doc', 'docx', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx'];

  const _isUserAdmin: ComputedRef<boolean> = computed(() => {
    if (userStore.current) {
      return userStore.current.roleId >= UserRoleEnum.Administrator;
    }

    return false;
  });

  const _isCurrentDefaultUser: ComputedRef<boolean> = computed(() => {
    if (userStore.current) {
      return userStore.current.roleId >= UserRoleEnum.User;
    }
    return false;
  });

  const isUserGrantedForActions: ComputedRef<boolean> = computed(() => {
    const docAccess = networkStore.settings?.docEditAccess;

    // if (_isUserAdmin.value) {
    //   return true;
    // }

    switch (docAccess) {
      case DocEditAccessEnum.All:
        return true;
      case DocEditAccessEnum.Administrators:
        return _isUserAdmin.value;
      case DocEditAccessEnum.AllExceptExternal:
        return _isCurrentDefaultUser.value;
      case DocEditAccessEnum.Off:
      default:
        return false;
    }
  });

  const openOfficeView = async (
    payload: string,
    temp = false,
    props: Partial<OfficeProps> | null,
    onNewTab = true,
    autoSave = false,
    groupId: number | null = null,
    folderId: number | null = null
  ): Promise<boolean> => {
    if (isUserGrantedForActions.value) {
      officeStore.setProps(props);

      const routerData = router.resolve(
        temp
          ? {
              name: ROUTES_NAME.OFFICE,
              params: { payload },
              query: {
                autoSave: `${autoSave ? 'true' : 'false'}`,
                temp: 'true',
                groupId: groupId ? groupId : undefined,
                folderId: folderId ?? props?.folder?.id ?? undefined,
              },
            }
          : {
              name: ROUTES_NAME.OFFICE,
              params: { payload },
            }
      );

      if (onNewTab && !(isWebMobile || isNativeMobile)) {
        window.open(routerData.href, '_blank');
      } else {
        await componentOfficeView({
          payload,
          autoSave: `${autoSave ? 'true' : 'false'}`,
          temp: `${temp ? 'true' : 'false'}`,
          groupId: groupId ? groupId : undefined,
          folderId: folderId ?? props?.folder?.id ?? undefined,
        });
      }

      return true;
    }

    return false;
  };

  const isOfficeFile = (fileType: string): boolean => OFFICE_EXTENSIONS.includes(fileType);

  return {
    openOfficeView,
    isOfficeFile,
    isUserGrantedForActions,
  };
}
