import type { Component } from 'vue';
import { markRaw } from 'vue';

interface IconComponent {
  default: Component;
}

interface IIcons {
  /**
   * Loads an icon by its name.
   * @param {string} iconName - The name of the icon to load.
   * @returns {Promise<Component | null>} - A promise that resolves to the icon component or null if not found.
   */
  loadIcon: (iconName: string) => Promise<Component | null>;
}

let instance: IIcons | null = null;

/**
 * Hook to use icons within the application.
 * @returns {IIcons} - An object with a method to load icons.
 */
export function useIcons(): IIcons {
  if (instance) {
    return instance;
  }

  /**
   * Glob import for icons
   * @type {Record<string, () => Promise<{ default: Component }>>}
   * For example: { '../icons/home.vue': () => import('../icons/home.vue') }
   */
  const icons = import.meta.glob('../icons/**/*.vue') as Record<string, () => Promise<IconComponent>>;

  /**
   * Map of icon names to their respective paths
   * @type {Record<string, string>}
   * For example: { 'home': '../icons/home.vue' }
   */
  const iconMap: Record<string, string> = {};

  /**
   * Preload all icons by populating the iconMap with icon names and paths.
   * @returns {Promise<void>}
   */
  const preloadIcons = async (): Promise<void> => {
    for (const path in icons) {
      const fileName = path.split('/').pop()?.replace('.vue', '').toLowerCase();
      if (fileName) {
        iconMap[fileName] = path;
      } else {
        console.error('Error parsing icon file name:', path);
      }
    }
  };

  // Office MIME types
  const officeMimeTypes = [
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'vnd.openxmlformats-officedocument.presentationml.presentation',
  ];

  // Extensions
  const docExtensions = ['docx', 'doc', 'docm', officeMimeTypes[0]];
  const excelExtensions = ['xlsx', 'xls', 'xlsm', officeMimeTypes[1]];
  const pptExtensions = ['pptx', 'ppt', 'pptm', officeMimeTypes[2]];

  // Methods for checking extensions
  /**
   * Checks if the extension is an Office extension.
   * @param {string} ext - The file extension to check.
   * @returns {boolean} - True if it is an Office extension, false otherwise.
   */
  const isOffice = (ext: string): boolean => isDoc(ext) || isExcel(ext) || isPpt(ext);

  const isDoc = (ext: string): boolean => docExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  const isExcel = (ext: string): boolean => excelExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  const isPpt = (ext: string): boolean => pptExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  /**
   * Loads an icon by its name.
   * @param {string} iconName - The name of the icon to load.
   * @returns {Promise<Component | null>} - A promise that resolves to the icon component or null if not found.
   */
  const loadIcon = async (iconName: string): Promise<Component | null> => {
    iconName = iconName.toLowerCase();

    if (!iconName) {
      console.error('No icon name provided.');
      return null;
    }

    let selectedIcon: Component | null = null;

    // Check if office document icon
    if (isOffice(iconName)) {
      switch (true) {
        case isDoc(iconName):
          selectedIcon = markRaw((await import('../icons/doc.vue')).default);
          break;
        case isExcel(iconName):
          selectedIcon = markRaw((await import('../icons/xlsx.vue')).default);
          break;
        case isPpt(iconName):
          selectedIcon = markRaw((await import('../icons/ppt.vue')).default);
          break;
        default:
          selectedIcon = null;
          break;
      }
    } else {
      const iconPath = iconMap[iconName];
      if (iconPath) {
        try {
          const iconModule = await icons[iconPath]();
          selectedIcon = markRaw(iconModule.default);
        } catch (error) {
          console.error('Error loading icon:', error);
          selectedIcon = null;
        }
      } else {
        console.error(`Icon file not found for the given prop name: ${iconName}`);
        selectedIcon = null;
      }
    }

    return selectedIcon;
  };

  // Preload icons immediately when this module is imported
  preloadIcons();

  instance = {
    loadIcon,
  };

  return instance;
}
