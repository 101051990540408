<template>
  <div class="empty">
    <ion-text>
      {{ noDataText }}
    </ion-text>
  </div>
</template>

<script lang="ts" setup>
import { IonText } from '@ionic/vue';
import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { useI18n } from '@/i18n';

const props = defineProps({
  text: {
    type: String || null,
    default: () => null,
  },
});

const { t } = useI18n();

const noDataText: ComputedRef<string> = computed(() => {
  if (props.text !== null) {
    return props.text;
  }
  return t('noData');
});
</script>

<style scoped lang="scss">
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
}
.empty ion-text {
  padding: 1rem;
  color: var(--ion-color-medium);
  text-align: center;
}
</style>
