import { alertController, modalController } from '@ionic/vue';

import { DocumentTypeEnum, WikiActionEnum, FileStatusEnum, DocumentExtensionEnum } from '@/@enums';
import type { DocEntity, WikiModel, WikiVersionModel, MediaModel, AppActionButton } from '@/@types';
import {
  filesHybrid,
  showToast,
  isBlob,
  wikiReplaceActionsMenu,
  componentDocsAttachment,
  componentDocsCreateFile,
  componentDocsMoveFile,
  componentWikiCreate,
  componentWikiRelations,
  componentWikiHistoryModal,
  useErrors,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { useDocStore, useWikiStore, useUserStore } from '@/store';

interface IWikiActions {
  whichActionToMake: (action: WikiActionEnum, wiki: WikiModel) => Promise<void>;
  openWiki: (id: number) => Promise<void>;
  downloadWiki: (wiki: WikiModel, documentExtension: DocumentExtensionEnum) => Promise<void>;
  editWiki: (wiki: WikiModel) => Promise<void>;
  compareWikiVersions: (wiki?: WikiModel, id?: number) => Promise<void>;
  moveWiki: (wiki: WikiModel) => Promise<void>;
  makeOfficial: (wiki: WikiModel) => Promise<void>;
  showRelations: (wiki: WikiModel) => Promise<void>;
  deleteWiki: (wiki?: WikiModel, id?: number) => Promise<void>;
  replace: (wiki: WikiModel) => Promise<boolean>;
  replaceFromDocBrowser: (wiki: WikiModel) => Promise<boolean>;
  replaceWithNewWiki: (wiki: WikiModel) => Promise<boolean>;
  replaceWithNewFile: (wiki: WikiModel) => Promise<boolean>;
  showHistory: (id: number) => Promise<void>;
  getWikiVersionButtons: () => {
    left: AppActionButton;
    right: AppActionButton;
  };
  getWikiVersionModifiedByAvatar: (wiki: WikiVersionModel) => MediaModel | null;
  searchWikiVersions: (searchText: string) => Promise<void>;
  restoreWikiVersion: (id: number) => Promise<void>;
  createWiki: (groupId?: number, folderId?: number) => Promise<void>;
}

export function useWikiActionsHelper(): IWikiActions {
  // const route = router.currentRoute.value;
  const { t } = useI18n();
  const docStore = useDocStore();
  const wikiStore = useWikiStore();

  const _confirmDelete = async (wikiId: number | undefined, versionId: number | undefined): Promise<void> => {
    const { handleError } = useErrors();

    const id = wikiId ?? versionId;

    const alert = await alertController.create({
      message: `${t('documents.popup.deleteWiki')} <strong>${id}</strong>?`,
      buttons: [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert_buttons',
        },
        {
          text: t('yes'),
          cssClass: 'custom-alert_buttons',
          handler: async () => {
            try {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              await wikiStore.deleteWiki(id!);
              await showToast(t('documents.popup.deleteSuccess'), true);
              modalController.dismiss();
            } catch (error) {
              handleError(true, error, t('documents.popup.deleteError'));
            }
          },
        },
      ],
    });

    await alert.present();
  };

  const openWiki = async (id: number): Promise<void> => {
    await router.push({
      name: ROUTES_NAME.WIKI_BY_ID,
      params: { id: id },
    });
  };

  const createWiki = async (groupId?: number, folderId?: number): Promise<void> => {
    await router.push({
      name: ROUTES_NAME.WIKI_EDIT,
      query: { groupId, folderId },
    });
  };

  const downloadWiki = async (wiki: WikiModel, documentExtension: DocumentExtensionEnum): Promise<void> => {
    const response = await wikiStore.downloadWiki(wiki.id, documentExtension);
    if (isBlob(response)) {
      const result = await filesHybrid.downloadWiki(wiki, response as Blob);
      await showToast(t('files.successDownloaded'), result === FileStatusEnum.Success);
    } else {
      console.error('Error response:', response);
    }
  };

  const editWiki = async (wiki: WikiModel): Promise<void> => {
    await router.push({
      name: ROUTES_NAME.WIKI_EDIT,
      query: {
        id: wiki.id,
        groupId: wiki.group?.id,
        folderId: wiki.parentFolderId ?? undefined,
      },
    });
  };

  const compareWikiVersions = async (wiki?: WikiModel, id?: number): Promise<void> => {
    if (!wiki?.id) {
      wikiStore.$patch((state) => {
        const sIndex = state.availableWikiVersions.findIndex((awv) => awv.id === id);
        const tIndex = sIndex + 1;
        state.sourceWikiVersion = state.availableWikiVersions[sIndex];
        state.targetWikiVersion = state.availableWikiVersions[tIndex];
      });
    }
    await router.push({
      name: ROUTES_NAME.WIKI_COMPARE,
      query: {
        id: wiki?.id,
      },
    });
  };

  const moveWiki = async (wiki: WikiModel): Promise<void> => {
    const result = await componentDocsMoveFile(null);
    if (result.data !== undefined) {
      await docStore.moveWiki(result.data.folderId, result.data.groupId, wiki.id);
    }
  };

  const makeOfficial = async (wiki: WikiModel): Promise<void> => {
    await wikiStore.makeOfficial(wiki.id);
  };

  const showRelations = async (wiki: WikiModel): Promise<void> => {
    await wikiStore.showRelations(wiki.id);
    await componentWikiRelations();
  };

  const deleteWiki = async (wiki?: WikiModel, id?: number): Promise<void> => {
    const { handleError } = useErrors();

    if (!wiki?.id && !id) {
      console.error('Wiki id is not defined');
      return;
    }

    if (!wiki?.id && id) {
      await _confirmDelete(undefined, id);
    } else if (wiki?.id !== undefined && id === undefined) {
      const optionsAlert = await alertController.create({
        message: `${t('documents.popup.deleteWiki')} <strong>${wiki?.name ?? id}</strong>?`,
        buttons: [
          {
            text: t('documents.popup.deleteWithAllRelations'),
            cssClass: 'custom-alert_buttons',
            handler: async () => {
              await _confirmDelete(wiki.id, undefined);
            },
          },
          {
            text: t('documents.popup.deleteWithReplace'),
            cssClass: 'custom-alert_buttons',
            handler: async () => {
              try {
                await replace(wiki);
                await showToast(t('documents.popup.deleteSuccess'), true);
                modalController.dismiss();
              } catch (error) {
                handleError(true, error, t('documents.popup.deleteError'));
              }
            },
          },
        ],
      });

      await optionsAlert.present();
    }
  };

  const replace = async (wiki: WikiModel): Promise<boolean> => {
    const result = await wikiReplaceActionsMenu();

    if (result.data !== undefined) {
      switch (result.data) {
        case WikiActionEnum.ReplaceFromDocBrowser:
          await replaceFromDocBrowser(wiki);
          break;
        case WikiActionEnum.ReplaceWithNewWiki:
          await replaceWithNewWiki(wiki);
          break;
        case WikiActionEnum.ReplaceWithNewFile:
          await replaceWithNewFile(wiki);
          break;
      }
    }

    return false;
  };

  const replaceFromDocBrowser = async (wiki: WikiModel): Promise<boolean> => {
    try {
      const result = await componentDocsAttachment(null);

      if (result.data !== undefined) {
        const data = result.data as DocEntity[];

        data.forEach(async (element) => {
          if (element.documentType === DocumentTypeEnum.Wiki) {
            await wikiStore.deleteWikiAndReplace(wiki.id, element.data.id, null);
          } else {
            await wikiStore.deleteWikiAndReplace(wiki.id, null, element.data.id);
          }
        });
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const replaceWithNewWiki = async (wiki: WikiModel): Promise<boolean> => {
    const result = await componentWikiCreate(wiki.group?.id, wiki?.parentFolderId ?? undefined, false);

    if (result) {
      await wikiStore.deleteWikiAndReplace(wiki.id, result.id, null);
    }

    return false;
  };

  const replaceWithNewFile = async (wiki: WikiModel): Promise<boolean> => {
    const result = await componentDocsCreateFile();

    if (result !== null) {
      await wikiStore.deleteWikiAndReplace(wiki.id, null, result.id);
    }

    return false;
  };

  const showHistory = async (id: number): Promise<void> => {
    const { handleError } = useErrors();

    await componentWikiHistoryModal();
    const result = await wikiStore.getWikiHistory(id);

    if (!result) {
      handleError(true, undefined, 'Failed to get history'); //TODO: add translation
    }
  };

  const getWikiVersionButtons = (): {
    left: AppActionButton;
    right: AppActionButton;
  } => {
    return {
      left: {
        title: t('wiki.actions.restore'),
        action: WikiActionEnum.Restore,
        type: 'attention',
        icon: 'respond-arrow', //TODO: restore icon from @vika
      },
      right: {
        title: t('wiki.actions.compare'),
        action: WikiActionEnum.Compare,
        type: 'main',
        icon: 'compare',
      },
    };
  };

  const getWikiVersionModifiedByAvatar = (wiki: WikiVersionModel): MediaModel | null => {
    if (wiki.modifyUserId) {
      const uId = wiki.modifyUserId;
      const user = useUserStore().getUserProfile(uId);
      return user?.avatar;
    }
    return null;
  };

  const searchWikiVersions = async (searchText: string) => {
    searchText = searchText.trim();
    if (searchText === '') {
      return;
    }
    await showToast('Searching for wiki versions is currently unavailable', false);
    // await wikiStore.searchWikiVersions(searchText);
  };

  const restoreWikiVersion = async (id: number | undefined) => {
    const { handleError } = useErrors();

    if (!id) {
      return;
    }
    handleError(true, undefined, 'Restoring wiki is currently unavailable'); //TODO: add translation
    // await wikiStore.restoreWikiVersion(id);
  };

  const whichActionToMake = async (action: WikiActionEnum, wiki: WikiModel, id?: number) => {
    switch (action) {
      case WikiActionEnum.Open:
        await openWiki(wiki.id);
        break;
      case WikiActionEnum.Edit:
        await editWiki(wiki);
        break;
      case WikiActionEnum.Compare:
        await compareWikiVersions(wiki, id);
        break;
      case WikiActionEnum.Move:
        await moveWiki(wiki);
        break;
      case WikiActionEnum.MakeOfficial:
        await makeOfficial(wiki);
        break;
      case WikiActionEnum.ShowRelations:
        await showRelations(wiki);
        break;
      case WikiActionEnum.Delete:
        await deleteWiki(wiki, id);
        break;
      case WikiActionEnum.ReplaceFromDocBrowser:
        await replaceFromDocBrowser(wiki);
        break;
      case WikiActionEnum.ReplaceWithNewWiki:
        await replaceWithNewWiki(wiki);
        break;
      case WikiActionEnum.ReplaceWithNewFile:
        await replaceWithNewFile(wiki);
        break;
      case WikiActionEnum.ShowHistory:
        await showHistory(wiki.id);
        break;
      case WikiActionEnum.Restore:
        await restoreWikiVersion(id);
        break;
      case WikiActionEnum.DownloadAsPDF:
        await downloadWiki(wiki, DocumentExtensionEnum.PDF);
        break;
      case WikiActionEnum.DownloadAsDOCX:
        await downloadWiki(wiki, DocumentExtensionEnum.DOCX);
        break;
    }
  };

  return {
    whichActionToMake,
    openWiki,
    downloadWiki,
    editWiki,
    compareWikiVersions,
    moveWiki,
    makeOfficial,
    showRelations,
    deleteWiki,
    replace,
    replaceFromDocBrowser,
    replaceWithNewWiki,
    replaceWithNewFile,
    createWiki,
    showHistory,
    getWikiVersionButtons,
    getWikiVersionModifiedByAvatar,
    searchWikiVersions,
    restoreWikiVersion,
  };
}
