import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/vue';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import type { RouteRecordRaw } from 'vue-router';

import { UserRoleEnum } from '@/@enums';
import {
  useTaskManagementHelper,
  useAiAssistantHelper,
  useRichTextHelper,
  showToast,
  useRequirementsHelper,
  useErrors,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { useUserStore, useAppStore } from '@/store';

declare module 'vue-router' {
  interface RouteMeta {
    isActive?: boolean;
  }
}
export const ROUTES_NAME = {
  HOME: 'Home',
  LOGIN: 'Login',
  REGISTRATION: 'Registration',
  ACTIVATION: 'Activation',
  MESSENGER_ACTIVE: 'Messenger',
  MESSENGER_ARCHIVE: 'MessengerArchive',
  MESSENGER_CHAT_BY_CHAIN: 'MessengerChatByChain',
  MESSENGER_CHAT_BY_USER: 'MessengerChatByUser',
  USERS: 'Users',
  USER_BY_ID: 'UserById',
  NOT_FOUND: 'NotFound',
  GROUPS: 'Groups',
  GROUP_BY_ID: 'GroupById',
  GROUP_DASHBOARD: 'GroupDashboard',
  NOTIFICATIONS: 'Notifications',
  FEED: 'Feed',
  POST_BY_ID: 'PostById',
  DOCS: 'Docs',
  PAGES: 'Pages',
  PAGE_BY_ID: 'PageById',
  PAGE_EDIT: 'PageEdit',
  CALENDAR: 'Calendar',
  SEARCH: 'Search',
  WIKIS: 'Wikis',
  WIKI_BY_ID: 'WikiById',
  WIKI_EDIT: 'WikiEdit',
  WIKI_COMPARE: 'WikiCompare',
  TOPICS: 'Topics',
  TOPIC_BY_ID: 'TopicById',
  ADMINISTRATION: 'Administration',
  ADMIN_USAGE_RULES: 'AdminUsageRules',
  USAGE_RULES: 'UsageRules',
  PROJECT_BY_ID: 'ProjectById',
  PROJECTS: 'Projects',
  PROJECTS_STATISTICS: 'ProjectsStatistics',
  TASKS: 'Tasks',
  MILESTONES: 'Milestones',
  MILESTONE_BY_ID: 'MilestoneById',
  OFFICE: 'Office',
  AI_ASSISTANT: 'AiAssistant',
  IDEAS: 'Ideas',
  NETWORKS: 'Networks',
  LOADING: 'Loading',
  SETTINGS: 'Settings',
  // DOCUMENT_VIEW: 'Document',
};

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: ROUTES_NAME.HOME,
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/login',
    name: ROUTES_NAME.LOGIN,
    component: () => import('@/views/Auth/LoginPage.vue'),
    meta: { layout: 'auth' },
  },
  ...(import.meta.env.VITE_ENABLE_REGISTRATION !== 'false'
    ? [
        {
          path: '/registration',
          name: ROUTES_NAME.REGISTRATION,
          component: () => import('@/views/Auth/RegistrationPage.vue'),
          meta: { layout: 'auth' },
        },
        {
          path: '/activation/:id',
          name: ROUTES_NAME.ACTIVATION,
          component: () => import('@/views/Auth/RegistrationPage.vue'),
          meta: { layout: 'auth' },
        },
      ]
    : []),
  {
    path: '/messenger',
    name: ROUTES_NAME.MESSENGER_ACTIVE,
    component: () => import('@/views/Messenger/MessengerPage.vue'),
    meta: { isMessenger: true, isArchive: false },
  },
  {
    path: '/messenger/archive',
    name: ROUTES_NAME.MESSENGER_ARCHIVE,
    component: () => import('@/views/Messenger/MessengerPage.vue'),
    meta: { isMessenger: true, isArchive: true },
  },
  {
    path: '/users',
    name: ROUTES_NAME.USERS,
    component: () => import(`@/views/Users/UsersPage.vue`),
  },
  {
    path: '/user/:id',
    name: ROUTES_NAME.USER_BY_ID,
    component: () => import('@/views/Users/UserPage.vue'),
  },
  {
    path: '/messenger/chain/:id',
    name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
    component: () => import('@/views/Messenger/ChatPage.vue'),
    meta: { type: 'chain', isMessenger: true },
  },
  {
    path: '/messenger/user/:id',
    name: ROUTES_NAME.MESSENGER_CHAT_BY_USER,
    component: () => import('@/views/Messenger/ChatPage.vue'),
    meta: { type: 'user', isMessenger: true },
  },
  {
    path: '/groups',
    name: ROUTES_NAME.GROUPS,
    component: () => import('@/views/Groups/GroupsPage.vue'),
  },
  {
    path: '/group/:id',
    name: ROUTES_NAME.GROUP_BY_ID,
    component: () => import('@/views/Groups/GroupPage.vue'),
  },
  {
    path: '/group/:id/dashboard',
    name: ROUTES_NAME.GROUP_DASHBOARD,
    component: () => import('@/views/Groups/GroupDashboardPage.vue'),
  },
  {
    path: '/notifications',
    name: ROUTES_NAME.NOTIFICATIONS,
    component: () => import('@/views/Notifications/NotificationsPage.vue'),
  },
  {
    path: '/feed',
    name: ROUTES_NAME.FEED,
    component: () => import('@/views/Feed/FeedPage.vue'),
  },
  {
    path: '/ideas',
    name: ROUTES_NAME.IDEAS,
    component: () => import('@/views/Ideas/IdeasPage.vue'),
  },
  {
    path: '/post/:id',
    name: ROUTES_NAME.POST_BY_ID,
    component: () => import('@/views/Conversations/ConversationPage.vue'),
  },
  {
    path: '/docs',
    name: ROUTES_NAME.DOCS,
    component: () => import(`@/views/Docs/DocsPage.vue`),
  },
  {
    path: '/pages',
    name: ROUTES_NAME.PAGES,
    component: () => import(`@/views/CustomPages/CustomPages.vue`),
    meta: { requiresStandardUserRights: true },
  },
  {
    path: '/page/:id',
    name: ROUTES_NAME.PAGE_BY_ID,
    component: () => import(`@/views/CustomPages/CustomPage.vue`),
  },
  {
    path: '/page/:id/edit',
    name: ROUTES_NAME.PAGE_EDIT,
    component: () => import(`@/views/CustomPages/CustomPageEdit.vue`),
    meta: { requiresStandardUserRights: true },
  },
  {
    path: '/calendar',
    name: ROUTES_NAME.CALENDAR,
    component: () => import('@/views/Calendar/CalendarPage.vue'),
  },
  {
    path: '/wikis',
    name: ROUTES_NAME.WIKIS,
    component: () => import('@/views/Wikis/WikisPage.vue'),
  },
  {
    path: '/wiki/:id',
    name: ROUTES_NAME.WIKI_BY_ID,
    component: () => import(`@/views/Wikis/WikiPage.vue`),
  },
  {
    path: '/edit-wiki',
    name: ROUTES_NAME.WIKI_EDIT,
    component: () => import(`@/views/Wikis/WikiEdit.vue`),
    props: (route) => ({ ...route.query }),
  },
  {
    path: '/compare-wiki',
    name: ROUTES_NAME.WIKI_COMPARE,
    component: () => import(`@/views/Wikis/WikiCompare.vue`),
    props: (route) => ({ ...route.query }),
  },
  {
    path: '/topics',
    name: ROUTES_NAME.TOPICS,
    component: () => import('@/views/Topics/TopicsPage.vue'),
  },
  {
    path: '/topic/:id',
    name: ROUTES_NAME.TOPIC_BY_ID,
    component: () => import(`@/views/Topics/TopicPage.vue`),
  },
  {
    path: '/search',
    name: ROUTES_NAME.SEARCH,
    component: () => import('@/views/Search/SearchPage.vue'),
  },
  {
    path: '/administration',
    name: ROUTES_NAME.ADMINISTRATION,
    component: () => import('@/views/Administration/AdministrationView.vue'),
    meta: { requiresAdminRights: true },
    redirect: { name: ROUTES_NAME.ADMIN_USAGE_RULES },
    children: [],
  },
  {
    path: '/usage-rules',
    name: ROUTES_NAME.USAGE_RULES,
    component: () => import('@/views/Networks/UsageRules/UsageRulesView.vue'),
  },
  {
    path: '/projects',
    name: ROUTES_NAME.PROJECTS,
    component: () => import('@/views/Projects/ProjectsPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/project/:projectId',
    name: ROUTES_NAME.PROJECT_BY_ID,
    component: () => import('@/views/Projects/ProjectPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/project/:projectId/milestones',
    name: ROUTES_NAME.MILESTONES,
    component: () => import('@/views/Projects/MilestonesPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  /* {
    path: '/projects/statistics',
    name: ROUTES_NAME.PROJECTS_STATISTICS,
    component: () => import('@/views/Projects/StatisticsPage.vue'),
    meta: { requiresProjectsPermission: true },
  }, */
  {
    path: '/tasks',
    name: ROUTES_NAME.TASKS,
    component: () => import('@/views/Projects/TasksPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/settings',
    name: ROUTES_NAME.SETTINGS,
    component: () => import('@/views/Settings/SettingsPage.vue'),
  },
  /* {
    path: '/project/:projectId/milestone/:milestoneId',
    name: ROUTES_NAME.MILESTONE_BY_ID,
    component: () => import('@/views/Projects/MilestonePage.vue'),
  }, */
  {
    path: '/office/:payload',
    name: ROUTES_NAME.OFFICE,
    component: () => import('@/views/Office/OfficeView.vue'),
    props: (route) => ({ ...route.params, ...route.query }),
  },
  {
    path: '/ai-assistant',
    name: ROUTES_NAME.AI_ASSISTANT,
    component: () => import('@/views/AiAssistant/AiAssistantPage.vue'),
    meta: { requiresAiAssistantPermission: true },
  },

  ...(import.meta.env.DEV
    ? [
        {
          path: '/networks',
          name: ROUTES_NAME.NETWORKS,
          component: () => import('@/views/Networks/NetworksPage.vue'),
        },
      ]
    : []),
  {
    path: '/loading',
    name: ROUTES_NAME.LOADING,
    component: () => import('@/views/Common/LoadingPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: ROUTES_NAME.NOT_FOUND,
    component: () => import('@/views/Common/NotFound.vue'),
  },
  /*
  {
    path: '/document/:fileName',
    name: ROUTES_NAME.DOCUMENT_VIEW,
    component: () => import('@/views/Docs/DocumentViewer.vue'),
  },
  */
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from): Promise<boolean> => {
  const { handleError } = useErrors();
  const isAndroid = isPlatform('android') && !isPlatform('mobileweb');

  const appStore = useAppStore();
  // let clearCacheException = false;

  if (!appStore.isAuth) {
    if (to.name !== ROUTES_NAME.LOGIN && to.name !== ROUTES_NAME.REGISTRATION && to.name !== ROUTES_NAME.ACTIVATION) {
      await router.push({ name: ROUTES_NAME.LOGIN });
      return false;
    }
  }

  if (to.name === ROUTES_NAME.WIKI_EDIT) {
    const preventRoute = await useRichTextHelper().checkIsAdvancedWiki();
    if (preventRoute) {
      return false;
    }
  }

  try {
    if (to.query.clearCache === 'true') {
      const trimmedUrl = to.fullPath.replace(/\?clearCache=true/g, '');
      // clearCacheException = true;
      to.fullPath = trimmedUrl;
    }

    if (appStore.isMiddleButtonClicked) {
      appStore.$patch((state) => {
        state.isMiddleButtonClicked = false;
      });

      window.open(`${to.fullPath}`, '_blank');

      return false;
    }

    // If the user is on the root '/' (home) from the home page
    if (to.name === ROUTES_NAME.HOME && from.name === appStore.homePage.name) {
      if (isAndroid) {
        await App.exitApp();
        return false;
      } else {
        await router.push(appStore.homePage);
        return true;
      }
    }

    /* Disabling for now
    if (
      to.fullPath === from.fullPath &&
      to.name === from.name &&
      !clearCacheException
    ) {
      // If the destination route is the same as the current route
      console.error('The destination route is the same as the current route');
      return false;
    }
    */

    return true;
  } catch (error) {
    const eText = `Error while performing route navigation. Failed to navigate from ${String(from.name)} to ${String(to.name)}`;
    handleError(false, error, eText);
    return false;

    /*
    TODO: Redirect to an error page
    next('/error');
    */
  }
});

router.beforeResolve(async (to) => {
  const { handleError } = useErrors();
  const taskManagementHelper = useTaskManagementHelper();
  const aiAssistantHelper = useAiAssistantHelper();

  const userStore = useUserStore();
  const userRole = userStore.current?.roleId ?? 0;

  if (await useRequirementsHelper().requirementsCheckInProcess()) {
    const { t } = useI18n();
    handleError(true, undefined, t('read.required'));
    return false;
  }

  if (
    (to.meta.requiresAdminRights && userRole < UserRoleEnum.Administrator) ||
    (to.meta.requiresStandardUserRights && userRole < UserRoleEnum.User) ||
    (to.meta.requiresProjectsPermission && !taskManagementHelper.getAccessToTaskManagement()) ||
    (to.meta.requiresAiAssistantPermission && !aiAssistantHelper.getAccessToAiAssistant())
  ) {
    router.push({ name: ROUTES_NAME.FEED });
  } else {
    return true;
  }
});

router.afterEach((to, from, failure) => {
  const { handleError } = useErrors();

  if (failure) {
    const eText = `Error while performing route navigation. Failed to navigate from ${String(from.name)} to ${String(to.name)}`;
    handleError(false, failure, eText);
  }
});

export default router;
