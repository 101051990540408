import type {
  RequestCallToGroupModel,
  RequestCallToUserModel,
  ResponseMeetChainModel,
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponseTokenModel,
  RequestAnswerToUserModel,
  ResponseMeetModel,
  RequestAnswerToGroupModel,
  ResponseCallToUserModel,
  ResponseParticipantsModel,
} from '@/@types';
import axios from '@/services/axios';

export class MeetApiService {
  async getToken(name: string, roomId: string): Promise<ResponseTokenModel | ResponseErrorModel> {
    return axios.post('/meet/token', {
      name,
      room: roomId,
    });
  }
  async getActiveParticipants(chainId: number): Promise<ResponseParticipantsModel | ResponseErrorModel> {
    return axios.get(`/meet/participants/${chainId}`);
  }
  async chainInfo(chainId: number): Promise<ResponseMeetChainModel | ResponseErrorModel> {
    return axios.get(`/meet/chain/${chainId}`);
  }
  async callToUser(data: RequestCallToUserModel): Promise<ResponseCallToUserModel | ResponseErrorModel> {
    return axios.post('/meet/call', data);
  }
  async answerToUser(data: RequestAnswerToUserModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/meet/result', data);
  }
  async callToGroup(data: RequestCallToGroupModel): Promise<ResponseMeetModel | ResponseErrorModel> {
    return axios.post('/meet/callToGroup', data);
  }
  async answerToGroup(data: RequestAnswerToGroupModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/meet/answerToGroup', data);
  }
  async invite(
    connectionId: string,
    roomName: string,
    withVideo: boolean
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/meet/invite', {
      toUserConnectionId: connectionId,
      roomName: roomName,
      withVideo: withVideo,
    });
  }
  async reject(roomId: string): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/meet/reject', {
      roomName: roomId,
    });
  }
}
