import { modalController } from '@ionic/vue';
import { ref } from 'vue';

import { componentForceReadModal, componentAcceptUsageRulesModal, componentPasswordChange } from './modalComponents';

import { RequirementsEnum } from '@/@enums';
import type { ResponseRulesModel, UserCurrentModel } from '@/@types';
import { useErrors } from '@/helpers';
import { useI18n } from '@/i18n';
import { $api } from '@/services';
import { useAppStore, useNetworkStore, usePostStore, useUserStore } from '@/store';

interface IRequirements {
  check(mode: RequirementsEnum): Promise<boolean | void>;
  requirementsCheckInProcess(): Promise<boolean>;
}

export function useRequirementsHelper(): IRequirements {
  const appStore = useAppStore();
  const networkStore = useNetworkStore();
  const postStore = usePostStore();
  const userStore = useUserStore();
  const { t } = useI18n();
  const current = ref<UserCurrentModel | null>(null);

  const check = async (mode: RequirementsEnum): Promise<boolean | void> => {
    const { handleError } = useErrors();

    if (!appStore.isAuth) {
      console.error('🚀 ~ [Requirements check] Error checking process - no authorization');
      return false;
    }

    //NOTE: If the network change process is running, skip the script
    if (networkStore.settingNetwork) return;

    //NOTE: If the checking process already is running, skip the script
    if (await requirementsCheckInProcess()) return;

    current.value = await userStore.currentUser();
    if (!current.value) {
      console.error('🚀 ~ [Requirements check] Error checking process - no user');
      return false;
    }

    let usageRulesStatus = true;
    let passwordStatus = true;
    let forceToReadStatus = true;

    switch (mode) {
      case RequirementsEnum.All:
        usageRulesStatus = await _checkUsageRulesFromUserData();
        passwordStatus = await _checkMustChangePassword();
        forceToReadStatus = await _checkAllForcedAsRead();
        break;
      case RequirementsEnum.UsageRules:
        usageRulesStatus = await _checkUsageRulesFromUserData();
        break;
      case RequirementsEnum.Password:
        passwordStatus = await _checkMustChangePassword();
        break;
      case RequirementsEnum.ForceToRead:
        forceToReadStatus = await _checkAllForcedAsRead();
        break;
    }

    if (!usageRulesStatus || !passwordStatus || !forceToReadStatus) {
      handleError(true, undefined, `[Requirements check] The process has been finalized - ${t('reqNotMet')}`);
    }

    return usageRulesStatus && passwordStatus && forceToReadStatus;
  };

  //NOTE: Check that the network rules are accepted
  const _checkUsageRulesFromUserData = async (): Promise<boolean> => {
    const { handleError } = useErrors();

    if (current.value?.isPolicyAccepted) return true;

    const response = await $api.user.getUsageRules();
    if (response.statusCode === 200) {
      const { data } = response as ResponseRulesModel;
      const acceptData = await componentAcceptUsageRulesModal(data.title, data.text);
      return acceptData.data ?? false;
    } else {
      handleError(true, response, t('administration.usageRules.fetchError'));
      return false;
    }
  };

  //NOTE: Check that the password has been successfully changed
  const _checkMustChangePassword = async (): Promise<boolean> => {
    if (!current.value?.mustChangePassword) return true;

    const changeData = await componentPasswordChange(false);
    return changeData.data ?? false;
  };

  //NOTE: Check that all required posts have been read
  const _checkAllForcedAsRead = async (): Promise<boolean> => {
    const response = await postStore.postsRequiredForRead();
    if (!response.length) return true;

    const readData = await componentForceReadModal();
    return readData.data ?? false;
  };

  const requirementsCheckInProcess = async (): Promise<boolean> => {
    return await modalController.getTop().then(async (modal) => {
      return modal &&
        (modal?.id === 'usage-rules-modal' ||
          modal?.id === 'password-change-modal' ||
          modal?.id === 'force-to-read-modal')
        ? true
        : false;
    });
  };

  return {
    check,
    requirementsCheckInProcess,
  };
}
