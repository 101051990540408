export const API_CONSTANTS = {
  StorageName: 'current-api',
};

export const getServiceUrl = (): string => {
  if (import.meta.env.PROD) {
    return `${import.meta.env.VITE_APP_URL_CORE}`;
  } else {
    return localStorage.getItem(API_CONSTANTS.StorageName) || import.meta.env.VITE_APP_URL_CORE;
  }
};

export const setServiceUrl = (url: string): void => {
  localStorage.setItem(API_CONSTANTS.StorageName, url);
};
