<template>
  <div v-if="images.length > 0" class="images">
    <app-images-grid
      :images="images"
      :is-comment-flag="isCommentFlag"
      :base-grid-height="600"
      :base-mobile-grid-height="450"
    />
  </div>
  <div ref="videosRef">
    <div v-if="videos.length > 0" :class="isCommentFlag ? 'videos-comment' : 'videos'">
      <div v-for="item in videos" :key="item.key" :class="videos.length === 1 ? 'videos-item' : 'videos-items'">
        <media-video
          :id="item.key.split('~')[1]"
          :key="videoSize.width"
          :name="item.name"
          :url="item.apiUrl"
          :image="item.videoPreview?.url ?? ''"
          :mime-type="item.mimeType"
          :size="videoSize"
        />
      </div>
    </div>
  </div>
  <div v-if="files.length > 0" class="files">
    <ion-list mode="md">
      <file-info-conversations
        v-for="item in files"
        :key="item.key"
        :file="item"
        :disabled="whatIsLoading !== item?.key && fileIsLoading"
        @onLoading="onFileIsLoading"
      />
    </ion-list>
  </div>
</template>

<script lang="ts" setup>
import { IonList } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed, ref } from 'vue';

import type { FileModel } from '@/@types';
import { FileInfoConversations, MediaVideo, AppImagesGrid } from '@/components';
import { useAppStore } from '@/store';

const props = defineProps({
  filesData: {
    type: Object as PropType<FileModel[]>,
    required: true,
  },
  isCommentFlag: {
    type: Boolean,
    default: () => false,
  },
});

const appStore = useAppStore();

const isSMWidth: ComputedRef<boolean> = computed(() => appStore.isSMWidth);
const videosRef = ref<any>(null);

const filesData: ComputedRef<FileModel[]> = computed(() => props.filesData);

const files: ComputedRef<FileModel[]> = computed(() =>
  filesData.value
    ? filesData.value.filter(
        (f: any) =>
          (!f.mimeType.startsWith('image') || ['svg', 'tiff', 'tga'].includes(f.type)) &&
          !f.mimeType.startsWith('video') &&
          f.type !== 'avif'
      )
    : []
);

const images: ComputedRef<FileModel[]> = computed(() =>
  filesData.value
    ? filesData.value.filter(
        (f: any) => (f.mimeType.startsWith('image') || f.type === 'avif') && !['svg', 'tiff', 'tga'].includes(f.type)
      )
    : []
);

const videos: ComputedRef<FileModel[]> = computed(() => {
  if (filesData.value) {
    return filesData.value.filter((f: any) => f.mimeType.startsWith('video'));
  }
  return [];
});

const whatIsLoading = ref<string>('');
const fileIsLoading = ref<boolean>(false);
const onFileIsLoading = (isLoading: boolean, id: string) => {
  if (isLoading === true) {
    fileIsLoading.value = true;
    whatIsLoading.value = id;
  } else {
    fileIsLoading.value = false;
  }
};

const acceptForVideoResize: ComputedRef<boolean> = computed(
  () => !isSMWidth.value && videosRef.value !== null && videos.value.length > 0 && props.isCommentFlag === false
);

const videoSize: ComputedRef<{ width: number; height: number }> = computed(() =>
  !acceptForVideoResize.value ? { width: 256, height: 144 } : getVideoContainerSize()
);

const getVideoContainerSize = () => {
  if (window.innerWidth !== 0) {
    return {
      width: window.innerWidth - 32,
      height: ((window.innerWidth - 32) * 9) / 16,
    };
  }
  return { width: 256, height: 144 };
};
</script>
<style scoped lang="scss">
.swiper {
  position: relative;
}
.images {
  margin-top: 1rem;
}
.images:hover {
  cursor: pointer;
}
.videos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.videos-comment {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 0.5rem;
  flex-wrap: wrap;
}
.videos-items {
  margin-bottom: 0.5rem;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}
.videos-items:hover {
  cursor: pointer;
}
.videos-item:hover {
  cursor: pointer;
  opacity: 0.7;
}
ion-list {
  background: transparent;
}
</style>
