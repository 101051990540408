<template>
  <ion-footer ref="bottomMenuRef">
    <div class="app-bottom-menu">
      <div
        v-for="(item, index) in menuItems"
        v-show="item.enabled"
        :key="`menu-item_${index}`"
        class="item"
        :class="{
          active: checkForActive(item),
          'without-label': !menuWithLabels,
        }"
        @click="menuItemClick($event, item)"
      >
        <icons-provider
          class="icon"
          :icon-props="{
            width: '24',
            height: '24',
            fill: 'var(--ion-color-custom)',
          }"
          :name="item.icon"
        />
        <ion-label v-if="menuWithLabels">{{ item.title }}</ion-label>
      </div>
    </div>
  </ion-footer>
</template>

<script lang="ts" setup>
import { IonLabel, IonFooter } from '@ionic/vue';
import { useElementSize, useWindowFocus } from '@vueuse/core';
import type { ComponentPublicInstance, ComputedRef } from 'vue';
import { watch, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import type { AppMenuItems } from '@/@types';
import IconsProvider from '@/components/Common/IconsProvider.vue';
import { useMenuHelper, componentAppSubMenuPopover } from '@/helpers';
import { useAppStore } from '@/store';

const appStore = useAppStore();
const route = useRoute();
const bottomMenuRef = ref<ComponentPublicInstance | null>(null);
const { height } = useElementSize(bottomMenuRef);
const focused = useWindowFocus();

const menuWithLabels = ref<boolean>(true);

const menuHelper = useMenuHelper();
const menuItems: ComputedRef<AppMenuItems[]> = computed(() => menuHelper.getBottomMenu());

const router = useRouter();
const homePage: ComputedRef<any> = computed(() => appStore.homePage);

const checkForActive = (element: any) => {
  return (
    (homePage.value.params?.id &&
      element.link?.name === route.name &&
      homePage.value.params.id === Number(route.params.id)) ||
    (element.link?.name === route.name && !route.params?.id)
  );
};

const menuItemClick = async (ev: Event, item: AppMenuItems) => {
  if (item.submenu?.length) {
    await componentAppSubMenuPopover(ev, item.submenu, 'top');
  } else {
    if (item.link !== null) {
      await router.push(item.link);
    }
  }
};

watch(height, () => {
  appStore.$patch({ appBottomMenuHeight: height.value });
});

watch(focused, () => {
  appStore.$patch({ appBottomMenuHeight: height.value });
});
</script>

<style scoped lang="scss">
ion-footer {
  z-index: 1000;
  background: var(--ion-color-intra);
}
.app-bottom-menu {
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--ion-safe-area-bottom);
  box-sizing: border-box;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    opacity: 0.8;
    color: var(--ion-color-custom);
    padding-top: app-padding(md);
    padding-bottom: app-padding(sm);
    position: relative;
    transition: all 0.15s ease-in-out;
    &.active {
      opacity: 1;
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        background: var(--ion-color-custom);
        position: absolute;
        bottom: 0;
        border-radius: 1.5px;
      }
    }
    &.without-label {
      justify-content: center;
    }
    ion-label {
      font-size: 0.7rem;
    }
    .icon {
      margin-bottom: app-padding(sm);
    }
  }
}
</style>
