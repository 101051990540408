import { alertController } from '@ionic/vue';

import { useI18n } from '@/i18n';
import axios from '@/services/axios';
import { useNetworkStore } from '@/store';

interface IRichTextHelpers {
  _getHiddenElement(htmlString: string): HTMLElement;
  preprocessInternalLinks(element: HTMLElement): void;
  restoreInternalLinks(element: HTMLElement): void;
  preprocessVideo(element: HTMLElement): Promise<void>;
  restoreVideoProcessing(element: HTMLElement): void;
  preprocessBody(htmlString: string): Promise<string>;
  preSubmit(htmlString: string): string;
  checkIsAdvancedWiki(): Promise<boolean>;
}

export function useRichTextHelper(): IRichTextHelpers {
  const _getHiddenElement = (htmlString: string): HTMLElement => {
    const parsedJson = JSON.parse(JSON.stringify(htmlString));
    const tempElement = document.createElement('div');
    tempElement.style.visibility = 'hidden';
    tempElement.innerHTML = parsedJson;

    return tempElement;
  };

  const preprocessInternalLinks = (element: HTMLElement): void => {
    const pattern = /^[ugt]:\d+$/;
    const internalLinks = Array.from(element.querySelectorAll('a')).filter((link) =>
      pattern.test(link.getAttribute('href') || '')
    );

    for (const link of internalLinks) {
      const href = link.getAttribute('href');
      const [identifier, id] = href?.split(':') as unknown as string;
      const routerNameMapper = {
        u: `/user/${id}`,
        g: `/group/${id}`,
        t: `/topic/${id}`,
      };
      link.setAttribute('href', routerNameMapper[identifier as 'u' | 'g' | 't']);
    }
  };

  const restoreInternalLinks = (element: HTMLElement): void => {
    const routerPathMatcher = /^\/(user|group|topic)\/(\d+)$/;
    const internalLinks = Array.from(element.querySelectorAll('a')).filter((link) =>
      routerPathMatcher.test(link.getAttribute('href') || '')
    );

    for (const link of internalLinks) {
      const href = link.getAttribute('href');
      const matchResult = href?.match(routerPathMatcher);
      if (matchResult) {
        const [, routerName, id] = matchResult;
        const routerIdentifierMapper = {
          '/user': 'u',
          '/group': 'g',
          '/topic': 't',
        };
        const identifier = routerIdentifierMapper[`/${routerName}` as '/user' | '/group' | '/topic'];
        if (identifier) {
          link.setAttribute('href', `${identifier}:${id}`);
        }
      }
    }
  };

  const preprocessVideo = async (element: HTMLElement): Promise<void> => {
    const medias = element.querySelectorAll('video');

    for (const media of medias) {
      const sourceElement = media.querySelector('source');

      if (sourceElement) {
        const videoUrl = sourceElement.getAttribute('src');

        const response = await axios.get(videoUrl as string, {
          responseType: 'blob',
        });

        sourceElement.setAttribute('data-original-src', videoUrl as string);
        const blobUrl = URL.createObjectURL(response as unknown as Blob);
        sourceElement.setAttribute('src', blobUrl);
      }
    }
  };

  const restoreVideoProcessing = (element: HTMLElement): void => {
    const medias = element.querySelectorAll('video');

    for (const media of medias) {
      const sourceElement = media.querySelector('source');

      if (sourceElement) {
        const originalVideoUrl = sourceElement.getAttribute('data-original-src') || sourceElement.getAttribute('alt');

        if (originalVideoUrl) {
          const regex = /filename=([^&]+)/;
          const match = originalVideoUrl.match(regex);

          if (match?.[1]) {
            const filename = match[1];

            sourceElement.setAttribute('src', `i:${filename}`);
            sourceElement.removeAttribute('data-original-src');
          }
        }
      }
    }
  };

  const preprocessBody = async (htmlString: string): Promise<string> => {
    const tempElement = _getHiddenElement(htmlString);

    /*
    TODO: delete if images get without auth token
    const imageTags = tempElement.querySelectorAll('img');

    for (const image of imageTags) {
      try {
        const response = await axios.get(image.alt, {
          responseType: 'blob',
        });

        const blobUrl = URL.createObjectURL(response as unknown as Blob);
        image.setAttribute('data-remote-url', image.alt);
        image.setAttribute('src', blobUrl);
      } catch (error) {
        console.error('Image fetch error:', error);
      }
    }
    */

    preprocessInternalLinks(tempElement);
    await preprocessVideo(tempElement);
    return tempElement.innerHTML;
  };

  const preSubmit = (htmlString: string): string => {
    const tempElement = _getHiddenElement(htmlString);

    const imageTags = tempElement.querySelectorAll('img');

    for (const image of imageTags) {
      const remoteUrl = image.getAttribute('data-remote-url') || image.getAttribute('alt');

      const regex = /\/media\/image/;
      const match = regex.test(remoteUrl || '');

      if (match) {
        image.setAttribute('src', remoteUrl as string);
        image.removeAttribute('data-remote-url');
      }
    }

    restoreInternalLinks(tempElement);
    restoreVideoProcessing(tempElement);
    return tempElement.innerHTML;
  };

  const checkIsAdvancedWiki = async (): Promise<boolean> => {
    const { t } = useI18n();
    const isWikiAdvancedOn = useNetworkStore()?.settings?.isAdvancedWikiesEditor;

    if (isWikiAdvancedOn) {
      const isAdvanced = await alertController.create({
        header: t('wiki.advancedMode.title'),
        message: t('wiki.advancedMode.message'),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert_buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await isAdvanced.present();

      return isAdvanced.onDidDismiss().then(() => {
        return true;
      });
    }

    return false;
  };

  return {
    _getHiddenElement,
    preprocessInternalLinks,
    restoreInternalLinks,
    preprocessVideo,
    restoreVideoProcessing,
    preprocessBody,
    preSubmit,
    checkIsAdvancedWiki,
  };
}
