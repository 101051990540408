// import * as SentryBrowser from '@sentry/browser';
import * as Sentry from '@sentry/capacitor';
import * as SentrySibling from '@sentry/vue';
import type { App } from 'vue';

import packageJson from '../../package.json';
// import router from '../router';

import { useErrors, useUserFlow } from '@/helpers';
// import { useI18n } from '@/i18n';
import { useUserStore } from '@/store';

export const initSentry = (app: App<Element>): void => {
  try {
    console.log('Sentry init is', import.meta.env.MODE);
    // const { t } = useI18n();
    const userStore = useUserStore();
    const userFlow = useUserFlow();
    const id = userStore?.current?.id;
    const currentUser = {
      name: (!id ? userFlow?.localUser?.fullName : userStore.getUserProfile(id).fullName) ?? 'Anonymous',
      email: (!id ? userFlow?.localUser?.email : userStore.getUserProfile(id).email) ?? 'Anonymous',
    };

    // https://docs.sentry.io/platforms/javascript/guides/vue/user-feedback/configuration/
    const feedback = SentrySibling.feedbackIntegration({
      autoInject: false,
      showBranding: false,
      colorScheme: 'system',
      isNameRequired: true,
      isEmailRequired: true,
      showName: true,
      showEmail: true,
      useSentryUser: currentUser,
      buttonLabel: 'Report a Bug', //TODO: t('sentryWidget.buttonName'),
      formTitle: 'Report a Bug', //TODO: t('sentryWidget.formTitle'),
      submitButtonLabel: 'Send Bug Report', //TODO: t('sentryWidget.submitButtonLabel'),
      cancelButtonLabel: 'Cancel', //TODO: t('sentryWidget.cancelButtonLabel'),
      nameLabel: 'Name', //TODO: t('sentryWidget.nameLabel'),
      namePlaceholder: 'Your Name', //TODO: t('sentryWidget.namePlaceholder'),
      emailLabel: 'Email', //TODO: t('sentryWidget.emailLabel'),
      isRequiredLabel: '(required)', //TODO: t('sentryWidget.isRequiredLabel'),
      messageLabel: 'Description', //TODO: t('sentryWidget.messageLabel'),
      messagePlaceholder: `What's the bug? What did you expect?`, //TODO: t('sentryWidget.messagePlaceholder'),
      successMessageText: 'Thank you for your report!', //TODO: t('sentryWidget.successMessageText'),
      // themeLight: {};
      // themeDark: {};
      // onFormClose: () => {},
      // onFormOpen: () => {},
      onSubmitSuccess: async () => {
        await useErrors().handleSuccessfulSubmit();
      },
      onSubmitError: async () => {
        console.error('Feedback form error'); //! DEBUG
        // await useErrors().localReport();
      },
    });

    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/replay/
    const replay = SentrySibling.replayIntegration({
      stickySession: true,
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [/.*/],
      networkRequestHeaders: [
        'Accept-Language',
        'BrowserPlatforms',
        'CapacitorPlatform',
        'IsNativePlatform',
        'ApiVersion',
      ],
      networkResponseHeaders: [],
    });

    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/browsertracing/
    const browserTracing = SentrySibling.browserTracingIntegration({
      idleTimeout: 1000,
    });

    Sentry.init(
      {
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `intra-ionic@${packageJson.version}${import.meta.env.VITE_APP_STAGE}`,
        dist: `${packageJson.build}`,
        environment: import.meta.env.DEV ? 'dev' : 'production',
        logErrors: true,
        replaysSessionSampleRate: 0.25,
        replaysOnErrorSampleRate: 1.0,
        trackComponents: true,
        tracesSampleRate: 1.0,
        integrations: [feedback, replay, browserTracing],
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async beforeSend(event, hint) {
          useErrors().setFeedbackWidget(feedback);
          await useErrors().setSentryEvent(event);
          return event;
        },
      },
      SentrySibling.init
    );
  } catch (error) {
    console.error('Error initializing Sentry:', error);
  }
};

export { Sentry, SentrySibling };
