import type {
  ResponseErrorModel,
  ResponseNetworkModel,
  ResponseNetworkSettingModel,
  ResponseNetworkResourcesModel,
  NetworkLocateModel,
  ResponseNetworkLocateModel,
} from '@/@types';
import axios from '@/services/axios';

export class NetworksApiService {
  async getLocate(uri: string, clientSecret: string): Promise<NetworkLocateModel | undefined> {
    let settings: NetworkLocateModel | undefined = undefined;

    const api = import.meta.env.VITE_APP_URL_API;
    const env = import.meta.env.VITE_APP_ENV; // alfa / beta
    const url = `${uri}${api}/networks/locate/?clientSecret=${clientSecret}&env=${env}`;

    await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response.json().then((responseToken: ResponseNetworkLocateModel) => {
            settings = responseToken.data;
          });
        } else {
          console.log(response.status);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });

    return settings;
  }

  async settings(clientSecret: string): Promise<ResponseNetworkSettingModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/?clientSecret=${clientSecret}`);
  }

  async current(clientSecret: string): Promise<ResponseNetworkModel | ResponseErrorModel> {
    return axios.get(`/networks/current/?clientSecret=${clientSecret}`);
  }

  async getResources(clientSecret: string): Promise<ResponseNetworkResourcesModel | ResponseErrorModel> {
    return axios.get(`/networks/resources?clientSecret=${clientSecret}&mobile=true`);
  }
}
