import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useAiAssistantHelper } from './useAiAssistantHelper';
import { useTaskManagementHelper } from './useTaskManagementHelper';

import { AppMenuEnum, UserRoleEnum } from '@/@enums';
import type { AppMenuItems, ProjectEntity } from '@/@types';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useAppStore, useProjectsStore, useUserStore } from '@/store';

interface IMenuHelper {
  getHeaderMenu(): AppMenuItems[];
  getRightMenu(): AppMenuItems[];
  getBottomMenu(): AppMenuItems[];
  getBottomAdditionalMenu(): AppMenuItems[];
  getMenuHiddenItemsForCompanies(): AppMenuEnum[];
  isCampusEnabled(): boolean;
  isQuickSearchEnabled(): boolean;
}

export function useMenuHelper(): IMenuHelper {
  const appStore = useAppStore();
  const maxItemsInHeaderMenu: ComputedRef<number> = computed(() => appStore.maxItemsInHeaderMenu);
  const maxItemsInBottomMenu = 4;

  const getMenuItems = (): AppMenuItems[] => {
    const { t } = useI18n();
    const userStore = useUserStore();
    const projectsStore = useProjectsStore();
    const aiAssistantHelper = useAiAssistantHelper();
    const taskManagementHelper = useTaskManagementHelper();

    const homePage: ComputedRef<any> = computed(() => appStore.homePage);
    const aiAssistantEnabled: ComputedRef<boolean> = computed(() => aiAssistantHelper.getAccessToAiAssistant());
    const taskManagementEnabled: ComputedRef<boolean> = computed(() =>
      taskManagementHelper.getAccessToTaskManagement()
    );
    const currentProject: ComputedRef<ProjectEntity> = computed(() => projectsStore.getCurrentProject);

    const hiddenItemsForCompanies: ComputedRef<AppMenuEnum[]> = computed(() => getMenuHiddenItemsForCompanies());

    const currentUserRoleId: ComputedRef<number> = computed(() => userStore.current?.roleId ?? 0);

    // Specifically for Klinikum-Erding - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1309
    const currentUserIsKEDummy: ComputedRef<boolean> = computed(
      () => userStore.current?.id == import.meta.env.VITE_KLINIKUM_ERDING_ID //? && appStore.companyRowId === import.meta.env.VITE_KLINIKUM_ERDING_COMPANY_ID
    );

    const data = [
      {
        name: AppMenuEnum.HomePage,
        title: t('appBottomMenu.home'),
        link: homePage.value,
        icon: 'home',
        enabled: homePage.value.name !== ROUTES_NAME.FEED,
        submenu: null,
      },

      {
        name: AppMenuEnum.Feed,
        title: t('appMenu.feed'),
        link: { name: ROUTES_NAME.FEED },
        icon: 'feed',
        enabled: true,
        submenu: null,
      },

      {
        name: AppMenuEnum.Messenger,
        title: t('appMenu.messenger'),
        link: { name: ROUTES_NAME.MESSENGER_ACTIVE },
        icon: 'chats',
        enabled: !currentUserIsKEDummy.value,
        submenu: null,
      },

      {
        name: AppMenuEnum.People,
        title: t('appMenu.people'),
        link: { name: ROUTES_NAME.USERS },
        icon: 'people',
        activeIcon: '',
        enabled: !currentUserIsKEDummy.value,
        submenu: null,
      },

      {
        name: AppMenuEnum.Groups,
        title: t('appMenu.groups'),
        link: { name: ROUTES_NAME.GROUPS },
        icon: 'spaces',
        enabled: true,
        submenu: null,
      },

      {
        name: AppMenuEnum.Search,
        title: t('appMenu.search'),
        link: { name: ROUTES_NAME.SEARCH },
        icon: 'zoom',
        enabled: !currentUserIsKEDummy.value && !isQuickSearchEnabled(),
        submenu: null,
      },

      {
        name: AppMenuEnum.AiAssistant,
        title: t('appMenu.aiAssistant'),
        link: { name: ROUTES_NAME.AI_ASSISTANT },
        icon: 'ai-assistant',
        enabled: aiAssistantEnabled.value && !currentUserIsKEDummy.value,
        submenu: null,
      },

      {
        name: AppMenuEnum.Projects,
        title: t('appMenu.projects'),
        link: { name: ROUTES_NAME.PROJECTS },
        icon: 'projects',
        enabled: taskManagementEnabled.value,
        submenu: [
          {
            name: AppMenuEnum.Projects,
            title: t('taskManagement.projects.myProjects'),
            link: { name: ROUTES_NAME.PROJECTS },
            icon: 'layers',
            enabled: !currentUserIsKEDummy.value,
            submenu: null,
          },
          {
            name: AppMenuEnum.Tasks,
            title: t('taskManagement.tasks.myTasks'),
            link: { name: ROUTES_NAME.TASKS },
            icon: 'copy',
            enabled: !currentUserIsKEDummy.value,
            submenu: null,
          },
          {
            name: AppMenuEnum.Milestones,
            title: t('taskManagement.milestones.all'),
            link: {
              name: ROUTES_NAME.MILESTONES,
              params: { projectId: currentProject.value.id },
            },
            icon: 'milestones',
            enabled: currentProject.value.id > 0 && !currentUserIsKEDummy.value,
            submenu: null,
          },
          /* {
            name: AppMenuEnum.ProjectsStatistics,
            title: t('statistics.title'),
            link: { name: ROUTES_NAME.PROJECTS_STATISTICS },
            defaultIcon: icons.layersOutline,
            activeIcon: icons.layers,
            enabled: true,
            submenu: null,
          }, */
        ],
      },

      {
        name: AppMenuEnum.Docs,
        title: t('appMenu.docs'),
        link: { name: ROUTES_NAME.DOCS },
        icon: 'documents',
        enabled: !currentUserIsKEDummy.value,
        submenu: null,
      },

      /*
      {
        name: AppMenuEnum.Wiki,
        title: t('appMenu.docs'),
        link: { name: ROUTES_NAME.WIKIS },
        defaultIcon: icons.folderOpenOutline,
        activeIcon: icons.folderOpen,
        enabled: !currentUserIsKEDummy.value,
        submenu: null,
      },
      */

      {
        name: AppMenuEnum.Calendar,
        title: t('appMenu.calendar'),
        link: { name: ROUTES_NAME.CALENDAR },
        icon: 'calendar',
        enabled: !currentUserIsKEDummy.value,
        submenu: null,
      },

      {
        name: AppMenuEnum.Pages,
        title: t('appMenu.pages'),
        link: { name: ROUTES_NAME.PAGES },
        icon: 'pages',
        enabled: currentUserRoleId.value >= UserRoleEnum.User,
        submenu: null,
      },

      {
        name: AppMenuEnum.Topics,
        title: t('appMenu.topics'),
        link: { name: ROUTES_NAME.TOPICS },
        icon: 'tags',
        // Switched off for display in the menu according to the discussion on the call 30.01.24
        // Switched on for display in the menu according to the discussion on the call 12.04.24
        enabled: !currentUserIsKEDummy.value,
        submenu: null,
      },

      {
        name: AppMenuEnum.Ideas,
        title: t('appMenu.ideas'),
        link: { name: ROUTES_NAME.IDEAS },
        icon: 'idea',
        enabled: true,
        submenu: null,
      },

      {
        name: AppMenuEnum.UsageRules,
        title: t('appMenu.usageRules'),
        link: { name: ROUTES_NAME.USAGE_RULES },
        icon: 'usageRules',
        enabled: true,
        submenu: null,
      },

      {
        name: AppMenuEnum.Login,
        title: t('appMenu.login'),
        link: null,
        icon: 'login',
        enabled:
          appStore.companyRowId === import.meta.env.VITE_KLINIKUM_ERDING_COMPANY_ID && currentUserIsKEDummy.value,
        submenu: null,
      },
    ] as AppMenuItems[];

    return data.filter((n) => n.enabled && !hiddenItemsForCompanies.value.includes(n.name));
  };

  const getHeaderAdditionalMenu = (): AppMenuItems[] => {
    const data = [...getMenuItems().slice(maxItemsInHeaderMenu.value - 1)] as AppMenuItems[];
    return data.filter((n) => n.enabled);
  };

  const getHeaderMenu = (): AppMenuItems[] => {
    const { t } = useI18n();
    const items = getMenuItems();

    if (items.length <= maxItemsInHeaderMenu.value) {
      return items;
    } else {
      return [
        ...getMenuItems().splice(0, maxItemsInHeaderMenu.value - 1),
        {
          name: AppMenuEnum.More,
          title: t('appMenu.more'),
          link: null,
          icon: 'more',
          enabled: true,
          submenu: getHeaderAdditionalMenu(),
        },
      ];
    }
  };

  const getBottomMenu = (): AppMenuItems[] => {
    const { t } = useI18n();
    const appStore = useAppStore();
    const isMDWidth: ComputedRef<boolean> = computed(() => appStore.isMDWidth);
    const data = !isMDWidth.value
      ? getMenuItems().length > maxItemsInBottomMenu
        ? [
            ...getMenuItems().splice(0, maxItemsInBottomMenu),
            {
              name: AppMenuEnum.More,
              title: t('appMenu.more'),
              link: null,
              icon: 'more',
              enabled: true,
              submenu: getBottomAdditionalMenu(),
            },
          ]
        : [...getMenuItems()]
      : ([
          ...getMenuItems().splice(0, maxItemsInHeaderMenu.value - 1),
          {
            name: AppMenuEnum.More,
            title: t('appMenu.more'),
            link: null,
            icon: 'more',
            enabled: true,
            submenu: getHeaderAdditionalMenu(),
          },
        ] as AppMenuItems[]);

    return data.filter((n) => n.enabled);
  };

  const getBottomAdditionalMenu = (): AppMenuItems[] => {
    const data = [...getMenuItems().slice(4)] as AppMenuItems[];
    return data.filter((n) => n.enabled);
  };

  const getRightMenu = (): AppMenuItems[] => {
    const { t } = useI18n();
    const userStore = useUserStore();
    const currentUserId: ComputedRef<number> = computed(() => userStore.current?.id ?? 0);

    const hiddenItemsForCompanies: ComputedRef<AppMenuEnum[]> = computed(() => getMenuHiddenItemsForCompanies());

    const data = [
      {
        name: AppMenuEnum.Profile,
        title: t('account.title'),
        link: {
          name: ROUTES_NAME.USER_BY_ID,
          params: { id: currentUserId.value },
        },
        icon: 'person-circle',
        enabled: true,
        submenu: null,
      },

      {
        name: AppMenuEnum.Settings,
        title: t('appMenu.settings'),
        link: { name: ROUTES_NAME.SETTINGS },
        icon: 'settings',
        enabled: true,
        submenu: null,
      },
    ] as AppMenuItems[];

    return data.filter((n) => n.enabled && !hiddenItemsForCompanies.value.includes(n.name));
  };

  const getMenuHiddenItemsForCompanies = (): AppMenuEnum[] => {
    const appStore = useAppStore();

    const companiesList: ComputedRef<{ [key: string]: string }> = computed(() => {
      if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS) {
        console.warn('The env variable VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS is not defined');
        return {};
      }

      try {
        return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS);
      } catch (error) {
        console.error('Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS env variable:', error);
        return {};
      }
    });

    const currentCompanyId: ComputedRef<string> = computed(() => appStore.companyRowId);

    for (const key in companiesList.value) {
      if (companiesList.value[key] === currentCompanyId.value) {
        switch (key) {
          case 'SHGT': {
            return [
              AppMenuEnum.Feed,
              AppMenuEnum.People,
              AppMenuEnum.Calendar,
              AppMenuEnum.Pages,
              AppMenuEnum.Topics,
              AppMenuEnum.UsageRules,
              AppMenuEnum.Docs,
              AppMenuEnum.Ideas,
            ];
          }

          case 'CAMPUS': {
            return [AppMenuEnum.Feed, AppMenuEnum.People, AppMenuEnum.Messenger, AppMenuEnum.Pages];
          }

          case 'ANDERSEN': {
            return [AppMenuEnum.Messenger, AppMenuEnum.Pages];
          }

          case 'VOEB': {
            return [
              AppMenuEnum.Feed,
              AppMenuEnum.People,
              AppMenuEnum.Messenger,
              AppMenuEnum.Calendar,
              AppMenuEnum.Projects,
              AppMenuEnum.ProjectsStatistics,
              AppMenuEnum.Tasks,
              AppMenuEnum.Milestones,
              AppMenuEnum.Search,
              AppMenuEnum.Topics,
              AppMenuEnum.Ideas,
              AppMenuEnum.Networks,
              AppMenuEnum.Pages,
              AppMenuEnum.UsageRules,
            ];
          }

          default:
            return [];
        }
      }
    }

    return [];
  };

  const isCampusEnabled = (): boolean => {
    const appStore = useAppStore();

    const companiesList: ComputedRef<{ [key: string]: string }> = computed(() => {
      if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS) {
        console.warn('The env variable VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS is not defined');
        return {};
      }

      try {
        return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS);
      } catch (error) {
        console.error('Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS env variable:', error);
        return {};
      }
    });

    const currentCompanyId: ComputedRef<string> = computed(() => appStore.companyRowId);

    if (Object.values(companiesList.value).includes(currentCompanyId.value)) return false;

    return true;
  };

  const isQuickSearchEnabled = (): boolean => {
    const appStore = useAppStore();

    const companiesList: ComputedRef<{ [key: string]: string }> = computed(() => {
      if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH) {
        console.warn('The env variable VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH is not defined');
        return {};
      }

      try {
        return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH);
      } catch (error) {
        console.error('Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH env variable:', error);
        return {};
      }
    });

    const currentCompanyId: ComputedRef<string> = computed(() => appStore.companyRowId);

    if (Object.values(companiesList.value).includes(currentCompanyId.value)) return false;

    return true;
  };

  return {
    getHeaderMenu,
    getRightMenu,
    getBottomMenu,
    getBottomAdditionalMenu,
    getMenuHiddenItemsForCompanies,
    isCampusEnabled,
    isQuickSearchEnabled,
  };
}
