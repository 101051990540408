import type { NetworkEntity } from '@/@types';

export const defaultNetwork: NetworkEntity = {
  id: '',
  title: '',
  logo: '',
  favicon: '',
  companyType: 0,
  hostWithSubHost: '',
  isHome: false,
  isOfficial: false,
  notificationCount: 0,
  webSocket: import.meta.env.VITE_APP_URL_SIGNALR,
  logoMobile: '',
};
