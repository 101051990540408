import type { OverlayEventDetail } from '@ionic/core';
import { alertController } from '@ionic/core';
import { lockClosed, star, shieldCheckmark, globeOutline } from 'ionicons/icons';

import { showToast } from './helper';

import {
  AllowExternalUsersToGroupEnum,
  AppCardsActionEnum,
  GroupInviteModeEnum,
  GroupsAccessEnum,
  GroupsTypeEnum,
  UserRoleEnum,
} from '@/@enums';
import type { AppActionButton, GroupEntity } from '@/@types';
import { useErrors } from '@/helpers';
import { useI18n } from '@/i18n';
import { useGroupsStore, useNetworkStore, useUserStore } from '@/store';

interface IGroups {
  getInviteModesToGroup(groupData: GroupEntity): { name: string; value: GroupInviteModeEnum }[];
  getInviteModeName(mode: GroupInviteModeEnum): string;
  getMainGroupAction(groupData: GroupEntity): {
    left: AppActionButton;
    right: AppActionButton;
  };
  onJoinGroup(id: number): Promise<void>;
  onLeaveGroup(id: number): Promise<void>;
  onSendRequest(id: number): Promise<void>;
  getGroupStats(groupData: GroupEntity): string;
  userIsJoined(groupData: GroupEntity): boolean;
  getGroupInfoAccess(groupId: number): boolean;
  isGroupAdmin(groupId: number): boolean;
  getGroupType(groupId: number): string;
  getGroupIcon(groupId: number): string | null;
  getGroupIconByData(isMandant: boolean, isOfficial: boolean, type: GroupsTypeEnum): string;
}

export function useGroupsHelper(): IGroups {
  const icons = {
    lock: lockClosed,
    star: star,
    shield: shieldCheckmark,
    globe: globeOutline,
  };
  // Возвращает способы приглашения в группу, зависят от ролей
  const getInviteModesToGroup = (groupData: GroupEntity): { name: string; value: GroupInviteModeEnum }[] => {
    const userStore = useUserStore();
    const networkStore = useNetworkStore();

    const allowExternalUsersToGroup: AllowExternalUsersToGroupEnum =
      networkStore.settings?.allowExternalUsersToGroup ?? AllowExternalUsersToGroupEnum.Off;

    const currentUserId: number = userStore.current?.id ?? 0;

    const currentUserRoleId: number = userStore.current?.roleId ?? 0;

    const currentUserIsAdmin: boolean = userStore.current ? currentUserRoleId >= UserRoleEnum.Administrator : false;

    const currentUserIsGroupAdmin: boolean = groupData.adminIds.includes(currentUserId);

    const modes = [];

    // Можно добавить в группу если юзер админ группы
    const caseOne = currentUserIsGroupAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью админа сети и одновременно админа группы
    const caseTwo =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.NetworkAdmins &&
      currentUserIsGroupAdmin &&
      currentUserIsAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью админа группы
    const caseThree =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.GroupAdmins && currentUserIsGroupAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью от 30
    const caseFour =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.All && currentUserRoleId >= UserRoleEnum.User;

    if (caseOne) {
      modes.push(
        {
          name: getInviteModeName(GroupInviteModeEnum.AddById),
          value: GroupInviteModeEnum.AddById,
        },
        {
          name: getInviteModeName(GroupInviteModeEnum.AddByEmail),
          value: GroupInviteModeEnum.AddByEmail,
        }
      );
    }

    if (caseTwo || caseThree || caseFour) {
      modes.push({
        name: getInviteModeName(GroupInviteModeEnum.AddExternal),
        value: GroupInviteModeEnum.AddExternal,
      });
    }

    return modes;
  };

  // Возвращает строку способа приглашения в группу
  const getInviteModeName = (mode: GroupInviteModeEnum): string => {
    const { t } = useI18n();
    switch (mode) {
      case GroupInviteModeEnum.AddById:
        return t('groupPage.manageModal.invite.byId');
      case GroupInviteModeEnum.AddByEmail:
        return t('groupPage.manageModal.invite.byEmail');
      case GroupInviteModeEnum.AddExternal:
        return t('groupPage.manageModal.invite.external');
    }
    return '';
  };

  const onJoinGroup = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();

    if (await groupStore.onJoin(id)) {
      await showToast(t('groupPage.youAreJoined'), true);
    } else {
      handleError(true, undefined, t('groupPage.notJoined'));
    }
  };

  const onLeaveGroup = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(id);

    const result = group.stats.members <= 1 ? await leaveConfirm(group, true) : await leaveConfirm(group, false);
    if (result) {
      if (await groupStore.onLeave(group.id, group.stats.members <= 1)) {
        await showToast(t('groupPage.youAreLeft', { group: group.title }), true);
      } else {
        handleError(true, undefined, t('groupPage.notLeft', { group: group.title }));
      }
    }
  };

  const onSendRequest = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();

    if (await groupStore.onJoin(id)) {
      await showToast(t('groupPage.requestIsSend'), true);
    } else {
      handleError(true, undefined, t('groupPage.requestNotSend'));
    }
  };

  const leaveConfirm = async (groupData: GroupEntity, lastUser: boolean): Promise<boolean> => {
    const userStore = useUserStore();
    const { t } = useI18n();
    const currentUserRoleId: number = userStore.current?.roleId ?? 0;

    const alert = await alertController.create({
      message:
        lastUser && currentUserRoleId >= 30
          ? t('groupPage.leaveConfirmIsLast')
          : t('groupPage.leaveConfirm', { group: groupData.title }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert_buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert_buttons',
          role: 'confirm',
        },
      ],
    });
    await alert.present();

    return alert.onDidDismiss().then(async (result: OverlayEventDetail<boolean>) => {
      return result.role === 'confirm';
    });
  };

  const userIsJoined = (groupData: GroupEntity): boolean => {
    return groupData.accessType >= GroupsAccessEnum.Member;
  };

  const getMainGroupAction = (groupData: GroupEntity): { left: AppActionButton; right: AppActionButton } => {
    const { t } = useI18n();
    const userIsSendRequest = (): boolean => groupData.accessType === GroupsAccessEnum.SendRequest;
    const isJoined = userIsJoined(groupData);
    const actionData: { left: AppActionButton; right: AppActionButton } = {
      left: {
        title: '',
        type: 'main',
        action: AppCardsActionEnum.None,
        icon: '',
      },
      right: {
        title: '',
        type: 'main',
        action: AppCardsActionEnum.None,
        icon: '',
      },
    };

    if (groupData.type !== GroupsTypeEnum.Public && !isJoined) {
      actionData.right.title = userIsSendRequest() ? t('groupPage.requestIsSend') : t('groupPage.sendRequest');
      actionData.right.type = userIsSendRequest() ? 'secondary' : 'main';
      actionData.right.action = userIsSendRequest()
        ? AppCardsActionEnum.OpenRequested
        : AppCardsActionEnum.GroupSendRequest;
      actionData.right.icon = userIsSendRequest() ? 'userTime' : 'userCheck';
    } else {
      actionData.right.title = isJoined ? t('open') : t('groupPage.join');
      actionData.right.type = isJoined ? 'main' : 'main';
      actionData.right.action = isJoined ? AppCardsActionEnum.Open : AppCardsActionEnum.GroupJoin;
      actionData.right.icon = isJoined ? '' : 'userCheck';

      actionData.left.title = isJoined ? t('groupPage.leave') : '';
      actionData.left.type = isJoined ? 'secondary' : '';
      actionData.left.action = isJoined ? AppCardsActionEnum.GroupLeave : AppCardsActionEnum.None;
      actionData.left.icon = isJoined ? 'userDeleteCross' : '';
    }

    return actionData;
  };

  const getGroupStats = (groupData: GroupEntity): string => {
    const { t } = useI18n();
    const title = [];
    if (groupData.stats.members !== 0) {
      title.push(t('appLists.members', groupData.stats.members));
    }
    if (groupData.stats.messages !== 0) {
      title.push(t('appLists.posts', groupData.stats.messages));
    }
    return title.join(' &#8729; ');
  };

  const getGroupInfoAccess = (groupId: number) => {
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);
    return group.showInformation;
  };

  const isGroupAdmin = (groupId: number) => {
    const userStore = useUserStore();
    const groupStore = useGroupsStore();
    const currentUserRoleId = userStore.current?.roleId ?? 0;
    const currentUserId = userStore.getId ?? null;

    const groupData = groupStore.getGroupById(Number(groupId));

    return (
      (groupData.accessType === GroupsAccessEnum.Admin && groupData.adminIds.includes(currentUserId)) ||
      currentUserRoleId >= UserRoleEnum.SuperAdministrator
    );
  };

  const getGroupType = (groupId: number): string => {
    const { t } = useI18n();
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);

    const groupTypes = [
      group.type === GroupsTypeEnum.PrivateVisible && t('groupPage.privateVisible'),
      group.type === GroupsTypeEnum.PrivateHidden && t('groupPage.privateHidden'),
      group.isMandant && t('groupPage.mandant'),
      group.isOfficial && t('groupPage.official'),
    ].filter(Boolean);

    return groupTypes.join(', ');
  };

  const getGroupIcon = (groupId: number): string | null => {
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);
    if (group.isMandant) return icons.globe;
    if (group.isOfficial) return icons.star;
    if (group.type === GroupsTypeEnum.PrivateHidden) return icons.shield;
    if (group.type === GroupsTypeEnum.PrivateVisible) return icons.lock;
    return null;
  };

  const getGroupIconByData = (isMandant: boolean, isOfficial: boolean, type: GroupsTypeEnum): string => {
    if (isMandant) return icons.globe;
    if (isOfficial) return icons.star;
    if (type === GroupsTypeEnum.PrivateHidden) return icons.shield;
    if (type === GroupsTypeEnum.PrivateVisible) return icons.lock;
    return '';
  };

  return {
    getInviteModesToGroup,
    getInviteModeName,
    getMainGroupAction,
    onJoinGroup,
    onLeaveGroup,
    onSendRequest,
    getGroupStats,
    userIsJoined,
    getGroupInfoAccess,
    isGroupAdmin,
    getGroupType,
    getGroupIcon,
    getGroupIconByData,
  };
}
