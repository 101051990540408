import type { TopicsIdsModel, TopicsIdsDataModel, TopicEntity } from '@/@types';

export const defaultTopicMode: TopicEntity = {
  createdAt: '',
  description: '',
  id: 0,
  label: '',
  photoFileUrl: null,
  title: '',
  url: '',
  webUrl: '',
  followersCount: 0,
  postsCount: 0,
  color: null,
};

export const defaultTopicsIds: TopicsIdsModel = {
  topicsPage: {
    all: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
    recommended: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
    following: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
    search: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
  },
  searchPage: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
};
