import type { IdeaTypeEnum, PostsFilterEnum } from '@/@enums';
import { ConversationsTypeEnum } from '@/@enums';
import type {
  ResponsePostsModel,
  ResponseErrorModel,
  ResponseSuccessModel,
  PollAddOptionModel,
  PollVoteModel,
  ResponseCommentsLoadMoreModel,
  ResponsePostModel,
  ResponseCommentModel,
  RequestPollCreateModel,
  RequestStandardPostCreateModel,
  RequestIdeaCreateModel,
  RequestCommentCreateModel,
  RequestPostEditModel,
  RequestPostComplain,
  RequestSendPostToExternalEmail,
  RequestIdeaEditModel,
  RequestPollEditModel,
  RequestAnnouncementEditModel,
  ResponseAnnouncementCreateModel,
  ResponsePostsIdeaModel,
  ResponseShortUsersModel,
  RequestBadgeCreateModel,
  RequestIdeaUpdateStatusModel,
  ResponsePlannedPostModel,
  ResponsePlannedPollModel,
  ResponsePollModel,
} from '@/@types';
import axios from '@/services/axios';

export class PostApiService {
  async getPostsFromUserId(userId: number): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/fromUser/${userId}?showAll=false`);
  }
  async loadMore(url: string): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(url);
  }
  async pollAddOption(data: PollAddOptionModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/polls/addOption`, {
      id: data.id,
      option: data.option,
    });
  }
  async pollAddVote(data: PollVoteModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/polls/addVote`, {
      messageId: data.messageId,
      optionIndex: data.optionIndex,
    });
  }
  async pollRemoveVote(data: PollVoteModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/polls/removeVote`, {
      messageId: data.messageId,
      optionIndex: data.optionIndex,
    });
  }
  async getPollById(id: number): Promise<ResponsePollModel | ResponseErrorModel> {
    return axios.get(`/polls/byId/${id}`);
  }
  async getPollVoters(id: number, index: number): Promise<ResponseShortUsersModel | ResponseErrorModel> {
    return axios.get(`/polls/voters/${id}?optionIndex=${index}`);
  }
  async postAddLike(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/addLike/${id}`, {
      id: id,
    });
  }
  async postRemoveLike(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/posts/removeLike/${id}`);
  }
  async commentAddLike(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/comments/addLike/${id}`);
  }
  async commentRemoveLike(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/comments/removeLike/${id}`);
  }
  async commentsLoadMore(url: string): Promise<ResponseCommentsLoadMoreModel | ResponseErrorModel> {
    return axios.get(url);
  }
  async getPostsFromGroupId(
    groupId: number,
    readStatus: PostsFilterEnum
  ): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/inGroup/${groupId}?readStatus=${readStatus}`);
  }
  async getPostsFromTagId(tagId: number): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/byTopicId/${tagId}`);
  }
  async getPostsFromWikiId(wikiId: number): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/byWikiId/${wikiId}`);
  }
  async getPostsAll(readStatus: PostsFilterEnum): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/all?readStatus=${readStatus}`);
  }
  async getPlannedPosts(): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/planned`);
  }
  async getPostsFollowing(readStatus: PostsFilterEnum): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/following?readStatus=${readStatus}`);
  }
  async getPostsBookmarks(): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/bookmarks/all`);
  }
  async getPostsRecommended(): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/recommended`);
  }
  async getIdeas(mode: IdeaTypeEnum): Promise<ResponsePostsIdeaModel | ResponseErrorModel> {
    return axios.get(`/ideas/list?mode=${mode}`);
  }
  async getAnnouncements(): Promise<any | ResponseErrorModel> {
    return axios.get(`/announcements/all`);
  }
  async getPostFromId(postId: number): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.get(`/posts/byId/${postId}`);
  }
  async getPlannedPostFromId(postId: number): Promise<ResponsePlannedPostModel | ResponseErrorModel> {
    return axios.get(`/posts/planned/${postId}`);
  }
  async postDelete(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/posts/delete/${postId}`);
  }
  async commentCreate(commentData: RequestCommentCreateModel): Promise<ResponseCommentModel | ResponseErrorModel> {
    return axios.post('/comments/create', commentData);
  }
  async commentDelete(commentId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/comments/delete/${commentId}`);
  }
  async ideaCreate(ideaData: RequestIdeaCreateModel): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post('/ideas/create', ideaData);
  }
  async ideaUpdateStatus(
    postId: number,
    ideaData: RequestIdeaUpdateStatusModel
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/ideas/updateStatus/${postId}`, ideaData);
  }
  async ideaPlannedCreate(ideaData: RequestIdeaCreateModel): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post('/ideas/planned/create', ideaData);
  }
  async ideaPlannedUpdate(
    postId: number,
    ideaData: RequestIdeaCreateModel
  ): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post(`/ideas/planned/${postId}/update`, ideaData);
  }
  async ideaPlannedForce(postId: number): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post(`/ideas/planned/${postId}/force`);
  }
  async ideaPlannedDelete(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/ideas/planned/${postId}/delete`);
  }
  async badgeCreate(badgeData: RequestBadgeCreateModel): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post('/posts/createBadge', badgeData);
  }
  async postCreate(postData: RequestStandardPostCreateModel): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post('/posts/create', postData);
  }
  async postPlannedCreate(
    postData: RequestStandardPostCreateModel
  ): Promise<ResponsePlannedPostModel | ResponseErrorModel> {
    return axios.post('/posts/planned/create', postData);
  }
  async postPlannedUpdate(
    postId: number,
    postData: RequestPostEditModel
  ): Promise<ResponsePlannedPostModel | ResponseErrorModel> {
    return axios.post(`/posts/planned/${postId}/update`, postData);
  }
  async postPlannedForce(postId: number): Promise<ResponsePlannedPostModel | ResponseErrorModel> {
    return axios.post(`/posts/planned/${postId}/force`);
  }
  async postPlannedDelete(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/posts/planned/${postId}/delete`);
  }
  async pollCreate(pollData: RequestPollCreateModel): Promise<ResponsePollModel | ResponseErrorModel> {
    return axios.post('/polls/create', pollData);
  }
  async pollPlannedCreate(pollData: RequestPollCreateModel): Promise<ResponsePlannedPollModel | ResponseErrorModel> {
    return axios.post('/polls/planned/create', pollData);
  }
  async pollPlannedUpdate(
    postId: number,
    pollData: RequestPollEditModel
  ): Promise<ResponsePlannedPollModel | ResponseErrorModel> {
    return axios.post(`/polls/planned/${postId}/update`, pollData);
  }
  async pollPlannedForce(postId: number): Promise<ResponsePollModel | ResponseErrorModel> {
    return axios.post(`/polls/planned/${postId}/force`);
  }
  async pollPlannedDelete(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/polls/planned/${postId}/delete`);
  }
  async addBookmark(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/bookmark/${postId}`);
  }
  async removeBookmark(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/unBookmark/${postId}`);
  }
  async markAsRead(postsIds: number[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(
      '/posts/markAsRead',
      { postsIds },
      {
        headers: {
          withoutToast: true,
        },
      }
    );
  }
  async markAsUnread(postsIds: number[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/posts/markAsUnread', { postsIds });
  }
  async pinPost(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/pin/${postId}`);
  }
  async unPinPost(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/unPin/${postId}`);
  }
  async postEdit(postId: number, postData: RequestPostEditModel): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post(`/posts/edit/${postId}`, postData);
  }
  async followToPost(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/follow/${postId}`);
  }
  async unFollowFromPost(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/unFollow/${postId}`);
  }
  async complainPost(
    postId: number,
    complainData: RequestPostComplain
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/report/${postId}`, complainData);
  }
  async sendPostToEmail(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/sendToEmail/${postId}`);
  }
  async sendPostToExternalEmail(
    postId: number,
    emailData: RequestSendPostToExternalEmail
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/sendToExternalEmail/${postId}`, emailData);
  }
  async ideaEdit(ideaId: number, ideaData: RequestIdeaEditModel): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post(`/ideas/update/${ideaId}`, ideaData);
  }
  async pollEdit(pollId: number, pollData: RequestPollEditModel): Promise<ResponsePollModel | ResponseErrorModel> {
    return axios.post(`/polls/edit/${pollId}`, pollData);
  }
  async announcementEdit(
    announcementId: number,
    announcementData: RequestAnnouncementEditModel
  ): Promise<ResponseAnnouncementCreateModel | ResponseErrorModel> {
    return axios.post(`/announcements/update/${announcementId}`, announcementData);
  }
  async postHide(postId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/posts/hide/${postId}`);
  }
  async getPostsPolls(): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/polls/list?limit=20`);
  }
  async shareArchiveLink(
    type: ConversationsTypeEnum,
    postId: number,
    emails: string[]
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    const data = { id: postId, emails };

    const getController = () => {
      switch (type) {
        case ConversationsTypeEnum.Text:
          return 'posts';

        case ConversationsTypeEnum.Idea:
          return 'ideas';

        case ConversationsTypeEnum.Poll:
          return 'polls';

        case ConversationsTypeEnum.Announcement:
          return 'announcements';

        case ConversationsTypeEnum.Event:
          return 'events';

        default:
          return 'posts';
      }
    };

    return axios.post(`/${getController()}/sendArchiveLink/`, data);
  }
  async getRequiredForRead(): Promise<ResponsePostsModel | ResponseErrorModel> {
    return axios.get(`/posts/requiredForRead`);
  }
}
