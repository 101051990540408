import { loadingController } from '@ionic/vue';

import { AppLoadingTypeEnum } from '@/@enums';

interface ILoadingHelper {
  create(text: string, id?: AppLoadingTypeEnum | undefined): Promise<void>;
  dismiss(id?: AppLoadingTypeEnum | undefined): Promise<void>;
}

export function useLoadingHelper(): ILoadingHelper {
  const customLoadingContent = (text: string): string => {
    const svg =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><g fill="currentColor" class="nc-icon-wrapper"><g class="nc-loop-circle-2-64-icon-f"><path d="M32 62a30 30 0 1 1 30-30 30.034 30.034 0 0 1-30 30zm0-55a25 25 0 1 0 25 25A25.028 25.028 0 0 0 32 7z" fill="rgba(var(--ion-color-intra-rgb), 0.3)" opacity=".4"></path><path d="M62 32h-5A25.028 25.028 0 0 0 32 7V2a30.034 30.034 0 0 1 30 30z" fill="var(--ion-color-intra)" data-color="color-2"></path></g><style>.nc-loop-circle-2-64-icon-f{--animation-duration:0.65s;transform-origin:32px 32px;animation:nc-loop-circle-2-anim var(--animation-duration) infinite cubic-bezier(.645,.045,.355,1)}@keyframes nc-loop-circle-2-anim{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}</style></g></svg>';

    return `<div class="custom-loading-content"><div class="spinner">${svg}</div><div class="text">${text}</div></div>`;
  };

  const create = async (text: string, id: AppLoadingTypeEnum | undefined = undefined): Promise<void> => {
    const loading = await loadingController.create({
      message: '',
      spinner: null,
      mode: 'ios',
      showBackdrop: true,
      id,
      cssClass: ['custom-loading', id === AppLoadingTypeEnum.NetworkSetting ? 'opaque' : ''],
    });

    await loading.present();

    const element = document.querySelector('.loading-content');
    if (element) {
      element.innerHTML = customLoadingContent(text);
    }
  };

  const dismiss = async (id: AppLoadingTypeEnum | undefined = undefined): Promise<void> => {
    try {
      const loading = await loadingController.getTop();

      if (id && loading?.id === id) {
        await loadingController.dismiss();
      } else if (!id && loading) {
        await loadingController.dismiss();
      }
    } catch (e) {
      console.error('Error dismissing loading:', e);
    }
  };

  return {
    create,
    dismiss,
  };
}
