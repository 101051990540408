<template>
  <div class="block">
    <div class="user">
      <ion-skeleton-text animated style="width: 4rem; height: 4rem; border-radius: 50%" />
      <ion-label>
        <ion-skeleton-text animated style="width: 8rem; height: 4rem; border-radius: 8px" />
      </ion-label>
    </div>
    <div class="message">
      <ion-text>
        <ion-skeleton-text animated style="width: 100%; height: 1rem; border-radius: 8px" />
        <ion-skeleton-text animated style="width: 100%; height: 1rem; border-radius: 8px" />
      </ion-text>
    </div>
    <div class="controls">
      <ion-skeleton-text animated style="width: 50%; height: 2rem; border-radius: 8px" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel, IonText, IonSkeletonText } from '@ionic/vue';
</script>

<style scoped lang="scss">
.block {
  background-color: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
  margin-bottom: app-padding(lg);
  padding: app-padding(lg);
}
.block .user {
  display: flex;
  align-items: center;
}
.block .user ion-label {
  margin-left: 1rem;
}
.block .controls {
  display: flex;
  justify-content: end;
}
</style>
