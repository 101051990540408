import type { DocsDataByFolderModel, DocsDataByGroupModel, DocsDataModel, ExternalLinkModel } from '@/@types';

export const defaultDocsData: DocsDataModel = {
  folders: [] as DocsDataByFolderModel[],
  groups: [] as DocsDataByGroupModel[],
  all: { loadMoreUrl: null, data: [] },
  uploaded: { loadMoreUrl: null, data: [] },
  recent: { loadMoreUrl: null, data: [] },
  search: { loadMoreUrl: null, data: [] },
};

export const defaultExternalLink: ExternalLinkModel = {
  title: '',
  url: '',
};
