<template>
  <ion-item
    v-if="isFloating"
    mode="ios"
    lines="none"
    :class="{ 'is-floating': isFloating, 'single-item': isSingleItem }"
  >
    <ion-input
      ref="input"
      :autocapitalize="autocapitalize"
      :autocomplete="autocomplete"
      :required="required"
      :spellcheck="spellcheck"
      :type="type"
      :value="value"
      :maxlength="maxlength"
      label-placement="floating"
      :label="placeholder"
      @ionInput="handleInputChange"
      @keyup.enter="keyUp"
    />
  </ion-item>

  <ion-item v-else lines="none" :class="[{ 'single-item': isSingleItem }, customSize]">
    <ion-input
      ref="input"
      :placeholder="placeholder"
      :autocapitalize="autocapitalize"
      :autocomplete="autocomplete"
      :required="required"
      :spellcheck="spellcheck"
      :type="type"
      :value="value"
      :maxlength="maxlength"
      :class="customSize"
      label-placement="stacked"
      :label="labelFlag ? placeholder : undefined"
      @ionInput="handleInputChange"
      @keydown.enter="keyUp"
    />
    <slot />
  </ion-item>
</template>

<script lang="ts" setup>
import type { AutocompleteTypes, TextFieldTypes } from '@ionic/core';
import { IonInput, IonItem } from '@ionic/vue';
import type { PropType } from 'vue';
import { onMounted, ref } from 'vue';

import { keyUpSend } from '@/helpers';

const props = defineProps({
  placeholder: {
    type: String,
    default: () => '',
  },
  autocapitalize: {
    type: String,
    default: () => '',
  },
  autocomplete: {
    type: String as PropType<AutocompleteTypes>,
    default: () => 'off',
  },
  required: {
    type: Boolean,
    default: () => false,
  },
  spellcheck: {
    type: Boolean,
    default: () => false,
  },
  type: {
    type: String as PropType<TextFieldTypes>,
    default: () => 'text',
  },
  value: {
    type: String,
    default: () => '',
  },
  labelFlag: {
    type: Boolean,
    default: () => false,
  },
  isFloating: {
    type: Boolean,
    default: () => false,
  },
  maxlength: {
    type: Number,
    default: undefined,
  },
  customSize: {
    type: String,
    default: () => '',
  },
  isSingleItem: {
    type: Boolean,
    default: () => false,
  },
  applyAutoFocus: {
    type: Boolean,
    default: () => false,
  },
});

const input = ref<any>(null);

const handleInputChange = (event: Event) => {
  let value = '';
  value = (event.target as HTMLInputElement).value;

  if (!props.maxlength) {
    emit('update:value', value);
  } else {
    if (props.maxlength >= value.length) {
      emit('update:value', value);
    }
  }
};

const emit = defineEmits(['update:value', 'keyUpActionValue']);

const keyUpAction = ref<boolean>(false);
const keyUp = (event: KeyboardEvent | MouseEvent) => {
  keyUpSend(event) ? (keyUpAction.value = true) : (keyUpAction.value = false);
  emit('keyUpActionValue', keyUpAction.value);
};

onMounted(async () => {
  if (input.value && props.applyAutoFocus) {
    setTimeout(() => input.value.$el.setFocus(), 0);
  }
});
</script>

<style scoped lang="scss">
ion-input {
  font-size: 1.1rem;
  --min-height: 4rem;
  --color: var(--ion-color-dark);
  --placeholder-color: var(--ion-color-dark);
  caret-color: auto;
  --background: transparent;
}
ion-input.medium {
  font-size: 0.9rem;
}
ion-item {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --inner-padding-end: 0;
  --background: transparent;
  margin-top: 1rem;
  border: 1px solid var(--ion-color-medium);
  border-radius: app-radius(md);
}
ion-item.medium {
  --min-height: 38px;
}
ion-item.single-item {
  margin-top: 0;
}
ion-item::part(native) {
  border-radius: app-radius(md);
}
ion-item.is-floating::part(native) {
  border-radius: unset;
  border-radius: app-radius(md) app-radius(md) 0 0;
}
ion-item.is-floating {
  border: unset;
  border-radius: unset;
  border-bottom: 1px solid var(--ion-color-medium);
  min-height: 0 !important;
}
</style>
