<template>
  <div class="contributions">
    <!-- NOTE: On mobile devices -->
    <template v-if="isAnyMobile">
      <ion-chip v-for="(item, index) in contributions" :key="index" color="medium" @click.stop="fullView = !fullView">
        <ion-icon v-if="!fullView" :icon="item.icon" />
        <ion-label>{{ !fullView ? item.count : item.description }}</ion-label>
      </ion-chip>
    </template>

    <!-- NOTE: On the web -->
    <template v-else>
      <ion-chip
        v-for="(item, index) in contributions"
        :key="index"
        v-tooltip.bottom="{
          content: item.description,
          theme: 'info-tooltip',
        }"
        color="medium"
        @click.stop
      >
        <ion-icon :icon="item.icon" />
        <ion-label>{{ item.count }}</ion-label>
      </ion-chip>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { IonIcon, IonChip, IonLabel } from '@ionic/vue';
import { copyOutline, chatbubbleOutline, documentOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed, ref } from 'vue';

import type { UserContributionsModel } from '@/@types';
import { isAnyMobile } from '@/helpers';
import { useI18n } from '@/i18n';

const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
  contributions: {
    type: Object as PropType<UserContributionsModel>,
    required: true,
  },
});

const icons = {
  tasks: copyOutline,
  files: documentOutline,
  posts: chatbubbleOutline,
};

const { t } = useI18n();
const fullView = ref<boolean>(false);

const contributions: ComputedRef<{ icon: string; count: string | number; description: string }[]> = computed(
  () =>
    [
      {
        icon: icons.posts,
        count: `${props.contributions.postsCount} / ${props.contributions.commentsCount}`,
        description: `${t('search.searchView.types.posts')}: ${
          props.contributions.postsCount
        }, ${t('conversations.comments.title')}: ${props.contributions.commentsCount}`,
      },
      {
        icon: icons.files,
        count: `${props.contributions.filesCount} / ${props.contributions.wikiesCount}`,
        description: `${t('search.searchView.types.files')}: ${
          props.contributions.filesCount
        }, ${t('search.searchView.types.wikis')}: ${props.contributions.wikiesCount}`,
      },
      {
        icon: icons.tasks,
        count: props.contributions.tasksCount,
        description: `${t('taskManagement.tasks.all')}: ${props.contributions.tasksCount}`,
      },
    ] as { icon: string; count: string | number; description: string }[]
);
</script>

<style scoped lang="scss">
.contributions {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  ion-chip {
    font-size: 0.7rem;
    margin-left: 0;
    margin-right: app-padding(md);
  }
}
</style>
