import type { ResponseErrorModel, ResponseBadgesModel } from '@/@types';
import axios from '@/services/axios';

export class BadgesApiService {
  async getBadges(): Promise<ResponseBadgesModel | ResponseErrorModel> {
    return axios.get('/badges/all');
  }
  async getBadgesById(ids: number[]): Promise<ResponseBadgesModel | ResponseErrorModel> {
    return axios.post(
      `/badges/byIds`,
      { badgesIds: ids },
      {
        headers: {
          withoutToast: true,
        },
      }
    );
  }
}
