import type {
  EditMessageModel,
  LastMessageModel,
  MessageEntity,
  RequestEditMessageModel,
  UserEntity,
  UserMessageModel,
} from '@/@types';

export function toLastModel(message: MessageEntity): LastMessageModel {
  return {
    id: message.id,
    chainId: message.chainId,
    createdAt: message.createdAt,
    type: message.messageType,
    text: message.bodyHtml,
    authorId: message.authorId,
    authorName: message.authorFullName,
    status: message.status,
  } as LastMessageModel;
}

export function toEditModel(message: EditMessageModel): RequestEditMessageModel {
  return {
    chainId: message.chainId,
    uniqueId: message.uniqueId,
    fileExistIds: message.fileExistIds,
    fileTempIds: message.fileTempIds,
    text: message.message.text,
  } as RequestEditMessageModel;
}

export function toShortUserModel(user: UserEntity): UserMessageModel {
  return {
    id: user.id,
    fullName: user.fullName,
    mainAlias: user.mainAlias,
    isActive: user.isActive,
    image: {
      url: user.avatar?.url,
      width: user.avatar?.width,
      height: user.avatar?.height,
    },
  } as UserMessageModel;
}
